<template>
  <div id="account-block-area" class="row">
    <div
      class="col-3 m-3 border rounded-lg account-block shadow"
      v-for="(item, index) in data"
      :key="index"
      @click="
        enterPage(
          item.accountId,
          item.accountName,
          item.accountType,
          item.accountIcon,
          item.accountToken,
        )
      "
    >
      <img
        :src="connectType(item.accountType)"
        v-bind:class="connectTypeClass(item.accountType)"
        class="rounded-circle account-type"
      />
      <div class="text-center pt-4">
        <img
          class="rounded-circle"
          :src="checkAccountIcon(item.accountIcon)"
          style="width: 150px; height: 150px"
        />
      </div>
      <p class="text-center py-3 font-weight-bold">{{ item.accountName }}</p>
    </div>
  </div>
</template>

<script>
import Card from '@/components'
export default {
  name: 'account-card',
  components: { Card },
  props: {
    data: Array,
    adminId: String,
    jwtToken: String,
  },
  methods: {
    // Account type icon src
    connectType(accountType) {
      if (accountType == 'wechat') {
        return require('@/assets/img/wechat.jpg')
      } else if (accountType == 'fb') {
        return require('@/assets/img/fb.png')
      } else if (accountType == 'ig') {
        return require('@/assets/img/instagram.png')
      } else if (accountType == 'line') {
        return require('@/assets/img/line.png')
      } else if (accountType == 'webbot') {
        return require('@/assets/img/webbot.webp')
      }
    },
    //Account type icon class
    connectTypeClass(accountType) {
      if (accountType == 'wechat') {
        return 'wechatIcon'
      } else {
        return 'lineIcon'
      }
    },
    // Return default icon if icon missing
    checkAccountIcon(accountIcon) {
      if (!accountIcon) {
        return require('@/assets/img/profile.jpg')
      }

      return accountIcon
    },
    // Encrypt token in localStorage
    // 1. adminId
    // 2. accountId
    // 3. accountName
    // 4. accountType
    createAuthToken(adminId, accountId, accountName, accountType, accountToken) {
      let encrypted = this.$aes.encrypt(
        adminId + ',' + accountId + ',' + accountName + ',' + accountType + ',' + accountToken,
      )

      return encrypted
    },
    // Choose an account to login
    enterPage(accountId, accountName, accountType, accountIcon, accountToken) {
      localStorage._token = this.createAuthToken(
        this.adminId,
        accountId,
        accountName,
        accountType,
        accountToken,
      )

      // save account info that can be public
      localStorage.profileImg = accountIcon
      localStorage.accountType = accountType

      this.$router.push('/dashboard')
    },
  },
}
</script>
