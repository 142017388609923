var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade bd-example-modal",
      attrs: {
        id: "create-tag-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "modal-dialog modal modal-dialog-centered modal-dialog-scrollable",
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content tag-modal" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("p", { staticClass: "modal-title text-bold" }, [
                _vm._v(
                  _vm._s(
                    _vm.editMode ? _vm.$t("__editLabel") : _vm.$t("__Addlabel")
                  )
                )
              ]),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c("div", [
                  _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                  _vm._v(
                    " " + _vm._s(_vm.$t("__requiredItem")) + "\n          "
                  )
                ]),
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-2",
                    attrs: { "data-translate": "_selectColor.edit" }
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(" " + _vm._s(_vm.$t("__Labelname")) + "\n          ")
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newTagData.name,
                        expression: "newTagData.name"
                      }
                    ],
                    staticClass: "form-control px-4 mx-3",
                    attrs: { type: "text", placeholder: _vm.$t("__Labelname") },
                    domProps: { value: _vm.newTagData.name },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.newTagData, "name", $event.target.value)
                      }
                    }
                  })
                ]),
                _c(
                  "label",
                  {
                    staticClass: "pt-3 mb-0",
                    attrs: { "data-translate": "_selectColor.edit" }
                  },
                  [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("__Chooselabelcolor")) +
                        "\n          "
                    )
                  ]
                ),
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "phone-screen mb-4" }, [
                    _c("div", { staticClass: "color-filters" }, [
                      _c(
                        "ul",
                        _vm._l(_vm.colorOptions, function(color, index) {
                          return _c("li", { key: index }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.newTagData.colorFilter,
                                  expression: "newTagData.colorFilter"
                                }
                              ],
                              attrs: {
                                type: "radio",
                                name: "colorFilters",
                                id: color
                              },
                              domProps: {
                                value: color,
                                checked: _vm._q(
                                  _vm.newTagData.colorFilter,
                                  color
                                )
                              },
                              on: {
                                change: function($event) {
                                  return _vm.$set(
                                    _vm.newTagData,
                                    "colorFilter",
                                    color
                                  )
                                }
                              }
                            }),
                            _c("label", {
                              class: color,
                              attrs: { for: color, id: color }
                            })
                          ])
                        }),
                        0
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "d-flex" }, [
                  _c("label", { staticClass: "pt-3 mb-2" }, [_vm._v("Values")]),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary btn-sm ml-auto my-auto",
                      staticStyle: { height: "32px" },
                      on: {
                        click: function($event) {
                          return _vm.addTagValue()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("__Add")) +
                          "\n            "
                      )
                    ]
                  )
                ]),
                _c("div", [
                  _c(
                    "ul",
                    { staticClass: "list-group" },
                    _vm._l(_vm.newTagData.values, function(value, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "list-group-item p-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.newTagData.values[index],
                                expression: "newTagData.values[index]"
                              }
                            ],
                            attrs: { type: "text" },
                            domProps: { value: _vm.newTagData.values[index] },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.newTagData.values,
                                  index,
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]
                      )
                    }),
                    0
                  )
                ])
              ])
            ]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  staticStyle: { width: "100%" },
                  attrs: { type: "button", id: "create-story-tag" },
                  on: {
                    click: function($event) {
                      return _vm.createTag()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.editMode
                          ? _vm.$t("__saveEditTag")
                          : _vm.$t("__Createnewlabel")
                      ) +
                      "\n        "
                  )
                ]
              )
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }