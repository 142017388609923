<template>
  <div>
    <card class="main-content">
      <!-- search -->
      <div class="row">
        <div class="ml-auto py-2 pr-4">
          <input
            id="search-box"
            type="text"
            class="search-box"
            name="q"
            v-model="search"
          />
          <label for="search-box">
            <span class="fas fa-search search-icon"></span>
          </label>
        </div>
      </div>
      <!-- search -->

      <div class="card-body">
        <div class="table-responsive">
          <!-- content -->
          <tagList-table
            type="hover table-bordered"
            :adminId="adminId"
            :jwtToken="jwtToken"
            :accountId="accountId"
            :data="table.data"
            :columns="table.columns"
            v-on:editTag="editTag"
            v-on:deleteTag="deleteTag"
          ></tagList-table>
          <!-- content -->
          <pagination
            v-model="page"
            :per-page="rowPerPage"
            :records="allTag.length"
            :options="{
              texts: {
    	          count: this.$t('__paginationText')
              }
            }"
            @paginate="callback"
          ></pagination>
        </div>
      </div>
      <!-- add button-->
      <div class="btn-follow">
        <a
          href="javascript:void(0)"
          style="color: #fff"
          @click="showCreateTagModal()"
        >
          <i class="fas fa-plus"></i>
        </a>
      </div>
    </card>
    <create-tag-modal
      v-on:newTag="newTag"
      v-bind:newTagData="modalData"
      ref="createTagModal"
    ></create-tag-modal>
  </div>
</template>
<script>
import { CreateTagModal } from "./Modals/index";
import { TagListTable } from "./Components/index";
import { apiFunction } from "@/Axios/functions";

const tableData = [];

export default {
  components: {
    TagListTable,
    CreateTagModal,
  },
  data() {
    return {
      search: "",
      page: 1,
      rowPerPage: 20,
      allTag: [],
      totalTag: 0,
      adminId: "",
      accountId: "",
      jwtToken: "",
      table: {
        columns: [],
        data: [...tableData],
      },
      modalData: {
        range_key: null,
        name: "",
        colorFilter: null,
        values: [],
      },
      editMode: false
    };
  },
  watch: {
    search: {
      handler: function (val, oldval) {
        let reg = new RegExp(val);
        let searchResult = [];
        $.each(this.allTag, function (i, tag) {
          if (reg.test(tag.name) == true) {
            searchResult.push(tag);
          }
        });
        this.table.data = searchResult;
      },
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    // Prepare page content
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(",");
        this.adminId = decrypt[0];
        this.accountId = decrypt[1];
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser();
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken;
      } catch (e) {
        this.$fire({
          title: this.$t("__authExpired"),
          text: this.$t("__pleaseLogin"),
          type: "error",
        }).then(() => {
          this.$Amplify.Auth.signOut();
          localStorage.clear();
          this.$router.push("/login");
        });
      }
      this.getTagList();
    },
    getTagList() {
      let formData = { adminId: this.adminId, accountId: this.accountId };

      apiFunction.getTags(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t("__authExpired"),
            text: this.$t("__pleaseLogin"),
            type: "error",
          }).then(() => {
            this.$Amplify.Auth.signOut();
            localStorage.clear();
            this.$router.push("/login");
          });
        } else {
          this.allTag = data.data.Result.data;
          this.allTag.forEach((tag) => {
            if (tag.values.length <= 0) {
              tag.values = null;
            }
          });
          // Init pagination
          this.callback(this.page);
        }
      });
    },
    // Pagination
    callback(pageNum) {
      let arr = this.allTag.slice(
        this.rowPerPage * (pageNum - 1),
        this.rowPerPage * pageNum
      );
      this.table.data = arr;
    },
    showCreateTagModal() {
      this.modalData = {
        name: "",
        colorFilter: null,
        values: [],
      };
      this.editMode = false;
      this.$refs.createTagModal.editMode = false;
      $("#create-tag-modal").modal("show");
    },
    // Pass new tag arr
    newTag() {
      let tagValues = [];
      this.modalData.values.forEach((value) => {
        if (value !== "") {
          tagValues.push(value);
        }
      });
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        tag: {
          range_key: this.modalData.range_key,
          name: this.modalData.name,
          color: this.modalData.colorFilter,
          values: tagValues,
          action: this.editMode ? "update" : "create",
        },
      };
      console.log(formData)
      apiFunction.saveTag(formData, this.jwtToken).then((data) => {
        if (data && !data.error) {
          this.$fire({
            title: this.editMode ? this.$t("__updatedsuccessfully") : this.$t("__addedsuccessfully"),
            text: this.editMode ? this.$t("__newTagUpdated") : this.$t("__newTagCreated"),
            type: "success",
            timer: 1500,
          }).then((e) => {
            this.getTagList();
          });
        } else {
          this.$fire({
            title: this.$t("__Addfailed"),
            text: this.$t("__oopsSomethingwentwrong"),
            type: "error",
          });
        }
      });
    },
    editTag(tag) {
      this.modalData = {
        range_key: tag.range_key,
        name: tag.name,
        colorFilter: tag.color,
        values: tag.values ? tag.values.slice() : [],
      };
      this.editMode = true;
      this.$refs.createTagModal.editMode = true;
      $("#create-tag-modal").modal("show");
    },
    deleteTag(tag) {
      this.$fire({
        title: this.$t("__Areyousureyouwanttodeleteit"),
        text: this.$t("__Thesystemwillnotbeabletoretrieveyourdata"),
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("__YesIwanttodelete"),
        cancelButtonText: this.$t("__cancel"),
      }).then((result) => {
        if (result.value) {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            tag: {
              name: tag.name,
              color: tag.color,
              range_key: tag.range_key,
            },
          };

          apiFunction.deleteTag(formData, this.jwtToken).then((data) => {
            if (data && !data.error) {
              this.$fire({
                title: this.$t("__successfullydeleted"),
                text: this.$t("__Yourdatahasbeensuccessfullydeleted"),
                type: "success",
                timer: 1500,
              }).then((e) => {
                this.getTagList();
              });
            } else {
              this.$fire({
                title: this.$t("__failedtodelete"),
                text: this.$t("__oopsSomethingwentwrong"),
                type: "error",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style>
</style>
