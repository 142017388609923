<template>
  <div>
    <!-- Loading and Error States -->
    <div v-if="loading">Loading...</div>
    <div v-if="error" class="error">{{ error }}</div>

    <!-- Settings Form -->
    <form v-if="!loading && !error" @submit.prevent="updateAccount">
      <!-- Icon URL -->
      <h4>Icon</h4>
      <hr />
      <div class="form-group row">
        <label for="icon_url" class="col-sm-2 col-form-label">{{ this.$t('__iconUrl') }}</label>
        <div class="col-sm-6">
          <input type="text" id="icon_url" v-model="form.icon_url" class="form-control" />
        </div>
      </div>
      <!-- Icon Preview -->
      <div class="form-group row" v-if="form.icon_url">
        <div class="col-sm-2 col-form-label">
          {{ $t('__iconPreview') }}
        </div>
        <div class="col-sm-6">
          <img
            :src="form.icon_url"
            alt="icon preview"
            style="max-width: 100px; border: 1px solid #ccc"
          />
        </div>
      </div>

      <!-- Header Background Color -->
      <h4>Header</h4>
      <hr />

      <div class="form-group row">
        <label for="header_background_color" class="col-sm-2 col-form-label">
          {{ this.$t('__headerBackgroundColor') }}
        </label>
        <div class="col-sm-6">
          <input
            type="color"
            id="header_background_color"
            v-model="form.header_background_color"
            class="form-control"
          />
        </div>
      </div>

      <!-- Header Title -->
      <div class="form-group row">
        <label for="header_title" class="col-sm-2 col-form-label">{{
          this.$t('__headerTitle')
        }}</label>
        <div class="col-sm-6">
          <input type="text" id="header_title" v-model="form.header_title" class="form-control" />
        </div>
      </div>

      <!-- Textarea Placeholder -->
      <h4>Textarea</h4>
      <hr />
      <div class="form-group row">
        <label for="textarea_placeholder_text" class="col-sm-2 col-form-label">
          {{ this.$t('__textareaPlaceholder') }}
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            id="textarea_placeholder_text"
            v-model="form.textarea_placeholder_text"
            class="form-control"
          />
        </div>
      </div>

      <!-- Choose Background Type -->
      <h4>Chat Area</h4>
      <hr />
      <div class="form-group row">
        <label for="background_type" class="col-sm-2 col-form-label">
          {{ this.$t('__backgroundType') }}
        </label>
        <div class="col-sm-6">
          <select id="background_type" v-model="backgroundType" class="form-control">
            <option value="color">{{ this.$t('__useBackgroundColor') }}</option>
            <option value="url">{{ this.$t('__useBackgroundImage') }}</option>
          </select>
        </div>
      </div>

      <!-- Chat Area Background Color -->
      <div class="form-group row" v-if="backgroundType === 'color'">
        <label for="chat_area_background_color" class="col-sm-2 col-form-label">
          {{ this.$t('__chatAreaBackgroundColor') }}
        </label>
        <div class="col-sm-6">
          <input
            type="color"
            id="chat_area_background_color"
            v-model="form.chat_area_background_color"
            class="form-control"
          />
        </div>
      </div>

      <!-- Chat Area Background Image URL -->
      <div class="form-group row" v-if="backgroundType === 'url'">
        <label for="chat_area_background_image_url" class="col-sm-2 col-form-label">
          {{ this.$t('__chatAreaBackgroundImageUrl') }}
        </label>
        <div class="col-sm-6">
          <input
            type="text"
            id="chat_area_background_image_url"
            v-model="form.chat_area_background_image_url"
            class="form-control"
          />
        </div>
      </div>

      <!-- Background Image Preview -->
      <div
        class="form-group row"
        v-show="form.chat_area_background_image_url && backgroundType === 'url'"
      >
        <div class="col-sm-2 col-form-label">
          {{ $t('__backgroundImagePreview') }}
        </div>
        <div class="col-sm-6">
          <img
            :src="form.chat_area_background_image_url"
            alt="icon preview"
            style="max-width: 100px; border: 1px solid #ccc"
          />
        </div>
      </div>

      <!-- Default Font Size -->
      <h4>Font Size</h4>
      <hr />
      <div class="form-group row">
        <label for="default_font_size" class="col-sm-2 col-form-label">
          {{ this.$t('__defaultFontSize') }}
        </label>
        <div class="col-sm-6">
          <select id="default_font_size" v-model="form.default_font_size" class="form-control">
            <option value="sm">Small</option>
            <option value="md">Medium</option>
            <option value="lg">Large</option>
          </select>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group row">
        <div class="col-sm-6 offset-sm-2">
          <button type="submit" class="btn btn-primary">{{ this.$t('__updateSetting') }}</button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import { apiFunction } from './Axios/functions'
export default {
  data() {
    return {
      form: null,
      loading: true,
      error: null,
      accountType: 'webbot',
      backgroundType: 'color',
    }
  },
  methods: {
    async loadData() {
      // Getting required Id with route parameter
      if (localStorage._token) {
        let decrypt = this.$aes.decrypt(localStorage._token).split(',')
        this.adminId = decrypt[0]
        this.accountId = decrypt[1]
      }
      try {
        let user = await this.$Amplify.Auth.currentAuthenticatedUser()
        this.jwtToken = user.getSignInUserSession().getIdToken().jwtToken
      } catch (e) {
        this.$fire({
          title: this.$t('__authExpired'),
          text: this.$t('__pleaseLogin'),
          type: 'error',
        }).then(() => {
          this.$Amplify.Auth.signOut()
          localStorage.clear()
          this.$router.push('/login')
        })
      }
      this.getAccountInfo()
    },
    // Get account info
    getAccountInfo() {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
      }

      apiFunction.getAccountInfo(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          }).then(() => {
            this.$Amplify.Auth.signOut()
            localStorage.clear()
            this.$router.push('/login')
          })
        } else {
          const accountInfo = data.data.Result.data.webbotAccountInfo || {}
          const accountType = data.data.Result.data.accountType
          this.backgroundType = accountInfo.chat_area_background_image_url ? 'url' : 'color'

          this.form = {
            icon_url: accountInfo.icon_url || '',
            header_background_color: accountInfo.header_background_color || '#ffffff',
            header_title: accountInfo.header_title || '',
            textarea_placeholder_text: accountInfo.textarea_placeholder_text || '',
            chat_area_background_color: accountInfo.chat_area_background_color || '#ffffff',
            chat_area_background_image_url: accountInfo.chat_area_background_image_url || '',
            default_font_size: accountInfo.default_font_size || 'md',
          }

          this.accountType = accountType
          this.loading = false
        }
      })
    },
    // Update account settings
    // Get account info
    updateAccount() {
      // If background type is color, clear the image URL
      // since when both are specified, the image will be used
      if (this.backgroundType === 'color') {
        this.form.chat_area_background_image_url = ''
      }
      let formData = {
        webbotAccountInfo: this.form,
        adminId: this.adminId,
        accountId: this.accountId,
        accountType: this.accountType,
      }

      apiFunction.updateAccount(formData, this.jwtToken).then((data) => {
        if (data && data.status == 200) {
          this.$fire({
            title: this.$t('__updatecompleted'),
            text: this.$t('__updatecompleted'),
            type: 'success',
            timer: 1500,
          })
          this.getAccountInfo()
        } else {
          this.$fire({
            title: this.$t('__Settingsnotyetsaved'),
            text: this.$t('__oopsSomethingwentwrong'),
            type: 'error',
          })
        }
      })
    },
  },
  mounted() {
    this.loadData() // Fetch settings when the component is mounted
  },
}
</script>

<style scoped>
.error {
  color: red;
}
.form-group {
  margin-bottom: 15px;
}
</style>
