<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade bd-example-modal-lg"
      id="nodeModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content tag-modal" style="width: 1000px">
          <div class="modal-header" style="align-items: center">
            <span v-if="this.nodeData.nodeId != ''">
              {{ $t('__modalTitleNodeId') }}: {{ this.nodeData.nodeId }}
            </span>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <!-- ラジオボタン -->
            <div class="row ml-5 align-items-center">
              <md-radio
                type="radio"
                id="one"
                value="Message"
                v-model="formInput.nodeType"
                v-on:change="onmessage"
              >
                <label for="one"></label>
                <span> {{ $t('__Message') }}</span>
              </md-radio>
              <md-radio
                type="radio"
                id="two"
                value="Condition"
                v-model="formInput.nodeType"
                v-on:change="onmessage"
              >
                <label for="two"></label>
                <span> {{ $t('__Condition') }}</span>
              </md-radio>
              <div
                class="form-check"
                v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="formInput.constant.ecsite"
                  id="ecsiteBox"
                />
                <label class="form-check-label" for="ecsiteBox">
                  {{ $t('__ecsiteBox') }}
                </label>
              </div>
              <div
                class="form-check ml-3"
                v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
              >
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="formInput.constant.chichatcatBox"
                  id="chichatcatBox"
                />
                <label class="form-check-label" for="chichatcatBox">
                  {{ $t('__chichatcatBox') }}
                </label>
              </div>
              <div class="ml-auto mr-3" v-if="accountType == 'fb' || accountType == 'ig'">
                <select class="form-control" id="fb_tag_type" v-model="formInput.fbTag">
                  <option value="NORMAL" selected>
                    {{ $t('__FbtagNormal') }}
                  </option>
                  <option value="ACCOUNT_UPDATE" selected>
                    {{ $t('__FbtagAccountUpdate') }}
                  </option>
                  <option value="CONFIRMED_EVENT_UPDATE" selected>
                    {{ $t('__FbtagConfirmEvenUpdate') }}
                  </option>
                  <option value="POST_PURCHASE_UPDATE" selected>
                    {{ $t('__FbtagPurchaseUpdate') }}
                  </option>
                </select>
              </div>
            </div>
            <!-- Tag -->
            <div class="row px-3 py-2">
              <ul
                class="tags"
                id="node-tag-area"
                v-for="(tag, index) in formInput.tags"
                :key="index"
              >
                <li>
                  <a
                    href="javascript:void(0)"
                    class="tag"
                    @click="deleteTag(tag)"
                    :class="tag.color"
                    id="newLevelTag"
                    >{{ tag.name }}</a
                  >
                </li>
              </ul>
              <button
                class="btn btn-outline-secondary btn-sm ml-auto"
                data-toggle="modal"
                data-target="#tag"
                @click="showExistTag()"
              >
                {{ $t('__Addlabel') }}
              </button>
            </div>
            <!-- Node SubTitle -->
            <div class="row px-3 mb-1">
              <p v-if="formInput.subTitle !== ''" class="text-bold" id="node-subTitle">
                {{ formInput.subtitle }}
              </p>
              <p v-if="formInput.subtitle == ''" class="text-bold" id="node-subTitle">Subtitle</p>
              <span id="node-subTitle-edit" @click="editSubTitle()">
                <i class="fas fa-pencil-alt cursor-pointer pl-1"></i>
              </span>
              <input
                type="text"
                class="form-control element-hide"
                id="node-subTitle-input"
                v-model="formInput.subtitle"
                @keypress="editSubTitleDone($event)"
              />
            </div>
            <!-- 条件ノード -->
            <div
              class="row border"
              style="height: 60vh; overflow: auto"
              v-if="formInput.nodeType == 'Condition'"
            >
              <div
                class="nav flex-column nav-pills col-2 border-right"
                id="v-pills-cond-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  class="nav-link active"
                  id="v-pills-url-tab"
                  data-toggle="pill"
                  href="#v-pills-url"
                  role="tab"
                  aria-controls="v-pills-url"
                  aria-selected="true"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  URL</a
                >
                <a
                  class="nav-link"
                  id="v-pills-segment-tab"
                  data-toggle="pill"
                  href="#v-pills-segment"
                  role="tab"
                  aria-controls="v-pills-segment"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__segment') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-conditions-tab"
                  data-toggle="pill"
                  href="#v-pills-conditions"
                  role="tab"
                  aria-controls="v-pills-conditions"
                  aria-selected="false"
                >
                  {{ $t('__MultiCondition') }}</a
                >
                <div class="divider-with-text">
                  <span>{{ $t('__default') }}</span>
                </div>

                <a
                  class="nav-link"
                  id="v-pills-defaultNextNode-tab"
                  data-toggle="pill"
                  href="#v-pills-defaultNextNode"
                  role="tab"
                  aria-controls="v-pills-defaultNextNode"
                  aria-selected="false"
                >
                  {{ $t('__defaultNextNode') }}</a
                >
              </div>
              <div class="tab-content col-10" id="v-pills-cond-tabContent">
                <!-- URL -->
                <div
                  class="tab-pane fade show active"
                  id="v-pills-url"
                  role="tabpanel"
                  aria-labelledby="v-pills-url-tab"
                >
                  <div class="pt-5">
                    <div class="form-group">
                      <label>{{ $t('__conditionUrl') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        :placeholder="$t('__urlPlaceHolder')"
                        v-model="condInput.config_url"
                      />
                    </div>

                    <div class="form-group">
                      <label>{{ $t('__branchSettings') }}</label>
                      <div>
                        <span class="text-danger">*</span>
                        {{ $t('__requiredItem') }}
                      </div>
                      <div
                        class="border p-2 mb-2"
                        v-for="(item, index) in condInput.options"
                        :key="index"
                      >
                        <div class="float-right">
                          <a
                            href="javascript:void(0)"
                            class="text-danger float-right"
                            @click="deleteResponse(index)"
                          >
                            <i class="fas fa-trash text-danger"></i>
                          </a>
                        </div>
                        <div>
                          <div class="form-row align-items-center">
                            <span class="text-danger">*</span>
                            <input type="text" class="form-control col-8" v-model="item.value" />
                            <div>{{ $t('__whenMatching') }}</div>
                          </div>
                          <div class="form-row align-items-center">
                            <md-radio class="ml-3" value="nnode" v-model="item.nextnodeType" />

                            <span class="text-danger" v-if="item.nextnodeType == 'nnode'">*</span>
                            <select
                              class="form-control col-3"
                              id="condnnode"
                              v-model="item.nextNodeId"
                              @focus="onchanged($event, index)"
                            >
                              <option value disabled selected>
                                {{ $t('__NextNodeoptional') }}
                              </option>
                              <option
                                :value="node.nodeId"
                                v-for="(node, idx) in nextNodeSelection"
                                :key="idx"
                              >
                                {{ node.title.substr(0, 25) }}
                                {{ node.subtitle }}
                              </option>
                            </select>
                            <div>{{ $t('__jumpTo') }}</div>

                            <md-radio class="ml-5" value="snode" v-model="item.nextnodeType">
                            </md-radio>
                            <span class="text-danger" v-if="item.nextnodeType == 'snode'">*</span>
                            <select
                              class="form-control col-3"
                              id="condsnode"
                              v-model="item.scenario_id"
                              @focus="onchanged($event, index)"
                            >
                              <option value disabled selected>
                                {{ $t('__NextScenariooptional') }}
                              </option>
                              <option
                                :value="scenario.scenario.scenarioId"
                                v-for="(scenario, idx) in allScenario"
                                :key="idx"
                              >
                                {{ scenario.scenario.scenarioName }}
                              </option>
                            </select>
                            <div>{{ $t('__jumpTo') }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row pt-4 add-btn-input cursor-pointer" @click="addNewResponse()">
                        <a href="javascript:void(0)" class="mx-auto my-3">
                          <i class="fas fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- segment -->
                <div
                  class="tab-pane fade show"
                  id="v-pills-segment"
                  role="tabpanel"
                  aria-labelledby="v-pills-segment-tab"
                >
                  <div class="pt-5">
                    <label>{{ $t('__branchSettings') }}</label>
                    <div>
                      <span class="text-danger">*</span>
                      {{ $t('__requiredItem') }}
                    </div>
                    <div
                      class="border p-2 mb-2"
                      v-for="(item, index) in segmentInput.options"
                      :key="index"
                    >
                      <div class="float-right">
                        <a
                          href="javascript:void(0)"
                          class="text-danger float-right"
                          @click="deleteSegment(index)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </div>
                      <div>
                        <div class="form-row align-items-center">
                          <span class="text-danger">*</span>
                          <select
                            class="form-control col-3 ml-0"
                            id="condnnode"
                            v-model="item.value"
                          >
                            <option value disabled selected>
                              {{ $t('__segment') }}
                            </option>
                            <option
                              :value="segment.id"
                              v-for="(segment, idx) in allSegments"
                              :key="idx"
                            >
                              {{ segment.name }}
                            </option>
                          </select>
                          <div>{{ $t('__whenMatching') }}</div>
                        </div>
                        <div class="form-row align-items-center">
                          <md-radio class="ml-3" value="nnode" v-model="item.nextnodeType" />
                          <span class="text-danger" v-if="item.nextnodeType == 'nnode'">*</span>
                          <select
                            class="form-control col-3"
                            id="segmentnnode"
                            v-model="item.nextNodeId"
                            @focus="onchanged($event, index)"
                          >
                            <option value disabled selected>
                              {{ $t('__NextNodeoptional') }}
                            </option>
                            <option
                              :value="node.nodeId"
                              v-for="(node, idx) in nextNodeSelection"
                              :key="idx"
                            >
                              {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                            </option>
                          </select>
                          <div>{{ $t('__jumpTo') }}</div>

                          <md-radio class="ml-5" value="snode" v-model="item.nextnodeType">
                          </md-radio>
                          <span class="text-danger" v-if="item.nextnodeType == 'snode'">*</span>
                          <select
                            class="form-control col-3"
                            id="segmentsnode"
                            v-model="item.scenario_id"
                            @focus="onchanged($event, index)"
                          >
                            <option value disabled selected>
                              {{ $t('__NextScenariooptional') }}
                            </option>
                            <option
                              :value="scenario.scenario.scenarioId"
                              v-for="(scenario, idx) in allScenario"
                              :key="idx"
                            >
                              {{ scenario.scenario.scenarioName }}
                            </option>
                          </select>
                          <div>{{ $t('__jumpTo') }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-4 add-btn-input cursor-pointer" @click="addNewSegment()">
                      <a href="javascript:void(0)" class="mx-auto my-3">
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- Conditions -->
                <div
                  class="tab-pane fade show"
                  id="v-pills-conditions"
                  role="tabpanel"
                  aria-labelledby="v-pills-conditions-tab"
                >
                  <div class="pt-5" style="min-width: 653.25px">
                    <div
                      class="border p-2 mb-2"
                      v-for="(item, index) in formInput.conditionConfig.options"
                      :key="index"
                    >
                      <a
                        href="javascript:void(0)"
                        class="text-danger pull-right"
                        @click="deleteCondition(index)"
                      >
                        <i class="fas fa-trash text-danger"></i>
                      </a>
                      <div class="clearfix"></div>
                      <div v-for="(subItem, subIndex) in item.multi_condition" :key="subIndex">
                        <div class="form-row px-4 pt-2">
                          <!-- Condition Source -->
                          <div class="form-group col-3">
                            <label>{{ $t('__source') }}</label>
                            <select
                              class="form-control"
                              v-model="subItem.source"
                              @change="onCondSourceChanged($event, index, subIndex)"
                            >
                              <option value="tag">{{ $t('__label') }}</option>
                              <option value="uservariable">
                                {{ $t('__variable') }}
                              </option>
                              <option value="followtime">
                                {{ $t('__FollowAccountTime') }}
                              </option>
                              <option value="cvkey">
                                {{ $t('__CvParam') }}
                              </option>
                            </select>
                          </div>
                          <!-- Condition Type / tag or uservariable -->
                          <div class="form-group col-2">
                            <label>{{ $t('__Condition') }}</label>
                            <select
                              class="form-control"
                              :aria-placeholder="$t('__fontcolor')"
                              v-model="subItem.expression"
                            >
                              <option
                                value="exactly_match"
                                v-if="
                                  (subItem.source == 'tag') | (subItem.source == 'uservariable')
                                "
                                selected
                              >
                                {{ $t('__ExactlyMatch') }}
                              </option>
                              <option
                                value="fuzzy_match"
                                v-if="
                                  (subItem.source == 'tag') | (subItem.source == 'uservariable')
                                "
                              >
                                {{ $t('__FuzzyMatch') }}
                              </option>
                              <option
                                value="has_value"
                                v-if="
                                  (subItem.source == 'tag') |
                                    (subItem.source == 'uservariable') |
                                    (subItem.source == 'cvkey')
                                "
                              >
                                {{ $t('__segmentcondition') }}
                              </option>
                              <option
                                value="no_value"
                                v-if="
                                  (subItem.source == 'tag') |
                                    (subItem.source == 'uservariable') |
                                    (subItem.source == 'cvkey')
                                "
                              >
                                {{ $t('__segmentnocondition') }}
                              </option>
                              <option
                                value="num_range"
                                v-if="
                                  (subItem.source == 'tag') | (subItem.source == 'uservariable')
                                "
                              >
                                {{ $t('__NumberRange') }}
                              </option>
                              <option
                                value="num_gt"
                                v-if="
                                  (subItem.source == 'tag') | (subItem.source == 'uservariable')
                                "
                              >
                                {{ $t('__GreaterThanNumber') }}
                              </option>
                              <option
                                value="num_lt"
                                v-if="
                                  (subItem.source == 'tag') | (subItem.source == 'uservariable')
                                "
                              >
                                {{ $t('__LessThanNumber') }}
                              </option>
                              <option
                                value="date_range"
                                v-if="
                                  (subItem.source == 'tag') |
                                    (subItem.source == 'uservariable') |
                                    (subItem.source == 'followtime')
                                "
                              >
                                {{ $t('__DataRange') }}
                              </option>
                            </select>
                          </div>
                          <!-- Condition Tag -->
                          <div class="form-group col-3" v-if="subItem.source == 'tag'">
                            <label>{{ $t('__label') }}</label>
                            <select
                              class="form-control"
                              v-model="subItem.tag"
                              @change="onCondTagChanged(index, subIndex)"
                            >
                              <option value disabled selected>
                                {{ $t('__TagSelectOptional') }}
                              </option>
                              <option
                                v-for="(tag, index) in tagList"
                                :value="tag"
                                :key="100 + index"
                              >
                                {{ tag.name }}
                              </option>
                            </select>
                          </div>
                          <!-- Condition Tag Value -->
                          <div
                            class="form-group col-3"
                            v-if="
                              (subItem.source == 'tag') & (subItem.expression == 'exactly_match')
                            "
                          >
                            <label>{{ $t('__value') }}</label>
                            <select
                              class="form-control"
                              :aria-placeholder="$t('__fontcolor')"
                              v-model="subItem.value"
                            >
                              <option value disabled selected>
                                {{ $t('__TagValueSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in valuesOfTag(
                                  subItem.tag.color,
                                  subItem.tag.name,
                                )"
                                :value="value"
                                :key="100 + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>
                          <!-- Condition User Variable -->
                          <div class="form-group col-3" v-if="subItem.source == 'uservariable'">
                            <label>{{ $t('__variable') }}</label>
                            <select class="form-control" v-model="subItem.uservariable.name">
                              <option value disabled selected>
                                {{ $t('__UserVariableSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in allVariables"
                                :value="value"
                                :key="'variable-' + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>
                          <!-- Condition Value Input -->
                          <div
                            class="form-group col-3"
                            v-if="
                              (subItem.expression == 'exactly_match') &
                                (subItem.source == 'uservariable') ||
                              subItem.expression == 'fuzzy_match' ||
                              subItem.expression == 'num_gt' ||
                              subItem.expression == 'num_lt'
                            "
                          >
                            <label>{{ $t('__value') }}</label>
                            <input class="form-control" type="text" v-model="subItem.value" />
                          </div>
                          <!-- Condition CV Key Value -->
                          <div class="form-group col-3" v-if="subItem.source == 'cvkey'">
                            <label>{{ $t('__SelectCvParam') }}</label>
                            <select class="form-control" v-model="subItem.value">
                              <option value="orderId" selected>orderId</option>
                            </select>
                          </div>
                          <!-- Condition Number Range -->
                          <div class="form-group col-2" v-if="subItem.expression == 'num_range'">
                            <label>開始</label>
                            <input class="form-control" type="text" v-model="subItem.range.start" />
                          </div>
                          <div class="form-group col-2" v-if="subItem.expression == 'num_range'">
                            <label>結束</label>
                            <input class="form-control" type="text" v-model="subItem.range.end" />
                          </div>
                          <!-- Condition Date Range -->
                          <div
                            class="form-group col-3"
                            v-if="
                              subItem.source == 'followtime' && subItem.expression == 'date_range'
                            "
                          >
                            <label>開始</label>
                            <datetime
                              v-model="subItem.range.start"
                              type="date"
                              input-class="form-control"
                            ></datetime>
                          </div>
                          <div
                            class="form-group col-3"
                            v-if="
                              subItem.source == 'followtime' && subItem.expression == 'date_range'
                            "
                          >
                            <label>結束</label>
                            <datetime
                              v-model="subItem.range.end"
                              type="date"
                              input-class="form-control"
                            ></datetime>
                          </div>
                        </div>
                        <div
                          class="form-row px-4 pt-2"
                          v-if="
                            subItem.source !== 'followtime' && subItem.expression == 'date_range'
                          "
                        >
                          <!-- Condition Date Range -->
                          <div class="form-group col-3">
                            <label>開始</label>
                            <datetime
                              v-model="subItem.range.start"
                              type="date"
                              input-class="form-control"
                            ></datetime>
                          </div>
                          <div class="form-group col-3">
                            <label>結束</label>
                            <datetime
                              v-model="subItem.range.end"
                              type="date"
                              input-class="form-control"
                            ></datetime>
                          </div>
                        </div>
                        <!-- Condition Operations -->
                        <div class="d-flex justify-content-end mt-3">
                          <button
                            v-if="item.multi_condition.length > 1"
                            class="btn btn-danger mr-2"
                            @click="deleteSubCondition(index, subIndex)"
                          >
                            Remove
                          </button>
                          <button
                            v-if="subIndex == item.multi_condition.length - 1"
                            class="btn btn-info"
                            @click="addSubCondition(index)"
                          >
                            AND
                          </button>
                        </div>
                        <hr class="solid" />
                      </div>
                      <div class="row px-4 mt-3">
                        <md-radio
                          class="md-primary ml-3 mr-0"
                          value="nnode"
                          v-model="item.nextnodeType"
                        />

                        <select
                          class="form-control col-4 ml-2"
                          id="tagnnode"
                          v-model="item.nextNodeId"
                          @focus="onchanged($event, index)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextNodeoptional') }}
                          </option>
                          <option
                            :value="node.nodeId"
                            v-for="(node, idx) in nodeData.totalNode"
                            :key="idx"
                          >
                            {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                          </option>
                        </select>
                        <md-radio
                          class="md-primary ml-3 mr-0"
                          value="snode"
                          v-model="item.nextnodeType"
                        >
                        </md-radio>

                        <select
                          class="form-control col-4 ml-2"
                          id="tagsnode"
                          v-model="item.scenario_id"
                          @focus="onchanged($event, index)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextScenariooptional') }}
                          </option>
                          <option
                            :value="scenario.scenario.scenarioId"
                            v-for="(scenario, idx) in allScenario"
                            :key="idx"
                          >
                            {{ scenario.scenario.scenarioName }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="row pt-4">
                      <button class="btn btn-secondary mx-auto" @click="addNewCondition()">
                        {{ formInput.conditionConfig.options.length === 0 ? 'Add' : 'OR' }}
                      </button>
                    </div>
                  </div>
                  <div class="pt-5" style="min-width: 653.25px">
                    <div class="form-group"></div>
                  </div>
                </div>
                <!-- Default Next Node -->
                <div
                  class="tab-pane fade show"
                  id="v-pills-defaultNextNode"
                  role="tabpanel"
                  aria-labelledby="v-pills-defaultNextNode-tab"
                >
                  <div class="form-row align-items-center">
                    <button class="next-node-option" @click="setNextNode('nnode')">
                      <md-radio
                        type="radio"
                        class="ml-3"
                        value="nnode"
                        name="defaultNextNodeType"
                        v-model="formInput.conditionConfig.defaultNextNode.nextnodeType"
                      />
                    </button>
                    <span
                      class="text-danger"
                      v-if="formInput.conditionConfig.defaultNextNode.nextnodeType === 'nnode'"
                      >*</span
                    >
                    <select
                      class="form-control col-3"
                      id="defaultnnode"
                      v-model="formInput.conditionConfig.defaultNextNode.nextNodeId"
                      @focus="onchanged($event)"
                    >
                      <option value disabled selected>
                        {{ $t('__NextNodeoptional') }}
                      </option>
                      <option
                        :value="node.nodeId"
                        v-for="(node, idx) in nextNodeSelection"
                        :key="idx"
                      >
                        {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                      </option>
                    </select>
                    <div>{{ $t('__jumpTo') }}</div>

                    <button class="next-node-option" @click="setNextNode('snode')">
                      <md-radio
                        type="radio"
                        class="ml-5"
                        value="snode"
                        name="defaultNextNodeType"
                        v-model="formInput.conditionConfig.defaultNextNode.nextnodeType"
                      />
                    </button>
                    <span
                      class="text-danger"
                      v-if="formInput.conditionConfig.defaultNextNode.nextnodeType === 'snode'"
                      >*</span
                    >
                    <select
                      class="form-control col-3"
                      id="defaultsnode"
                      v-model="formInput.conditionConfig.defaultNextNode.scenario_id"
                      @focus="onchanged($event)"
                    >
                      <option value disabled selected>
                        {{ $t('__NextScenariooptional') }}
                      </option>
                      <option
                        :value="scenario.scenario.scenarioId"
                        v-for="(scenario, idx) in allScenario"
                        :key="idx"
                      >
                        {{ scenario.scenario.scenarioName }}
                      </option>
                    </select>
                    <div>{{ $t('__jumpTo') }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- 会話ノード -->
            <div
              class="row border"
              style="height: 65vh; overflow: auto"
              v-if="formInput.nodeType == 'Message'"
            >
              <div
                class="nav flex-column nav-pills col-2 border-right pt-2"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <a
                  class="nav-link active"
                  id="v-pills-image_size-tab"
                  data-toggle="pill"
                  href="#v-pills-image_size"
                  role="tab"
                  aria-controls="v-pills-image_size"
                  aria-selected="false"
                  v-if="accountType == 'line'"
                >
                  {{ $t('__flexmessageSize') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-boldText1-tab"
                  data-toggle="pill"
                  href="#v-pills-boldText1"
                  role="tab"
                  aria-controls="v-pills-boldText1"
                  aria-selected="true"
                  v-if="accountType == 'line'"
                >
                  {{ $t('__Titlebold') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-image-tab"
                  data-toggle="pill"
                  href="#v-pills-image"
                  role="tab"
                  aria-controls="v-pills-image"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__image') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-boldText2-tab"
                  data-toggle="pill"
                  href="#v-pills-boldText2"
                  role="tab"
                  aria-controls="v-pills-boldText2"
                  aria-selected="false"
                  v-if="accountType == 'line'"
                >
                  {{ $t('__Subtitlebold') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-text-tab"
                  data-toggle="pill"
                  href="#v-pills-text"
                  role="tab"
                  aria-controls="v-pills-text"
                  aria-selected="false"
                >
                  {{ $t('__Textcontent') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-buttonList-tab"
                  data-toggle="pill"
                  href="#v-pills-buttonList"
                  role="tab"
                  aria-controls="v-pills-buttonList"
                  aria-selected="false"
                >
                  {{ $t('__Button') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-fb-multi-temp-tab"
                  data-toggle="pill"
                  href="#v-pills-fb-multi-temp"
                  role="tab"
                  aria-controls="v-pills-fb-multi-temp"
                  aria-selected="false"
                  v-if="accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__templateUnion') }}</a
                >
                <div class="dropdown-divider" v-if="accountType == 'line'"></div>
                <a
                  class="nav-link"
                  id="v-pills-sticker-tab"
                  data-toggle="pill"
                  href="#v-pills-sticker"
                  role="tab"
                  aria-controls="v-pills-sticker"
                  aria-selected="false"
                  v-if="accountType == 'line'"
                >
                  {{ $t('__Texture') }}</a
                >
                <a
                  class="nav-link"
                  id="v-pills-flexMsg-tab"
                  data-toggle="pill"
                  href="#v-pills-flexMsg"
                  role="tab"
                  aria-controls="v-pills-flexMsg"
                  aria-selected="false"
                  v-if="accountType == 'line'"
                  >Flex Message</a
                >
                <a
                  class="nav-link"
                  id="v-pills-carouselMsg-tab"
                  data-toggle="pill"
                  href="#v-pills-carouselMsg"
                  role="tab"
                  aria-controls="v-pills-carouselMsg"
                  aria-selected="false"
                  v-if="accountType == 'webbot' || accountType == 'line'"
                  >Carousel Message</a
                >
                <a
                  class="nav-link"
                  id="v-pills-carouselImageMsg-tab"
                  data-toggle="pill"
                  href="#v-pills-carouselImageMsg"
                  role="tab"
                  aria-controls="v-pills-carouselImageMsg"
                  aria-selected="false"
                  v-if="accountType == 'webbot' || accountType == 'line'"
                  >Carousel Image Message</a
                >
                <a
                  class="nav-link"
                  id="v-pills-richMsg-tab"
                  data-toggle="pill"
                  href="#v-pills-richMsg"
                  role="tab"
                  aria-controls="v-pills-richMsg"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'webbot'"
                  >Rich Message</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="nav-link"
                  id="v-pills-jump-tab"
                  data-toggle="pill"
                  href="#v-pills-jump"
                  role="tab"
                  aria-controls="v-pills-jump"
                  aria-selected="false"
                  v-if="
                    accountType == 'line' ||
                    accountType == 'fb' ||
                    accountType == 'ig' ||
                    accountType == 'webbot'
                  "
                >
                  {{ $t('__Jump') }}</a
                >
                <a
                  class="nav-link"
                  id="#v-pills-schedule-tab"
                  data-toggle="pill"
                  href="#v-pills-schedule"
                  role="tab"
                  aria-controls="v-pills-schedule"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__Sendbyappointment') }}</a
                >
                <a
                  class="nav-link"
                  id="#v-pills-userreply-tab"
                  data-toggle="pill"
                  href="#v-pills-userreply"
                  role="tab"
                  aria-controls="v-pills-userreply"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__Userreply') }}</a
                >
                <a
                  class="nav-link"
                  id="#v-pills-removevariable-tab"
                  data-toggle="pill"
                  href="#v-pills-removevariable"
                  role="tab"
                  aria-controls="v-pills-removevariable"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__RemoveVariable') }}</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="nav-link"
                  id="#v-pills-imageVideo-tab"
                  data-toggle="pill"
                  href="#v-pills-imageVideo"
                  role="tab"
                  aria-controls="v-pills-imageVideo"
                  aria-selected="false"
                >
                  {{ $t('__ImageVideoMessage') }}</a
                >
                <a
                  class="nav-link"
                  id="#v-pills-datetimepicker-tab"
                  data-toggle="pill"
                  href="#v-pills-datetimepicker"
                  role="tab"
                  aria-controls="v-pills-datetimepicker"
                  aria-selected="false"
                  v-if="accountType == 'line'"
                >
                  {{ $t('__timepickerText') }}</a
                >
                <a
                  class="nav-link"
                  id="#v-pills-externalapisetting-tab"
                  data-toggle="pill"
                  href="#v-pills-externalapisetting"
                  role="tab"
                  aria-controls="v-pills-externalapisetting"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  {{ $t('__Externalapisetting') }}</a
                >
                <div class="dropdown-divider"></div>
                <a
                  class="nav-link"
                  id="#v-pills-textmessage-tab"
                  data-toggle="pill"
                  href="#v-pills-textmessage"
                  role="tab"
                  aria-controls="v-pills-textmessage"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'webbot'"
                  >Text Message</a
                >
                <a
                  class="nav-link"
                  id="#v-pills-quickreply-tab"
                  data-toggle="pill"
                  href="#v-pills-quickreply"
                  role="tab"
                  aria-controls="v-pills-quickreply"
                  aria-selected="false"
                  >{{ $t('__QuickReply') }}</a
                >
                <a
                  class="nav-link"
                  id="#v-datetime-trigger-tab"
                  data-toggle="pill"
                  href="#v-datetime-trigger"
                  role="tab"
                  aria-controls="v-datetime-trigger"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                  >{{ $t('__DateTimeTrigger') }}</a
                >

                <a
                  class="nav-link"
                  id="#v-pills-form-tab"
                  data-toggle="pill"
                  href="#v-pills-form"
                  role="tab"
                  aria-controls="v-pills-form"
                  aria-selected="false"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                  >{{ $t('__Form') }}</a
                >
                <div class="dropdown-divider"></div>
              </div>
              <div class="tab-content col-10" id="v-pills-tabContent">
                <!-- image size -->
                <div
                  class="tab-pane fade active show"
                  id="v-pills-image_size"
                  role="tabpanel"
                  aria-labelledby="v-pills-image_size-tab"
                  v-if="accountType == 'line'"
                >
                  <div class="pt-5">
                    <label>{{ $t('__flexmessageSizeChange') }}</label>
                  </div>
                  <div class="pb-3">
                    <select
                      class="col-3 form-control"
                      name="bubbleSize"
                      id="bubbleSize"
                      v-model="formInput.flexSize"
                    >
                      <option value disabled selected>
                        {{ $t('__setSize') }}
                      </option>
                      <option v-for="item in formInput.bubbleSize" :key="item" :value="item">
                        {{ item }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- bold text -->
                <div
                  class="tab-pane fade"
                  id="v-pills-boldText1"
                  role="tabpanel"
                  aria-labelledby="v-pills-boldText1-tab"
                  v-if="accountType == 'line'"
                >
                  <div class="pt-5">
                    <label> {{ $t('__Titlebold') }}</label>
                    <input
                      type="text"
                      class="form-control col-6"
                      id="bold-text1-input"
                      v-model="formInput.boldText1"
                    />
                  </div>
                </div>
                <!-- image -->
                <div
                  class="tab-pane fade"
                  id="v-pills-image"
                  role="tabpanel"
                  aria-labelledby="v-pills-image-tab"
                  v-if="
                    accountType == 'line' ||
                    accountType == 'fb' ||
                    accountType == 'ig' ||
                    accountType == 'webbot'
                  "
                >
                  <div class="pt-5">
                    <div class="form-check">
                      <!-- Upload image input-->
                      <div class="col-12 pb-4 my-1">
                        <div class="border">
                          <vue-dropzone
                            ref="myVueDropzone"
                            id="myDropzone"
                            :options="dropzoneOptions"
                            v-on:vdropzone-file-added="flexmsgImgHandling"
                          ></vue-dropzone>
                        </div>
                      </div>
                    </div>
                    <div class="form-check">
                      <label>{{ $t('__urltitle') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="image-title"
                        v-model="formInput.urlTitle"
                      />
                    </div>
                    <div class="form-check pt-3">
                      <label> {{ $t('__ImageUrl') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="url-pic-upload"
                        :placeholder="$t('__urlPlaceHolder')"
                        v-model="formInput.urlPic"
                      />
                    </div>
                    <hr />
                    <div class="form-check">
                      <label> {{ $t('__urllinkoptional') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="url-pic-transition"
                        :placeholder="$t('__urlPlaceHolder')"
                        v-model="formInput.urlPicTransition"
                      />
                    </div>
                  </div>
                </div>
                <!-- bold text -->
                <div
                  class="tab-pane fade"
                  id="v-pills-boldText2"
                  role="tabpanel"
                  aria-labelledby="v-pills-boldText2-tab"
                  v-if="accountType == 'line'"
                >
                  <div class="pt-5">
                    <label> {{ $t('__Subtitlebold') }}</label>
                    <input
                      type="text"
                      class="form-control col-6"
                      id="bold-text2-input"
                      v-model="formInput.boldText2"
                    />
                  </div>
                </div>
                <!-- text -->
                <div
                  class="tab-pane fade"
                  id="v-pills-text"
                  role="tabpanel"
                  aria-labelledby="v-pills-text-tab"
                >
                  <div class="pt-5">
                    <label> {{ $t('__Textcontent') }}</label>
                    <a
                      class="ml-2 p-1"
                      href="https://developers.line.biz/media/messaging-api/emoji-list.pdf"
                    >
                      <i class="far fa-laugh"></i>
                    </a>
                    <textarea
                      class="form-control col-9"
                      rows="6"
                      id="text-input"
                      v-model="formInput.text"
                    ></textarea>
                  </div>
                </div>
                <!-- button list -->
                <div
                  class="tab-pane fade"
                  id="v-pills-buttonList"
                  role="tabpanel"
                  aria-labelledby="v-pills-buttonList-tab"
                >
                  <div class="pt-5">
                    <!-- Button direction -->
                    <label v-if="accountType != 'fb'"> {{ $t('__Buttondirection') }}</label>
                    <select
                      class="form-control col-8"
                      v-model="buttonStyleSelect.align"
                      v-if="accountType != 'fb'"
                    >
                      <option value="horizontal">
                        {{ $t('__Horizontal') }}
                      </option>
                      <option value="vertical">{{ $t('__Vertical') }}</option>
                    </select>

                    <label class="number-count mt-4" data-number-count="1">{{
                      $t('__Button')
                    }}</label>
                    <div>
                      <span class="text-danger">*</span>
                      {{ $t('__requiredItem') }}
                    </div>
                    <div
                      class="border p-2 mb-2"
                      v-for="(item, index) in formInput.button"
                      :key="index"
                    >
                      <div class="float-right">
                        <a
                          href="javascript:void(0)"
                          class="text-danger float-right"
                          @click="deleteButton(index)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </div>
                      <br />
                      <!-- Button style -->
                      <div class="form-row px-4">
                        <!-- Button style / Label -->
                        <div class="form-group col-4">
                          <label
                            ><span class="text-danger">*</span> {{ $t('__Textcontent') }}</label
                          >
                          <input type="text" class="form-control" v-model="item.action.label" />
                        </div>
                        <!-- Button style / Color -->
                        <div
                          class="form-group col-4"
                          v-if="accountType == 'line' || accountType == 'webbot'"
                        >
                          <label>{{ $t('__Buttoncolor') }}</label>
                          <input
                            type="text"
                            class="form-control"
                            v-model="item.color"
                            list="buttonColors"
                          />
                          <datalist id="buttonColors">
                            <option value="#00b900">
                              {{ $t('__buttonColorGreen') }}
                            </option>
                            <option value="#0d6efd">
                              {{ $t('__buttonColorBlue') }}
                            </option>
                            <option value="#dc3545">
                              {{ $t('__buttonColorRed') }}
                            </option>
                            <option value="#ffc107">
                              {{ $t('__buttonColorYellow') }}
                            </option>
                            <option value="#adb5bd">
                              {{ $t('__buttonColorGray') }}
                            </option>
                            <option value="#000000">
                              {{ $t('__buttonColorBlack') }}
                            </option>
                          </datalist>
                          <small class="text-muted">{{ $t('__setHexColorCode') }}</small>
                        </div>
                        <!-- Button style / Font -->
                        <div
                          class="form-group col-3"
                          v-if="accountType == 'line' || accountType == 'webbot'"
                        >
                          <label>{{ $t('__fontcolor') }}</label>
                          <select class="form-control" v-model="item.style">
                            <option value="primary">
                              {{ $t('__Fontwhite') }}
                            </option>
                            <option value="secondary">
                              {{ $t('__Fontblack') }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Button Tag -->
                      <div class="px-4 mt-2 mb-3">
                        <label>{{ $t('__label') }}</label>
                        <div class="form-row">
                          <!-- Button Tag / tag -->
                          <select
                            class="col-3 form-control ml-2"
                            id="btntag"
                            v-model="item.tag"
                            @focus="onchanged($event, index)"
                          >
                            <option
                              :value="{
                                color: '',
                                name: '',
                              }"
                              disabled
                              selected
                            >
                              {{ $t('__TagSelectOptional') }}
                            </option>
                            <option
                              v-for="(tag, index) in nodeData.tags"
                              :value="tag"
                              :key="100 + index"
                            >
                              {{ tag.name }}
                            </option>
                          </select>
                          <!-- Button Tags / tag value -->
                          <select
                            class="col-3 form-control ml-2"
                            :aria-placeholder="$t('__fontcolor')"
                            v-model="item.tagValue"
                          >
                            <option value disabled selected>
                              {{ $t('__TagValueSelectOptional') }}
                            </option>
                            <option
                              v-for="(value, index) in valuesOfTag(item.tag.color, item.tag.name)"
                              :value="value"
                              :key="100 + index"
                            >
                              {{ value }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <!-- Button Vars -->
                      <div class="px-4 mt-2 mb-3">
                        <label>{{ $t('__variable') }}</label>
                        <div class="ml-1" v-if="item.userVars.length == 0">
                          <a
                            href="javascript:void(0)"
                            class="text-info m-1"
                            @click="addButtonVar(index)"
                          >
                            <i class="fas fa-plus text-info"></i>
                          </a>
                        </div>
                        <div v-for="(varItem, varIndex) in item.userVars" :key="varIndex">
                          <div class="form-row mt-2">
                            <select class="col-3 form-control ml-2" v-model="varItem.variable">
                              <option value disabled selected>
                                {{ $t('__UserVariableSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in allVariables"
                                :value="value"
                                :key="100 + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                            <input
                              class="col-3 form-control ml-2"
                              type="text"
                              v-model="varItem.value"
                            />
                            <div class="ml-1">
                              <a
                                href="javascript:void(0)"
                                class="text-danger m-1"
                                @click="deleteButtonVar(index, varIndex)"
                                v-if="item.userVars.length > 0"
                              >
                                <i class="fas fa-minus text-danger"></i>
                              </a>
                            </div>
                            <div class="ml-1">
                              <a
                                href="javascript:void(0)"
                                class="text-info m-1"
                                @click="addButtonVar(index)"
                                v-if="varIndex == item.userVars.length - 1"
                              >
                                <i class="fas fa-plus text-info"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Button Action -->
                      <div class="form-row px-4 mt-3">
                        <div class="form-group col-12 mb-0">
                          <label
                            ><span class="text-danger">*</span>
                            {{ $t('__buttonTappedAction') }}</label
                          >
                          <div class="form-group col-auto mb-0">
                            <!-- Button Action / URL -->
                            <div class="form-group col-auto">
                              <label>{{ $t('__jumpToSpecificUrl') }}</label>
                              <div class="form-row align-items-center">
                                <md-radio
                                  type="radio"
                                  class="ml-3"
                                  value="uri"
                                  v-model="item.action.nodetype"
                                />
                                <input
                                  type="text"
                                  class="form-control col-10 ml-0"
                                  :placeholder="$t('__urlPlaceHolder')"
                                  v-model="item.action.type"
                                  :id="'uri-' + index"
                                  @focus="onchanged($event, index)"
                                />
                              </div>
                            </div>
                            <div class="form-row col-auto">
                              <!-- Button Action / Next Scenario -->
                              <div class="form-group col-6">
                                <label>{{ $t('__jumpToSpecificScenario') }}</label>
                                <div class="form-row align-items-center">
                                  <md-radio
                                    type="radio"
                                    class="ml-3"
                                    value="snode"
                                    v-model="item.action.nodetype"
                                  />
                                  <select
                                    class="form-control col-9"
                                    id="snode"
                                    v-model="item.action.data"
                                    @focus="onchanged($event, index)"
                                  >
                                    <option value disabled selected>
                                      {{ $t('__NextScenariooptional') }}
                                    </option>
                                    <option
                                      v-for="(scenario, index) in allScenario"
                                      :value="scenario.scenario.scenarioId"
                                      :key="100 + index"
                                    >
                                      {{ scenario.scenario.scenarioName }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <!-- Button Action / Next Node -->
                              <div class="form-group col-6">
                                <label>{{ $t('__jumpToSpecificNode') }}</label>
                                <div class="form-row align-items-center">
                                  <md-radio
                                    type="radio"
                                    class="ml-3"
                                    value="nnode"
                                    v-model="item.action.nodetype"
                                  />

                                  <select
                                    class="form-control col-9"
                                    id="nnode"
                                    v-model="item.action.data"
                                    @focus="onchanged($event, index)"
                                  >
                                    <option value disabled selected>
                                      {{ $t('__NextNodeoptional') }}
                                    </option>
                                    <option
                                      :value="node.nodeId"
                                      v-for="(node, idx) in nodeData.totalNode"
                                      :key="idx"
                                    >
                                      {{ node.title.substr(0, 25) }}
                                      {{ node.subtitle }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="row pt-4 add-btn-input cursor-pointer"
                      @click="addNewButton()"
                      v-if="
                        accountType != 'fb' || (accountType == 'fb' && formInput.button.length < 3)
                      "
                    >
                      <a href="javascript:void(0)" class="mx-auto my-3">
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- FB Multi Template -->
                <div
                  class="tab-pane fade"
                  id="v-pills-fb-multi-temp"
                  role="tabpanel"
                  aria-labelledby="v-pills-fb-multi-temp-tab"
                  v-if="accountType == 'fb' || accountType == 'ig'"
                >
                  <fb-generic-template-setting
                    v-bind:elementsInput="formInput.fbGenericElements"
                    v-bind:tagsInput="nodeData.tags"
                    v-bind:allTags="allTags"
                    v-bind:allNodes="nodeData.totalNode"
                    v-bind:allScenario="allScenario"
                    v-bind:allVariables="allVariables"
                    @update="updateFormValue"
                  ></fb-generic-template-setting>
                </div>
                <!-- sticker -->
                <div
                  class="tab-pane fade"
                  id="v-pills-sticker"
                  role="tabpanel"
                  aria-labelledby="v-pills-sticker-tab"
                  v-if="accountType == 'line'"
                >
                  <div class="pt-5">
                    <div class="row align-items-center ml-2">
                      <label class="mt-2">{{ $t('__packageID') }}</label>
                      <input
                        type="text"
                        class="form-control col-3 ml-1"
                        v-model="formInput.packageId"
                      />
                      <label class="mt-2 ml-4">{{ $t('__stickerID') }}</label>
                      <input
                        type="text"
                        class="form-control col-3 ml-1"
                        v-model="formInput.stickerId"
                      />
                    </div>
                    <div class="row mt-3 ml-3">
                      <a
                        :href="this.$t('__stickerRefUrl')"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ this.$t('__StickerList') }}</a
                      >
                    </div>
                  </div>
                </div>
                <!-- flex Message -->
                <div
                  class="tab-pane fade"
                  id="v-pills-flexMsg"
                  role="tabpanel"
                  aria-labelledby="v-pills-flexMsg-tab"
                  v-if="accountType == 'line'"
                >
                  <div class="pt-5">
                    <div class="alert alert-danger" role="alert" v-show="flexMsgInvalid">
                      {{ flexMsgInvalid }}
                    </div>
                    <div class="pb-3">
                      <label>{{ $t('__flexMsgTitle') }}</label>
                      <input
                        type="text"
                        class="form-control col-6"
                        id="flexMsg-title"
                        v-model="formInput.flexMsgTitle"
                      />
                    </div>
                    <label>Flex Message:</label>
                    <textarea
                      class="form-control col-9"
                      rows="9"
                      id="flexMsg-input"
                      v-model="formInput.flexMessage"
                    ></textarea>
                    {{ $t('__WhenusingFlexMessageyoucanuse') }}
                    <a
                      href="https://developers.line.biz/flex-simulator/"
                      style="color: #007bff"
                      target="_blank"
                      rel="noopener noreferrer"
                      >Flex Message Simulator</a
                    >{{ $t('__Viewtheeditedcontentinthewindowinstantly') }}
                    <div
                      class="border p-2 mt-2 mb-2"
                      v-for="(item, index) in formInput.flexMsgConfigs"
                      :key="index"
                    >
                      <div class="float-right">
                        <a
                          href="javascript:void(0)"
                          class="text-danger float-right"
                          @click="deleteFlexMsgConfig(index)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </div>
                      <br />
                      <!-- Replacement Text / Scenario / Tag / Value -->
                      <div class="row px-3">
                        <input
                          type="text"
                          class="col-2 form-control"
                          :placeholder="$t('__FlexReplaceTxtHint')"
                          v-model="item.replacement_str"
                        />
                        <select class="col-3 form-control ml-2" v-model="item.scenarioId">
                          <option value disabled selected>
                            {{ $t('__NextScenariooptional') }}
                          </option>
                          <option
                            :value="scenario.scenario.scenarioId"
                            v-for="(scenario, index) in allScenario"
                            :key="100 + index"
                          >
                            {{ scenario.scenario.scenarioName }}
                          </option>
                        </select>
                        <select
                          class="col-3 form-control ml-2"
                          id="flexcfgtag"
                          v-model="item.tag"
                          @focus="onchanged($event, index)"
                        >
                          <option
                            :value="{
                              color: '',
                              name: '',
                            }"
                            disabled
                            selected
                          >
                            {{ $t('__TagSelectOptional') }}
                          </option>
                          <option
                            v-for="(tag, index) in nodeData.tags"
                            :value="tag"
                            :key="100 + index"
                          >
                            {{ tag.name }}
                          </option>
                        </select>

                        <select
                          class="col-3 form-control ml-2"
                          :aria-placeholder="$t('__fontcolor')"
                          v-model="item.tagValue"
                        >
                          <option value disabled selected>
                            {{ $t('__TagValueSelectOptional') }}
                          </option>
                          <option
                            v-for="(value, index) in valuesOfTag(item.tag.color, item.tag.name)"
                            :value="value"
                            :key="100 + index"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <div class="px-1 mt-2 mb-3">
                        <label>{{ $t('__variable') }}</label>
                        <div class="ml-1" v-if="item.userVars.length == 0">
                          <a
                            href="javascript:void(0)"
                            class="text-info m-1"
                            @click="addFlexMsgVar(index)"
                          >
                            <i class="fas fa-plus text-info"></i>
                          </a>
                        </div>
                        <div v-for="(varItem, varIndex) in item.userVars" :key="varIndex">
                          <div class="form-row mt-2">
                            <select class="col-3 form-control ml-2" v-model="varItem.variable">
                              <option value disabled selected>
                                {{ $t('__UserVariableSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in allVariables"
                                :value="value"
                                :key="100 + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                            <input
                              class="col-3 form-control ml-2"
                              type="text"
                              v-model="varItem.value"
                            />
                            <div class="ml-1">
                              <a
                                href="javascript:void(0)"
                                class="text-danger m-1"
                                @click="deleteFlexMsgVar(index, varIndex)"
                                v-if="item.userVars.length > 0"
                              >
                                <i class="fas fa-minus text-danger"></i>
                              </a>
                            </div>
                            <div class="ml-1">
                              <a
                                href="javascript:void(0)"
                                class="text-info m-1"
                                @click="addFlexMsgVar(index)"
                                v-if="varIndex == item.userVars.length - 1"
                              >
                                <i class="fas fa-plus text-info"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row pt-4 add-btn-input cursor-pointer" @click="addFlexMsgConfig()">
                      <a href="javascript:void(0)" class="mx-auto my-3">
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <!-- Carousel Message -->
                <div
                  class="tab-pane fade"
                  id="v-pills-carouselMsg"
                  role="tabpanel"
                  aria-labelledby="v-pills-carouselMsg-tab"
                  v-if="accountType == 'webbot' || accountType == 'line'"
                >
                  <div class="pb-3">
                    <label>{{ $t('__carouselMsgTitle') }}</label>
                    <input
                      type="text"
                      class="form-control col-6"
                      id="carouselMsg-title"
                      v-model="formInput.carouselMsgTitle"
                    />
                  </div>
                  <div class="bubbles-container">
                    <div
                      v-for="(bubble, index) in formInput.carouselMsg"
                      :key="index"
                      class="bubble-wrapper"
                    >
                      <BubbleEditor
                        :bubble="bubble"
                        :index="index"
                        :accountType="accountType"
                        :uploadImage="imgHandling"
                        :dropzoneOptions="dropzoneOptions"
                        @updateBubble="updateBubble"
                        @deleteBubble="deleteBubble"
                      />
                    </div>
                    <button class="btn btn-primary mt-3" @click="addBubble">
                      {{ $t('__addBubble') }}
                    </button>
                  </div>
                </div>

                <!-- Carousel Image Message -->
                <div
                  class="tab-pane fade"
                  id="v-pills-carouselImageMsg"
                  role="tabpanel"
                  aria-labelledby="v-pills-carouselImageMsg-tab"
                  v-if="accountType == 'webbot' || accountType == 'line'"
                >
                  <div class="pb-3">
                    <label>{{ $t('__carouselMsgTitle') }}</label>
                    <input
                      type="text"
                      class="form-control col-6"
                      id="carouselMsg-title"
                      v-model="formInput.carouselMsgTitle"
                    />
                  </div>
                  <div class="bubbles-container">
                    <div
                      v-for="(bubble, index) in formInput.carouselImageMsg"
                      :key="index"
                      class="bubble-wrapper"
                    >
                      <ImageBubbleEditor
                        :bubble="bubble"
                        :index="index"
                        :uploadImage="imgHandling"
                        :dropzoneOptions="dropzoneOptions"
                        @updateBubble="updateImageBubble"
                        @deleteBubble="deleteImageBubble"
                      />
                    </div>
                    <button class="btn btn-primary mt-3" @click="addImageBubble">
                      {{ $t('__addBubble') }}
                    </button>
                  </div>
                </div>

                <!-- Rich Message -->
                <div
                  class="tab-pane fade"
                  id="v-pills-richMsg"
                  role="tabpanel"
                  aria-labelledby="v-pills-richMsg-tab"
                  v-if="accountType == 'line' || accountType == 'webbot'"
                >
                  <div class="pt-5">
                    <div class="form-check">
                      <!-- Upload image input-->
                      <div class="col-12 pb-4">
                        <div class="border">
                          <vue-dropzone
                            ref="RichMsgVueDropzone"
                            id="richMsgDropzone"
                            :options="dropzoneOptions"
                            v-on:vdropzone-file-added="richMsgImgHandling"
                          ></vue-dropzone>
                        </div>
                      </div>
                    </div>
                    <div class="form-check mt-2">
                      <label><span class="text-danger">*</span> {{ $t('__richMsgTitle') }}</label>
                      <input
                        type="text"
                        class="form-control col-6"
                        id="richMsg-title"
                        v-model="formInput.richMsg.title"
                      />
                    </div>
                    <div class="form-check mt-2">
                      <label><span class="text-danger">*</span> {{ $t('__ImageUrl') }}</label>
                      <input
                        type="text"
                        class="form-control"
                        id="richMsg-url-pic-upload"
                        :placeholder="$t('__urlPlaceHolder')"
                        v-model="formInput.richMsg.baseUrl"
                        disabled
                      />
                    </div>
                    <div class="form-check mt-2" v-if="formInput.richMsg.baseUrl">
                      <label>Preview</label>
                      <br />
                      <img
                        :src="formInput.richMsg.baseUrl + '/240'"
                        label="Image Preview"
                        class="img-thumbnail"
                        alt="Image Preview"
                      />
                    </div>
                    <div class="form-check mt-2">
                      <label><span class="text-danger">*</span> {{ $t('__richMsgLayout') }}</label>
                      <div class="container">
                        <div class="row">
                          <div class="container col-2" @click="onRichMsgLayoutChanged('A')">
                            <div
                              class="row layout-container"
                              v-bind:class="{
                                'layout-selected': formInput.richMsg.layout === 'A',
                              }"
                            >
                              <div
                                class="col-12 richmsg-layout-unit1 align-items-center"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'A',
                                }"
                              >
                                <p style="text-align: center; margin-top: 40%">A</p>
                              </div>
                            </div>
                          </div>
                          <div class="container col-2" @click="onRichMsgLayoutChanged('B')">
                            <div
                              class="row layout-container"
                              v-bind:class="{
                                'layout-selected': formInput.richMsg.layout === 'B',
                              }"
                            >
                              <div
                                class="col-6 richmsg-layout-unit1"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'B',
                                }"
                              >
                                <p style="text-align: center; margin-top: 80%">A</p>
                              </div>
                              <div
                                class="col-6 richmsg-layout-unit1"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'B',
                                }"
                              >
                                <p style="text-align: center; margin-top: 80%">B</p>
                              </div>
                            </div>
                          </div>
                          <div class="container col-2" @click="onRichMsgLayoutChanged('C')">
                            <div
                              class="row layout-container"
                              v-bind:class="{
                                'layout-selected': formInput.richMsg.layout === 'C',
                              }"
                            >
                              <div
                                class="col-12 richmsg-layout-unit2"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'C',
                                }"
                              >
                                <p style="text-align: center; margin-top: 20%">A</p>
                              </div>
                              <div
                                class="col-12 richmsg-layout-unit2"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'C',
                                }"
                              >
                                <p style="text-align: center; margin-top: 20%">B</p>
                              </div>
                            </div>
                          </div>
                          <div class="container col-2" @click="onRichMsgLayoutChanged('D')">
                            <div
                              class="row layout-container"
                              v-bind:class="{
                                'layout-selected': formInput.richMsg.layout === 'D',
                              }"
                            >
                              <div
                                class="col-12 richmsg-layout-unit2"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'D',
                                }"
                              >
                                <p style="text-align: center; margin-top: 20%">A</p>
                              </div>
                              <div
                                class="col-6 richmsg-layout-unit2"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'D',
                                }"
                                v-for="n in 2"
                                :key="n"
                              >
                                <p style="text-align: center; margin-top: 40%" v-if="n == 1">B</p>
                                <p style="text-align: center; margin-top: 40%" v-if="n == 2">C</p>
                              </div>
                            </div>
                          </div>
                          <div class="container col-2" @click="onRichMsgLayoutChanged('E')">
                            <div
                              class="row layout-container"
                              v-bind:class="{
                                'layout-selected': formInput.richMsg.layout === 'E',
                              }"
                            >
                              <div
                                class="col-6 richmsg-layout-unit2"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'E',
                                }"
                                v-for="n in 2"
                                :key="'richlayout-ab-' + n"
                              >
                                <p style="text-align: center; margin-top: 40%" v-if="n == 1">A</p>
                                <p style="text-align: center; margin-top: 40%" v-if="n == 2">B</p>
                              </div>
                              <div
                                class="col-6 richmsg-layout-unit2"
                                v-bind:class="{
                                  'layout-selected': formInput.richMsg.layout === 'E',
                                }"
                                v-for="n in 2"
                                :key="'richlayout-cd-' + n"
                              >
                                <p style="text-align: center; margin-top: 40%" v-if="n == 1">C</p>
                                <p style="text-align: center; margin-top: 40%" v-if="n == 2">D</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="form-check mt-2">
                      <label><span class="text-danger">*</span> {{ $t('__action') }}</label>
                      <div
                        class="action-container mb-1"
                        v-for="(action, actionIndex) in formInput.richMsg.areaOptions"
                        :key="action.label"
                      >
                        <label class="ml-3 mt-2">{{ action.label }}</label>
                        <div class="row px-4 mt-2">
                          <md-radio
                            type="radio"
                            class="ml-3"
                            value="message"
                            v-model="action.type"
                          />

                          <input
                            type="text"
                            class="form-control col-10 ml-0"
                            :placeholder="$t('__Textcontent')"
                            :id="'text-' + action.label"
                            @focus="onRichMsgActionTypeChanged($event, actionIndex)"
                            v-model="action.text"
                          />
                        </div>
                        <div class="row px-4 mt-2">
                          <md-radio type="radio" class="ml-3" value="uri" v-model="action.type" />

                          <input
                            type="text"
                            class="form-control col-10 ml-0"
                            :placeholder="$t('__urllinkoptional')"
                            :id="'uri-' + action.label"
                            @focus="onRichMsgActionTypeChanged($event, actionIndex)"
                            v-model="action.url"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Jump -->
                <div
                  class="tab-pane fade"
                  id="v-pills-jump"
                  role="tabpanel"
                  aria-labelledby="v-pills-jump-tab"
                  v-if="
                    accountType == 'line' ||
                    accountType == 'fb' ||
                    accountType == 'ig' ||
                    accountType == 'webbot'
                  "
                >
                  <div class="pt-5">
                    <!-- Next Scenario/Node -->
                    <div class="row align-items-center mt-2">
                      <md-radio type="radio" class="ml-3" value="scenario" v-model="jumpType" />
                      <select
                        class="form-control col-4 mr-2"
                        id="jumpscenario"
                        v-model="nextScenario"
                        @focus="onchanged($event)"
                      >
                        <option value selected>
                          {{ $t('__NextScenariooptional') }}
                        </option>
                        <option
                          :value="scenario.scenario.scenarioId"
                          v-for="(scenario, index) in allScenario"
                          :key="100 + index"
                        >
                          {{ scenario.scenario.scenarioName }}
                        </option>
                      </select>

                      <md-radio type="radio" class="ml-3" value="node" v-model="jumpType" />

                      <select
                        class="form-control col-4 mr-2"
                        id="jumpnode"
                        v-model="nextNode"
                        @focus="onchanged($event)"
                      >
                        <option value selected>
                          {{ $t('__NextNodeoptional') }}
                        </option>
                        <option
                          :value="node.nodeId"
                          v-for="(node, index) in nextAndSameNodeSelection"
                          :key="index"
                        >
                          {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <!-- schedule -->
                <div
                  class="tab-pane fade pt-5"
                  id="v-pills-schedule"
                  role="tabpanel"
                  aria-labelledby="v-pills-schedule-tab"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  <div
                    class="border p-2 mb-2"
                    v-for="(item, index) in formInput.reservations"
                    :key="index"
                  >
                    <div class="float-right">
                      <a
                        href="javascript:void(0)"
                        class="text-danger float-right"
                        @click="deleteNewReservation(index)"
                      >
                        <i class="fas fa-trash text-danger"></i>
                      </a>
                    </div>
                    <br />
                    <div class="form-row px-4">
                      <div class="form-group col-5">
                        <label
                          ><span class="text-danger">*</span> {{ $t('__Sentconversation') }}</label
                        >
                        <select class="form-control" v-model="item.schedule">
                          <option value selected>
                            {{ $t('__NextScenariooptional') }}
                          </option>
                          <option
                            :value="scenario.scenario.scenarioId"
                            v-for="(scenario, idx) in allScenario"
                            :key="idx"
                          >
                            {{ scenario.scenario.scenarioName }}
                          </option>
                        </select>
                      </div>
                      <div class="form-group col-6">
                        <label>{{ $t('__Appointment') }}</label>
                        <div class="form-inline">
                          <input
                            type="number"
                            class="form-control col-4 mr-0"
                            v-model="item.scheduletime"
                          />

                          <select class="form-control ml-1" v-model="item.scheduleunit">
                            <option value="days">{{ $t('__days') }}</option>
                            <option value="hours">{{ $t('__hours') }}</option>
                            <option value="minutes">
                              {{ $t('__minutes') }}
                            </option>
                          </select>
                          <label>{{ $t('__Rear') }}</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-row px-4">
                      <div class="form-group col-3">
                        <label>{{ $t('__timepickerStartTime') }}</label>
                        <a
                          href="javascript:void(0)"
                          class="text-danger m-1"
                          @click="clearReservationTimeRange(index, 'start')"
                        >
                          <i class="fas fa-undo text-success"></i>
                        </a>
                        <input type="time" class="form-control" v-model="item.window.start" />
                      </div>
                      <div class="form-group col-3">
                        <label>{{ $t('__timepickerEndTime') }}</label>
                        <a
                          href="javascript:void(0)"
                          class="text-danger m-1"
                          @click="clearReservationTimeRange(index, 'end')"
                        >
                          <i class="fas fa-undo text-success"></i>
                        </a>
                        <input type="time" class="form-control" v-model="item.window.end" />
                      </div>
                    </div>
                  </div>
                  <div class="row pt-4 add-btn-input cursor-pointer" @click="addNewReservation()">
                    <a href="javascript:void(0)" class="mx-auto my-3">
                      <i class="fas fa-plus"></i>
                    </a>
                  </div>
                </div>
                <!-- imageMessage -->
                <div
                  class="tab-pane fade"
                  id="v-pills-imageVideo"
                  role="tabpanel"
                  aria-labelledby="v-pills-imageVideo-tab"
                >
                  <div class="pt-5">
                    <div class="form-check">
                      <!-- Upload image input-->
                      <div class="col-9 pb-4 my-1">
                        <div class="border">
                          <vue-dropzone
                            ref="OriginalVueDropzone"
                            id="originalDropzone"
                            :options="dropzoneOptions"
                            v-on:vdropzone-file-added="originalImgHandling"
                          ></vue-dropzone>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="accountType != 'fb'" class="form-check">
                    <label>{{ $t('__ImageUrl') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="origin-url-pic-upload"
                      :placeholder="'https://...'"
                      v-model="formInput.originalImageUrl"
                    />
                  </div>
                  <div v-if="accountType == 'fb' || accountType == 'ig'" class="form-check">
                    <label>Attachment Id</label>
                    <input
                      type="text"
                      class="form-control"
                      id="attach-id-pic-upload-fb"
                      v-model="formInput.originalImageUrl"
                    />
                  </div>
                  <div
                    v-if="accountType == 'line' || accountType == 'webbot'"
                    class="form-check pt-3"
                  >
                    <label>{{ $t('__VideoUrl') }}</label>
                    <input
                      type="text"
                      class="form-control"
                      id="origin-url-video-upload"
                      :placeholder="'https://...'"
                      v-model="formInput.originalVideoUrl"
                    />
                  </div>
                </div>
                <!-- Datetime picker -->
                <div
                  class="tab-pane fade"
                  id="v-pills-datetimepicker"
                  role="tabpanel"
                  aria-labelledby="v-pills-datetimepicker-tab"
                >
                  <div class="pt-5">
                    <div>
                      <span class="text-danger">*</span>
                      {{ $t('__requiredItem') }}
                    </div>
                    <!-- Datetime picker style -->
                    <div class="form-row px-4 pt-4">
                      <!-- Datetime picker style / Label -->
                      <div class="form-group col-4">
                        <label><span class="text-danger">*</span> {{ $t('__Textcontent') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formInput.datetimepicker.action.label"
                        />
                      </div>
                      <!-- Datetime picker style / Color -->
                      <div class="form-group col-4">
                        <label>{{ $t('__Buttoncolor') }}</label>
                        <input
                          type="text"
                          class="form-control"
                          v-model="formInput.datetimepicker.color"
                          list="buttonColors"
                        />
                        <datalist id="buttonColors">
                          <option value="#00b900">
                            {{ $t('__buttonColorGreen') }}
                          </option>
                          <option value="#0d6efd">
                            {{ $t('__buttonColorBlue') }}
                          </option>
                          <option value="#dc3545">
                            {{ $t('__buttonColorRed') }}
                          </option>
                          <option value="#ffc107">
                            {{ $t('__buttonColorYellow') }}
                          </option>
                          <option value="#adb5bd">
                            {{ $t('__buttonColorGray') }}
                          </option>
                          <option value="#000000">
                            {{ $t('__buttonColorBlack') }}
                          </option>
                        </datalist>
                        <small class="text-muted">{{ $t('__setHexColorCode') }}</small>
                      </div>
                      <!-- Datetime picker style / Font -->
                      <div class="form-group col-3">
                        <label>{{ $t('__fontcolor') }}</label>
                        <select class="form-control" v-model="formInput.datetimepicker.style">
                          <option value="primary">
                            {{ $t('__Fontwhite') }}
                          </option>
                          <option value="secondary">
                            {{ $t('__Fontblack') }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- Datetime picker Mode and Single Var -->
                    <div class="form-row px-4 pt-4">
                      <div class="form-group col-4">
                        <label><span class="text-danger">*</span> MODE</label>
                        <select
                          class="form-control"
                          v-model="formInput.datetimepicker.action.mode"
                          @change="onDtPickerTypechanged(true)"
                        >
                          <option value="date">Date</option>
                          <option value="time">Time</option>
                          <option value="datetime">Datetime</option>
                        </select>
                      </div>
                      <div class="form-group col-4">
                        <label>{{ $t('__timepickerVariable') }}</label>
                        <select class="form-control" v-model="formInput.datetimepicker.varName">
                          <option value disabled selected>
                            {{ $t('__UserVariableSelectOptional') }}
                          </option>
                          <option
                            v-for="(value, index) in allVariables"
                            :value="value"
                            :key="100 + index"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- Datetime picker Tag -->
                    <div class="px-4 mt-2 mb-3">
                      <label>{{ $t('__label') }}</label>
                      <div class="form-row">
                        <!-- Datetime picker Tag / tag -->
                        <select
                          class="col-3 form-control ml-2"
                          id="datetimepicker"
                          v-model="formInput.datetimepicker.tag"
                          @focus="onchanged($event)"
                        >
                          <option
                            :value="{
                              color: '',
                              name: '',
                            }"
                            disabled
                            selected
                          >
                            {{ $t('__TagSelectOptional') }}
                          </option>
                          <option
                            v-for="(tag, index) in nodeData.tags"
                            :value="tag"
                            :key="100 + index"
                          >
                            {{ tag.name }}
                          </option>
                        </select>
                        <!-- Datetime picker Tag / tag value -->
                        <select
                          class="col-3 form-control ml-2"
                          :aria-placeholder="$t('__fontcolor')"
                          v-model="formInput.datetimepicker.tagValue"
                        >
                          <option value disabled selected>
                            {{ $t('__TagValueSelectOptional') }}
                          </option>
                          <option
                            v-for="(value, index) in valuesOfTag(
                              formInput.datetimepicker.tag.color,
                              formInput.datetimepicker.tag.name,
                            )"
                            :value="value"
                            :key="100 + index"
                          >
                            {{ value }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- Datetime picker Vars -->
                    <div class="px-4 mt-2 mb-3">
                      <label>{{ $t('__variable') }}</label>
                      <div class="ml-1" v-if="formInput.datetimepicker.userVars.length == 0">
                        <a
                          href="javascript:void(0)"
                          class="text-info m-1"
                          @click="addDtPickerVar()"
                        >
                          <i class="fas fa-plus text-info"></i>
                        </a>
                      </div>
                      <div
                        v-for="(varItem, varIndex) in formInput.datetimepicker.userVars"
                        :key="varIndex"
                      >
                        <div class="form-row mt-2">
                          <select class="col-3 form-control ml-2" v-model="varItem.variable">
                            <option value disabled selected>
                              {{ $t('__UserVariableSelectOptional') }}
                            </option>
                            <option
                              v-for="(value, index) in allVariables"
                              :value="value"
                              :key="100 + index"
                            >
                              {{ value }}
                            </option>
                          </select>
                          <input
                            class="col-3 form-control ml-2"
                            type="text"
                            v-model="varItem.value"
                          />
                          <div class="ml-1">
                            <a
                              href="javascript:void(0)"
                              class="text-danger m-1"
                              @click="deleteDtPickerVar(varIndex)"
                              v-if="formInput.datetimepicker.userVars.length > 0"
                            >
                              <i class="fas fa-minus text-danger"></i>
                            </a>
                          </div>
                          <div class="ml-1">
                            <a
                              href="javascript:void(0)"
                              class="text-info m-1"
                              @click="addDtPickerVar()"
                              v-if="varIndex == formInput.datetimepicker.userVars.length - 1"
                            >
                              <i class="fas fa-plus text-info"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Datetime picker initial time -->
                    <div class="px-4 mt-2 mb-3">
                      <label>{{ $t('__timepickerInitialTime') }}</label>
                      <a
                        href="javascript:void(0)"
                        class="text-danger m-1"
                        @click="clearDtPickerDate('initial')"
                      >
                        <i class="fas fa-undo text-success"></i>
                      </a>
                      <div class="form-row">
                        <div class="form-group col-4">
                          <input
                            :type="dtPickerType"
                            class="form-control ml-2"
                            v-model="formInput.datetimepicker.action.initial"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Datetime picker initial max/min -->
                    <div class="px-4 mt-2 mb-3">
                      <div class="form-row">
                        <div class="form-group col-4">
                          <label>{{ $t('__timepickerMinTime') }}</label>
                          <a
                            href="javascript:void(0)"
                            class="text-danger m-1"
                            @click="clearDtPickerDate('min')"
                          >
                            <i class="fas fa-undo text-success"></i>
                          </a>
                          <input
                            :type="dtPickerType"
                            class="form-control ml-2"
                            v-model="formInput.datetimepicker.action.min"
                          />
                        </div>
                        <div class="form-group col-4">
                          <label>{{ $t('__timepickerMaxTime') }}</label>
                          <a
                            href="javascript:void(0)"
                            class="text-danger m-1"
                            @click="clearDtPickerDate('max')"
                          >
                            <i class="fas fa-undo text-success"></i>
                          </a>
                          <input
                            :type="dtPickerType"
                            class="form-control ml-2"
                            v-model="formInput.datetimepicker.action.max"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- Datetime picker Action -->
                    <div class="form-row px-4 mt-3">
                      <div class="form-group col-12 mb-0">
                        <label>{{ $t('__buttonTappedAction') }}</label>
                        <div class="form-group col-auto mb-0">
                          <div class="form-row col-auto">
                            <!-- Datetime picker / Next Scenario -->
                            <div class="form-group col-6">
                              <label>{{ $t('__jumpToSpecificScenario') }}</label>
                              <div class="form-row align-items-center">
                                <md-radio
                                  type="radio"
                                  class="ml-3"
                                  value="datesnode"
                                  v-model="formInput.datetimepicker.nodetype"
                                />
                                <select
                                  class="form-control col-9"
                                  id="datesnode"
                                  @focus="onchanged($event)"
                                  v-model="formInput.datetimepicker.nextScenario"
                                >
                                  <option value disabled selected>
                                    {{ $t('__NextScenariooptional') }}
                                  </option>
                                  <option
                                    v-for="(scenario, index) in allScenario"
                                    :value="scenario.scenario.scenarioId"
                                    :key="100 + index"
                                  >
                                    {{ scenario.scenario.scenarioName }}
                                  </option>
                                </select>
                              </div>
                            </div>
                            <!-- Datetime picker / Next Node -->
                            <div class="form-group col-6">
                              <label>{{ $t('__jumpToSpecificNode') }}</label>
                              <div class="form-row align-items-center">
                                <md-radio
                                  type="radio"
                                  class="ml-3"
                                  value="datennode"
                                  v-model="formInput.datetimepicker.nodetype"
                                />

                                <select
                                  class="form-control col-9"
                                  id="datennode"
                                  @focus="onchanged($event)"
                                  v-model="formInput.datetimepicker.nextNode"
                                >
                                  <option value disabled selected>
                                    {{ $t('__NextNodeoptional') }}
                                  </option>
                                  <option
                                    :value="node.nodeId"
                                    v-for="(node, index) in nextNodeSelection"
                                    :key="index"
                                  >
                                    {{ node.title.substr(0, 25) }}
                                    {{ node.subtitle }}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- User Reply -->
                <div
                  class="tab-pane fade"
                  id="v-pills-userreply"
                  role="tabpanel"
                  aria-labelledby="v-pills-userreply-tab"
                >
                  <div class="pt-5">
                    <label> {{ $t('__variable') }}</label>
                    <a
                      href="javascript:void(0)"
                      class="text-info m-1"
                      @click="showExistVariable('UserReply')"
                    >
                      <i class="fas fa-edit text-info"></i>
                    </a>
                    <div class="form-row">
                      <div
                        v-for="(variable, index) in formInput.userAction.userReply.variables"
                        :key="index"
                      >
                        <button class="btn btn-link btn-variable">${{ variable }}</button>
                      </div>
                    </div>

                    <div
                      class="form-row"
                      v-for="(items, index) in formInput.userAction.userReply.validate.inputList"
                      :key="items.type"
                    >
                      <select
                        class="form-control col-3 ml-2"
                        id="userreplyscenario"
                        v-model="items.type"
                        @change="onUserReplyValidateTypeChanged($event, index)"
                      >
                        <option value="null" selected>
                          {{ $t('__UserreplyVariableType') }}
                        </option>
                        <option value="Eng">
                          {{ $t('__UserreplyVariableType_Eng') }}
                        </option>
                        <option value="Num">
                          {{ $t('__UserreplyVariableType_Num') }}
                        </option>
                        <option value="Len">
                          {{ $t('__UserreplyVariableType_Len') }}
                        </option>
                        <option value="RegExp">
                          {{ $t('__UserreplyVariableType_RegExp') }}
                        </option>
                        <option value="Phone">
                          {{ $t('__UserreplyVariableType_Phone') }}
                        </option>
                        <option value="Post">
                          {{ $t('__UserreplyVariableType_Post') }}
                        </option>
                        <option value="City">
                          {{ $t('__UserreplyVariableType_City') }}
                        </option>
                      </select>
                      <select
                        v-if="items.type == 'Len'"
                        class="col-1 form-control ml-2"
                        type="text"
                        v-model="formInput.userAction.userReply.selectCompare"
                      >
                        <option
                          v-for="item in formInput.userAction.userReply.lensCompare"
                          :key="item.value"
                          :value="item.label"
                        >
                          {{ item.value }}
                        </option>
                      </select>
                      <input
                        v-if="items.type == 'Len'"
                        type="text"
                        class="col-3 form-control ml-2"
                        v-model="items.value"
                        :placeholder="$t('__UserreplyVariableType_Len')"
                      />
                      <input
                        v-if="items.type == 'RegExp'"
                        type="text"
                        class="col-3 form-control ml-2"
                        v-model="items.value"
                        :placeholder="$t('__UserreplyVariableType_RegExp')"
                      />
                      <div class="ml-1 mt-2">
                        <a
                          href="javascript:void(0)"
                          class="text-danger m-1"
                          v-if="formInput.userAction.userReply.validate.inputList.length > 1"
                          @click="deleteButtonRegex(index)"
                        >
                          <i class="fas fa-minus text-danger"></i>
                        </a>
                      </div>
                      <div class="ml-1 mt-2">
                        <a
                          href="javascript:void(0)"
                          class="text-info m-1"
                          @click="addButtonRegex(index)"
                        >
                          <i class="fas fa-plus text-info"></i>
                        </a>
                      </div>
                    </div>

                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formInput.userAction.userReply.encryption"
                      />
                      <label class="form-check-label" for="ecsiteBox">
                        {{ $t('__encryption') }}
                      </label>
                    </div>
                    <label class="mt-2"> {{ $t('__systemReply') }}</label>
                    <!-- User Reply Setting / Success Scenario  -->
                    <div class="mt-2 mb-3">
                      <label>{{ $t('__VariableFormatValidateSuccess') }}</label>
                      <div class="form-row">
                        <md-radio
                          type="radio"
                          class="ml-3"
                          value="scenario"
                          v-model="userReplyValidateNextType.success"
                        />

                        <select
                          class="form-control col-4 ml-2"
                          id="userReplyValidateSuccessScenario"
                          v-model="formInput.userAction.userReply.validate.success.scenarioId"
                          @focus="onNextScenarioNodeChanged($event)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextScenariooptional') }}
                          </option>
                          <option
                            :value="scenario.scenario.scenarioId"
                            v-for="(scenario, index) in allScenario"
                            :key="100 + index"
                          >
                            {{ scenario.scenario.scenarioName }}
                          </option>
                        </select>

                        <md-radio
                          type="radio"
                          class="ml-3"
                          value="node"
                          v-model="userReplyValidateNextType.success"
                        />

                        <select
                          class="form-control col-4 ml-2"
                          id="userReplyValidateSuccessNode"
                          v-model="formInput.userAction.userReply.validate.success.nextNodeId"
                          @focus="onNextScenarioNodeChanged($event)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextNodeoptional') }}
                          </option>
                          <option
                            :value="node.nodeId"
                            v-for="(node, index) in nextAndSameNodeSelection"
                            :key="index"
                          >
                            {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- User Reply Setting / Failed Scenario  -->
                    <div class="mt-2 mb-3">
                      <label>{{ $t('__VariableFormatValidateFailed') }}</label>
                      <div class="form-row">
                        <md-radio
                          type="radio"
                          class="ml-3"
                          value="scenario"
                          v-model="userReplyValidateNextType.failed"
                        />

                        <select
                          class="form-control col-4 ml-2"
                          id="userReplyValidateFailedScenario"
                          v-model="formInput.userAction.userReply.validate.failed.scenarioId"
                          @focus="onNextScenarioNodeChanged($event)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextScenariooptional') }}
                          </option>
                          <option
                            :value="scenario.scenario.scenarioId"
                            v-for="(scenario, index) in allScenario"
                            :key="100 + index"
                          >
                            {{ scenario.scenario.scenarioName }}
                          </option>
                        </select>

                        <md-radio
                          type="radio"
                          class="ml-3"
                          value="node"
                          v-model="userReplyValidateNextType.failed"
                        />

                        <select
                          class="form-control col-4 ml-2"
                          id="userReplyValidateFailedNode"
                          v-model="formInput.userAction.userReply.validate.failed.nextNodeId"
                          @focus="onNextScenarioNodeChanged($event)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextNodeoptional') }}
                          </option>
                          <option
                            :value="node.nodeId"
                            v-for="(node, index) in nextAndSameNodeSelection"
                            :key="index"
                          >
                            {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Remove Variable  -->
                <div
                  class="tab-pane fade"
                  id="v-pills-removevariable"
                  role="tabpanel"
                  aria-labelledby="v-pills-removevariable-tab"
                >
                  <div class="pt-5">
                    <label> {{ $t('__variable') }}</label>
                    <a
                      href="javascript:void(0)"
                      class="text-info m-1"
                      @click="showExistVariable('RemoveVariable')"
                    >
                      <i class="fas fa-edit text-info"></i>
                    </a>
                    <div class="form-row">
                      <div
                        v-for="(variable, index) in formInput.userAction.emptyVariable.variables"
                        :key="index"
                      >
                        <button class="btn btn-link btn-variable">${{ variable }}</button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- External API Setting  -->
                <div
                  class="tab-pane fade"
                  id="v-pills-externalapisetting"
                  role="tabpanel"
                  aria-labelledby="v-pills-externalapisetting-tab"
                >
                  <div class="pt-5">
                    <div class="mt-2 mb-3">
                      <label>{{ $t('__ExternalapiName') }}</label>
                      <div class="form-row">
                        <select class="col-3 form-control ml-2">
                          <option value="cros" selected>CROS</option>
                        </select>
                      </div>
                    </div>
                    <div class="mt-2 mb-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="formInput.externalApi.cros.enable"
                        />
                        <label class="form-check-label" for="externalapiEnable">
                          {{ $t('__ExternalapiEnable') }}
                        </label>
                      </div>
                    </div>
                    <div class="mt-2 mb-3">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="formInput.externalApi.cros.others.orderIdReplace"
                        />
                        <label class="form-check-label">
                          {{ $t('__OrderIdReplaceEnable') }}
                        </label>
                      </div>
                    </div>
                    <!-- External API Setting / Success Scenario  -->
                    <div class="mt-2 mb-3">
                      <label>{{ $t('__ApiResponseSuccess') }}</label>
                      <div class="form-row">
                        <select
                          class="form-control col-4 ml-2"
                          v-model="formInput.externalApi.cros.responseSetting.success.scenarioId"
                          @change="onApiResSuccessScenarioChanged($event)"
                        >
                          <option value disabled selected>
                            {{ $t('__NextScenariooptional') }}
                          </option>
                          <option
                            :value="scenario.scenario.scenarioId"
                            v-for="(scenario, index) in allScenario"
                            :key="100 + index"
                          >
                            {{ scenario.scenario.scenarioName }}
                          </option>
                        </select>
                        <select
                          class="form-control col-4 ml-2"
                          v-model="formInput.externalApi.cros.responseSetting.success.nextNodeId"
                        >
                          <option value disabled selected>
                            {{ $t('__NextNodeoptional') }}
                          </option>
                          <option
                            :value="node.nodeId"
                            v-for="(node, index) in userReplyValidateNodes.success"
                            :key="index"
                          >
                            {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- External API Setting / Failed Scenario  -->
                    <div class="mt-2 mb-3">
                      <label>{{ $t('__ApiResponseFailed') }}</label>
                      <div
                        class="border p-2 mb-2"
                        v-for="(item, index) in formInput.externalApi.cros.responseSetting.failed"
                        :key="index"
                      >
                        <div class="float-right">
                          <a
                            href="javascript:void(0)"
                            class="text-danger float-right"
                            @click="deleteApiResFailedSetting(index)"
                          >
                            <i class="fas fa-trash text-danger"></i>
                          </a>
                        </div>
                        <br />
                        <div class="form-row mb-1">
                          <select class="form-control col-4 ml-2" v-model="item.error">
                            <option value disabled selected>
                              {{ $t('__SelectErrorCode') }}
                            </option>
                            <option
                              :value="err.code"
                              v-for="(err, index) in crosApiErrs"
                              :key="'cros-err-' + index"
                            >
                              {{ err.code }}
                            </option>
                          </select>
                        </div>
                        <span
                          v-if="item.error !== 'default' && item.error !== ''"
                          class="text-danger ml-1"
                          >{{ getCrosErrorMsg(item.error) }}</span
                        >
                        <div class="form-row mt-2">
                          <select
                            class="form-control col-4 ml-2"
                            v-model="item.scenarioId"
                            @change="onApiResFailedScenarioChanged(index)"
                          >
                            <option value disabled selected>
                              {{ $t('__NextScenariooptional') }}
                            </option>
                            <option
                              :value="scenario.scenario.scenarioId"
                              v-for="(scenario, index) in allScenario"
                              :key="100 + index"
                            >
                              {{ scenario.scenario.scenarioName }}
                            </option>
                          </select>
                          <select class="form-control col-4 ml-2" v-model="item.nextNodeId">
                            <option value disabled selected>
                              {{ $t('__NextNodeoptional') }}
                            </option>
                            <option
                              :value="node.nodeId"
                              v-for="(node, index) in userReplyValidateNodes.failed[index]"
                              :key="index"
                            >
                              {{ node.title.substr(0, 25) }} {{ node.subtitle }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div
                        class="row pt-4 add-btn-input cursor-pointer"
                        @click="addNewApiResFailedSetting()"
                      >
                        <a href="javascript:void(0)" class="mx-auto my-3">
                          <i class="fas fa-plus"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Text Message -->
                <div
                  class="tab-pane fade"
                  id="v-pills-textmessage"
                  role="tabpanel"
                  aria-labelledby="v-pills-textmessage-tab"
                >
                  <div class="pt-5">
                    <div>
                      <span class="text-danger">*</span>
                      {{ $t('__requiredItem') }}
                    </div>
                    <div class="px-4 mt-2 mb-3">
                      <label><span class="text-danger">*</span> {{ $t('__Textcontent') }}</label>
                      <textarea
                        class="form-control col-9"
                        rows="6"
                        id="text-input"
                        v-model="formInput.textMsg.text"
                      ></textarea>
                    </div>
                    <!-- Text Message Emojis -->
                    <div class="px-4 mt-2 mb-3">
                      <label>{{ $t('__Emoji') }}</label>
                      <div class="ml-1" v-if="formInput.textMsg.emojis.length == 0">
                        <a
                          href="javascript:void(0)"
                          class="text-info m-1"
                          @click="addTextMsgEmoji()"
                        >
                          <i class="fas fa-plus text-info"></i>
                        </a>
                      </div>
                      <div
                        v-for="(emoji, emojiIndex) in formInput.textMsg.emojis"
                        :key="emojiIndex"
                      >
                        <div class="form-row mt-2">
                          <div class="col-1 form-control ml-2">
                            <p>{{ emojiIndex + 1 }}</p>
                          </div>
                          <input
                            type="text"
                            class="col-4 form-control ml-2"
                            v-model="emoji.productId"
                            :placeholder="$t('__EmojiProdId')"
                          />
                          <input
                            type="text"
                            class="col-3 form-control ml-2"
                            v-model="emoji.emojiId"
                            :placeholder="$t('__EmojiId')"
                          />
                          <div class="ml-1">
                            <a
                              href="javascript:void(0)"
                              class="text-danger m-1"
                              @click="deleteTextMsgEmoji(emojiIndex)"
                              v-if="formInput.textMsg.emojis.length > 0"
                            >
                              <i class="fas fa-minus text-danger"></i>
                            </a>
                          </div>
                          <div class="ml-1">
                            <a
                              href="javascript:void(0)"
                              class="text-info m-1"
                              @click="addTextMsgEmoji()"
                              v-if="
                                (emojiIndex == formInput.textMsg.emojis.length - 1) &
                                (formInput.textMsg.emojis.length < 20)
                              "
                            >
                              <i class="fas fa-plus text-info"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Quick Reply -->
                <div
                  class="tab-pane fade"
                  id="v-pills-quickreply"
                  role="tabpanel"
                  aria-labelledby="v-pills-quickreply-tab"
                  v-if="
                    accountType == 'line' ||
                    accountType == 'fb' ||
                    accountType == 'ig' ||
                    accountType == 'webbot'
                  "
                >
                  <div class="pt-5">
                    <!-- Quick Reply Action -->
                    <label>{{ $t('__QuickReplyAction') }}</label>
                    <div>
                      <span class="text-danger">*</span>
                      {{ $t('__requiredItem') }}
                    </div>
                    <div
                      class="border p-2 mb-2"
                      v-for="(item, index) in formInput.quickReply.items"
                      :key="index"
                    >
                      <div class="float-right">
                        <a
                          href="javascript:void(0)"
                          class="text-danger float-right"
                          @click="deleteQuickReplyAction(index)"
                        >
                          <i class="fas fa-trash text-danger"></i>
                        </a>
                      </div>
                      <br />
                      <!-- Quick Reply Action Type -->
                      <div class="form-row px-4 pt-4">
                        <div class="form-group col-4">
                          <label
                            ><span class="text-danger">*</span>
                            {{ $t('__QuickReplyAction') }}</label
                          >
                          <select
                            class="form-control"
                            id="quickReplyType"
                            v-model="item.action.type"
                            @change="onQuickReplyActionChanged($event, index)"
                          >
                            <option value="postback">
                              {{ $t('__QuickReplyPostback') }}
                            </option>
                            <option
                              value="message"
                              v-if="accountType == 'line' || accountType == 'webbot'"
                            >
                              {{ $t('__QuickReplyMessage') }}
                            </option>
                            <option
                              value="uri"
                              v-if="accountType == 'line' || accountType == 'webbot'"
                            >
                              {{ $t('__QuickReplyUri') }}
                            </option>
                            <option value="datetimepicker" v-if="accountType == 'line'">
                              {{ $t('__QuickReplyTimepicker') }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group col-4">
                          <label><span class="text-danger">*</span> {{ $t('__label') }}</label>
                          <input type="text" class="form-control" v-model="item.action.label" />
                        </div>
                      </div>
                      <!-- Action == "postback" -->
                      <div v-if="item.action.type === 'postback'">
                        <!-- Quick Reply Postback Tag -->
                        <div class="px-4 mt-2 mb-3">
                          <label>{{ $t('__label') }}</label>
                          <div class="form-row">
                            <!-- Quick Reply Postback Tag / tag -->
                            <select
                              class="col-3 form-control ml-2"
                              id="quickReplyTag"
                              v-model="item.action.tag"
                              @focus="onQuickReplyActionChanged($event, index)"
                            >
                              <option
                                :value="{
                                  color: '',
                                  name: '',
                                }"
                                disabled
                                selected
                              >
                                {{ $t('__TagSelectOptional') }}
                              </option>
                              <option
                                v-for="(tag, index) in nodeData.tags"
                                :value="tag"
                                :key="100 + index"
                              >
                                {{ tag.name }}
                              </option>
                            </select>
                            <!-- Quick Reply Postback Tag / tag value -->
                            <select
                              class="col-3 form-control ml-2"
                              :aria-placeholder="$t('__fontcolor')"
                              v-model="item.action.tagValue"
                            >
                              <option value disabled selected>
                                {{ $t('__TagValueSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in valuesOfTag(
                                  item.action.tag.color,
                                  item.action.tag.name,
                                )"
                                :value="value"
                                :key="100 + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- Quick Reply Postback Vars -->
                        <div class="px-4 mt-2 mb-3">
                          <label>{{ $t('__variable') }}</label>
                          <div class="ml-1" v-if="item.action.userVars.length == 0">
                            <a
                              href="javascript:void(0)"
                              class="text-info m-1"
                              @click="addQuickReplyVar(index)"
                            >
                              <i class="fas fa-plus text-info"></i>
                            </a>
                          </div>
                          <div v-for="(varItem, varIndex) in item.action.userVars" :key="varIndex">
                            <div class="form-row mt-2">
                              <select class="col-3 form-control ml-2" v-model="varItem.variable">
                                <option value disabled selected>
                                  {{ $t('__UserVariableSelectOptional') }}
                                </option>
                                <option
                                  v-for="(value, index) in allVariables"
                                  :value="value"
                                  :key="100 + index"
                                >
                                  {{ value }}
                                </option>
                              </select>
                              <input
                                class="col-3 form-control ml-2"
                                type="text"
                                v-model="varItem.value"
                              />
                              <div class="ml-1">
                                <a
                                  href="javascript:void(0)"
                                  class="text-danger m-1"
                                  @click="deleteQuickReplyVar(index, varIndex)"
                                  v-if="item.action.userVars.length > 0"
                                >
                                  <i class="fas fa-minus text-danger"></i>
                                </a>
                              </div>
                              <div class="ml-1">
                                <a
                                  href="javascript:void(0)"
                                  class="text-info m-1"
                                  @click="addQuickReplyVar(index)"
                                  v-if="varIndex == item.action.userVars.length - 1"
                                >
                                  <i class="fas fa-plus text-info"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Quick Reply Postback Action -->
                        <div class="form-row px-4 mt-3">
                          <div class="form-group col-12 mb-0">
                            <label
                              ><span class="text-danger">*</span>
                              {{ $t('__buttonTappedAction') }}</label
                            >
                            <div class="form-group col-auto mb-0">
                              <div class="form-row col-auto">
                                <!-- Quick Reply Postback Action / Next Scenario -->
                                <div class="form-group col-6">
                                  <label>{{ $t('__jumpToSpecificScenario') }}</label>
                                  <div class="form-row align-items-center">
                                    <md-radio
                                      type="radio"
                                      class="ml-3"
                                      value="quickReplyNextScenario"
                                      v-model="item.action.nodeType"
                                    />
                                    <select
                                      class="form-control col-9"
                                      id="quickReplyNextScenario"
                                      v-model="item.action.nextScenario"
                                      @focus="onQuickReplyActionChanged($event, index)"
                                    >
                                      <option value disabled selected>
                                        {{ $t('__NextScenariooptional') }}
                                      </option>
                                      <option
                                        v-for="(scenario, index) in allScenario"
                                        :value="scenario.scenario.scenarioId"
                                        :key="100 + index"
                                      >
                                        {{ scenario.scenario.scenarioName }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <!-- Quick Reply Postback Action / Next Node -->
                                <div class="form-group col-6">
                                  <label>{{ $t('__jumpToSpecificNode') }}</label>
                                  <div class="form-row align-items-center">
                                    <md-radio
                                      type="radio"
                                      class="ml-3"
                                      value="quickReplyNextNode"
                                      v-model="item.action.nodeType"
                                    />

                                    <select
                                      class="form-control col-9"
                                      id="quickReplyNextNode"
                                      v-model="item.action.nextNode"
                                      @focus="onQuickReplyActionChanged($event, index)"
                                    >
                                      <option value disabled selected>
                                        {{ $t('__NextNodeoptional') }}
                                      </option>
                                      <option
                                        :value="node.nodeId"
                                        v-for="(node, index) in nextNodeSelection"
                                        :key="index"
                                      >
                                        {{ node.title.substr(0, 25) }}
                                        {{ node.subtitle }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- Action == "message" -->
                      <div v-if="item.action.type === 'message'">
                        <div class="px-4 mt-2 mb-3">
                          <label
                            ><span class="text-danger">*</span> {{ $t('__Textcontent') }}</label
                          >
                          <textarea
                            class="form-control col-9"
                            v-model="item.action.text"
                          ></textarea>
                        </div>
                      </div>
                      <!-- Action == "uri" -->
                      <div v-if="item.action.type === 'uri'">
                        <div class="px-4 mt-2 mb-3">
                          <label
                            ><span class="text-danger">*</span>
                            {{ $t('__jumpToSpecificUrl') }}</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            :placeholder="$t('__urlPlaceHolder')"
                            v-model="item.action.uri"
                          />
                        </div>
                      </div>
                      <!-- Action == "datetimepicker" -->
                      <div v-if="item.action.type === 'datetimepicker'">
                        <!-- Quick Reply Datetimepicker Mode and Single Var -->
                        <div class="form-row px-4 pt-4">
                          <div class="form-group col-4">
                            <label><span class="text-danger">*</span> MODE</label>
                            <select
                              class="form-control"
                              v-model="item.action.mode"
                              @change="onQuickReplyDtPickerTypechanged(index, true)"
                            >
                              <option value="date">Date</option>
                              <option value="time">Time</option>
                              <option value="datetime">Datetime</option>
                            </select>
                          </div>
                          <div class="form-group col-4">
                            <label>{{ $t('__timepickerVariable') }}</label>
                            <select class="form-control" v-model="item.action.varName">
                              <option value selected>
                                {{ $t('__UserVariableSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in allVariables"
                                :value="value"
                                :key="100 + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- Quick Reply Datetimepicker Tag -->
                        <div class="px-4 mt-2 mb-3">
                          <label>{{ $t('__label') }}</label>
                          <div class="form-row">
                            <!-- Quick Reply Datetimepicker Tag / tag -->
                            <select
                              class="col-3 form-control ml-2"
                              id="quickReplyTag"
                              v-model="item.action.tag"
                              @focus="onQuickReplyActionChanged($event, index)"
                            >
                              <option
                                :value="{
                                  color: '',
                                  name: '',
                                }"
                                disabled
                                selected
                              >
                                {{ $t('__TagSelectOptional') }}
                              </option>
                              <option
                                v-for="(tag, index) in nodeData.tags"
                                :value="tag"
                                :key="100 + index"
                              >
                                {{ tag.name }}
                              </option>
                            </select>
                            <!-- Quick Reply Datetimepicker Tag / tag value -->
                            <select
                              class="col-3 form-control ml-2"
                              :aria-placeholder="$t('__fontcolor')"
                              v-model="item.action.tagValue"
                            >
                              <option value disabled selected>
                                {{ $t('__TagValueSelectOptional') }}
                              </option>
                              <option
                                v-for="(value, index) in valuesOfTag(
                                  item.action.tag.color,
                                  item.action.tag.name,
                                )"
                                :value="value"
                                :key="100 + index"
                              >
                                {{ value }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- Quick Reply Datetimepicker Vars -->
                        <div class="px-4 mt-2 mb-3">
                          <label>{{ $t('__variable') }}</label>
                          <div class="ml-1" v-if="item.action.userVars.length == 0">
                            <a
                              href="javascript:void(0)"
                              class="text-info m-1"
                              @click="addQuickReplyVar(index)"
                            >
                              <i class="fas fa-plus text-info"></i>
                            </a>
                          </div>
                          <div v-for="(varItem, varIndex) in item.action.userVars" :key="varIndex">
                            <div class="form-row mt-2">
                              <select class="col-3 form-control ml-2" v-model="varItem.variable">
                                <option value disabled selected>
                                  {{ $t('__UserVariableSelectOptional') }}
                                </option>
                                <option
                                  v-for="(value, index) in allVariables"
                                  :value="value"
                                  :key="100 + index"
                                >
                                  {{ value }}
                                </option>
                              </select>
                              <input
                                class="col-3 form-control ml-2"
                                type="text"
                                v-model="varItem.value"
                              />
                              <div class="ml-1">
                                <a
                                  href="javascript:void(0)"
                                  class="text-danger m-1"
                                  @click="deleteQuickReplyVar(index, varIndex)"
                                  v-if="item.action.userVars.length > 0"
                                >
                                  <i class="fas fa-minus text-danger"></i>
                                </a>
                              </div>
                              <div class="ml-1">
                                <a
                                  href="javascript:void(0)"
                                  class="text-info m-1"
                                  @click="addQuickReplyVar(index)"
                                  v-if="varIndex == item.action.userVars.length - 1"
                                >
                                  <i class="fas fa-plus text-info"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Quick Reply Datetimepicker initial time -->
                        <div class="px-4 mt-2 mb-3">
                          <label>{{ $t('__timepickerInitialTime') }}</label>
                          <a
                            href="javascript:void(0)"
                            class="text-danger m-1"
                            @click="clearQuickReplyDtPickerDate(index, 'initial')"
                          >
                            <i class="fas fa-undo text-success"></i>
                          </a>
                          <div class="form-row">
                            <div class="form-group col-4">
                              <input
                                :type="item.action.dtPickerType"
                                class="form-control ml-2"
                                v-model="item.action.initial"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- Datetime picker initial max/min -->
                        <div class="px-4 mt-2 mb-3">
                          <div class="form-row">
                            <div class="form-group col-4">
                              <label>{{ $t('__timepickerMinTime') }}</label>
                              <a
                                href="javascript:void(0)"
                                class="text-danger m-1"
                                @click="clearQuickReplyDtPickerDate(index, 'min')"
                              >
                                <i class="fas fa-undo text-success"></i>
                              </a>
                              <input
                                :type="item.action.dtPickerType"
                                class="form-control ml-2"
                                v-model="item.action.min"
                              />
                            </div>
                            <div class="form-group col-4">
                              <label>{{ $t('__timepickerMaxTime') }}</label>
                              <a
                                href="javascript:void(0)"
                                class="text-danger m-1"
                                @click="clearQuickReplyDtPickerDate(index, 'max')"
                              >
                                <i class="fas fa-undo text-success"></i>
                              </a>
                              <input
                                :type="item.action.dtPickerType"
                                class="form-control ml-2"
                                v-model="item.action.max"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- Quick Reply Datetimepicker Action -->
                        <div class="form-row px-4 mt-3">
                          <div class="form-group col-12 mb-0">
                            <label
                              ><span class="text-danger">*</span>
                              {{ $t('__buttonTappedAction') }}</label
                            >
                            <div class="form-group col-auto mb-0">
                              <div class="form-row col-auto">
                                <!-- Quick Reply Datetimepicker Action / Next Scenario -->
                                <div class="form-group col-6">
                                  <label>{{ $t('__jumpToSpecificScenario') }}</label>
                                  <div class="form-row align-items-center">
                                    <md-radio
                                      type="radio"
                                      class="ml-3"
                                      value="quickReplyNextScenario"
                                      v-model="item.action.nodeType"
                                    />
                                    <select
                                      class="form-control col-9"
                                      id="quickReplyNextScenario"
                                      v-model="item.action.nextScenario"
                                      @focus="onQuickReplyActionChanged($event, index)"
                                    >
                                      <option value disabled selected>
                                        {{ $t('__NextScenariooptional') }}
                                      </option>
                                      <option
                                        v-for="(scenario, index) in allScenario"
                                        :value="scenario.scenario.scenarioId"
                                        :key="100 + index"
                                      >
                                        {{ scenario.scenario.scenarioName }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <!-- Quick Reply Datetimepicker Action / Next Node -->
                                <div class="form-group col-6">
                                  <label>{{ $t('__jumpToSpecificNode') }}</label>
                                  <div class="form-row align-items-center">
                                    <md-radio
                                      type="radio"
                                      class="ml-3"
                                      value="quickReplyNextNode"
                                      v-model="item.action.nodeType"
                                    />

                                    <select
                                      class="form-control col-9"
                                      id="quickReplyNextNode"
                                      v-model="item.action.nextNode"
                                      @focus="onQuickReplyActionChanged($event, index)"
                                    >
                                      <option value disabled selected>
                                        {{ $t('__NextNodeoptional') }}
                                      </option>
                                      <option
                                        :value="node.nodeId"
                                        v-for="(node, index) in nextNodeSelection"
                                        :key="index"
                                      >
                                        {{ node.title.substr(0, 25) }}
                                        {{ node.subtitle }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="row pt-4 add-btn-input cursor-pointer"
                      @click="addQuickReplyAction()"
                    >
                      <a href="javascript:void(0)" class="mx-auto my-3">
                        <i class="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- Form -->
                <div
                  class="tab-pane fade"
                  id="v-pills-form"
                  role="tabpanel"
                  aria-labelledby="v-pills-form-tab"
                >
                  <var-form-setting
                    v-bind:allVarForms="allVarForms"
                    v-bind:varForms="formInput.varForms"
                    @showCreateFormModal="showCreateFormModal"
                    @update="updateFormValue"
                    @deleteForm="deleteForm"
                  ></var-form-setting>
                </div>
                <!-- Datetime Trigger -->
                <div
                  class="tab-pane fade"
                  id="v-datetime-trigger"
                  role="tabpanel"
                  aria-labelledby="v-datetime-trigger-tab"
                  v-if="accountType == 'line' || accountType == 'fb' || accountType == 'ig'"
                >
                  <div class="pt-5">
                    <div class="form-check mt-2">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="formInput.datetimeTrigger.enable"
                      />
                      <label class="form-check-label">
                        {{ $t('__DateTimeTriggerEnable') }}
                      </label>
                    </div>
                    <div class="form-row px-4">
                      <div class="form-group col-4">
                        <label>{{ $t('__DateTimeTriggerStart') }}</label>
                        <input
                          type="datetime-local"
                          class="form-control"
                          v-model="formInput.datetimeTrigger.startTime"
                        />
                      </div>
                      <div class="form-group col-4">
                        <label>{{ $t('__DateTimeTriggerEnd') }}</label>
                        <input
                          type="datetime-local"
                          class="form-control"
                          v-model="formInput.datetimeTrigger.endTime"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- フッター -->
          <div class="modal-footer">
            <button
              id="addNode"
              type="button"
              class="btn btn-secondary"
              data-toggle="modal"
              style="width: 100%"
              data-translate="_confirm.node"
              @click="submitNode()"
            >
              {{ $t('__determine') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <create-variable-modal v-on:newVariable="newVariable"></create-variable-modal>
    <exist-variable-modal
      v-bind:allVariables="variables"
      v-on:addVariable="addVariable"
      v-on:removeVariable="removeVariable"
    >
    </exist-variable-modal>
    <var-form-modal
      v-bind:allVariables="variables"
      v-bind:formObject="formObjectModel"
      v-on:saveForm="saveForm"
    >
    </var-form-modal>
  </div>
</template>

<script>
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import CreateVariableModal from './CreateVariable.vue'
import ExistVariableModal from './ExistVariable'
import VarFormModal from './VarFormModal'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { apiFunction } from '../Axios/functions'
import { Utility } from '../../../utility'
import { ImageMapActions } from '../Models/ImageMap'
import { FbGenericTemplateSetting, VarFormSetting } from '../SettingPages/index'
import BubbleEditor from '../Components/BubbleEditor.vue'
import ImageBubbleEditor from '../Components/ImageBubbleEditor.vue'

export default {
  components: {
    Datetime,
    ExistVariableModal,
    CreateVariableModal,
    VarFormModal,
    vueDropzone: vue2Dropzone,
    FbGenericTemplateSetting,
    VarFormSetting,
    BubbleEditor,
    ImageBubbleEditor,
  },
  props: {
    adminId: String,
    jwtToken: String,
    accountId: String,
    scenarioId: String,
    nodeData: Object,
    allNodes: Array,
    allTags: Array,
    allVariables: Array,
    allScenario: Array,
    allSegments: Array,
    allVarForms: Array,
    crosApiErrs: Array,
  },
  name: 'create-edit-node-modal',

  data() {
    const uploadText =
      "<div><i class='fa fa-cloud-upload-alt pr-2' style='font-size: 3.5rem;'></i><h4>" +
      this.$t('__Clickordragtoupload') +
      '</h4></div>'

    return {
      accountType: '',
      token: null,
      isShow: false,
      flexMsgInvalid: null,
      variablesSelectMode: '',
      tagList: [],
      dtPickerType: 'datetime-local',
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 300,
        maxFilesize: 500,
        addRemoveLinks: true,
        uploadMultiple: true,
        maxFiles: 1,
        dictDefaultMessage: uploadText,
      },
      buttonStyleSelect: {
        text: '',
        align: 'horizontal',
      },
      condInput: {
        config_url: null,
        options: [],
      },
      segmentInput: {
        options: [],
      },
      // find-forminput
      formInput: {
        bubbleSize: ['giga', 'mega', 'kilo', 'micro', 'nano'],
        flexSize: '',
        nodeType: 'Message',
        nextnodeType: 'nnode',
        nodeName: '',
        subtitle: '',
        boldText1: '',
        boldText2: '',
        urlTitle: '',
        urlPic: '',
        urlPicTransition: '',
        picRatioX: '',
        picRatioY: '',
        text: '',
        textMsg: {
          text: '',
          emojis: [],
        },
        quickReply: {
          items: [],
        },
        button: [],
        datetimepicker: {
          color: '#00b900',
          style: 'primary',
          type: 'button',
          nodetype: '',
          action: {
            type: 'datetimepicker',
            label: '',
            data: '',
            mode: 'datetime',
            initial: '',
            max: '',
            min: '',
          },
          tag: {
            color: '',
            name: '',
          },
          tagValue: '',
          userVars: [],
          varName: '',
          nextNode: '',
          nextScenario: '',
        },
        conditionConfig: {
          options: [],
          defaultNextNode: { nextnodeType: 'snode', nextNodeId: '', scenario_id: '' },
        },
        tags: [],
        packageId: null,
        stickerId: null,
        flexMsgTitle: '',
        flexMessage: null,
        flexMsgConfigs: [],
        carouselMsg: [
          {
            image: { url: '', aspectRatio: '16:9' },
            title: {
              text: '',
              color: '#000000',
            },
            subtitle: {
              text: '',
              color: '#000000',
            },
            buttons: [
              {
                actionType: 'uri',
                value: '',
                fontColor: '#FFFFFF',
                color: '#000000',
                label: '',
              },
            ],
          },
        ],
        carouselImageMsg: [
          {
            image: { url: '', aspectRatio: '1:1', linkUri: '' },
          },
        ],

        richMsg: {
          title: '',
          layout: 'A',
          baseUrl: '',
          areaOptions: [],
        },
        reservations: [],
        originalImageUrl: '',
        originalVideoUrl: '',
        replyMsg: {},
        userAction: {
          userReply: {
            variables: [],
            selectCompare: '',
            lensCompare: [
              {
                value: '<',
                label: 'lessThan',
              },
              {
                value: '>',
                label: 'moreThan',
              },
              {
                value: '=',
                label: 'equal',
              },
            ],
            validate: {
              inputList: [
                {
                  type: null,
                  value: '',
                },
              ],
              success: {
                nextNodeId: '',
                scenarioId: '',
              },
              failed: {
                nextNodeId: '',
                scenarioId: '',
              },
            },
            encryption: false,
          },

          emptyVariable: {
            variables: [],
          },
        },
        constant: {
          ecsite: false,
          chichatcatBox: false,
        },
        externalApi: {
          cros: {
            enable: false,
            responseSetting: {
              success: {
                nextNodeId: '',
                scenarioId: '',
              },
              failed: [],
            },
            others: {
              orderIdReplace: false,
            },
          },
        },
        fbTag: 'NORMAL',
        fbGenericElements: [],
        varForms: [],
        datetimeTrigger: {
          enable: false,
          startTime: '',
          endTime: '',
        },
      },
      urldata: null,
      nextNodeSelection: [],
      nextAndSameNodeSelection: [],
      jumpType: '',
      nextNode: '',
      nextScenario: '',
      isInvalidScheduleTime: false,
      variables: [],
      isInvalidScheduleTime: false,
      userReplyValidateNextType: {
        success: '',
        failed: '',
      },
      userReplyValidateNodes: {
        success: [],
        failed: [],
      },
      formObjectModel: null,
    }
  },
  watch: {
    formInput: {
      handler: function (newVal) {
        if (newVal.urlPic != '') {
          var img = new Image()
          img.onload = () => {
            this.formInput.picRatioX = img.width
            this.formInput.picRatioY = img.height
          }
          img.src = newVal.urlPic
        }
        this.isInvalidScheduleTime = newVal.scheduletime != null && newVal.scheduletime < 0
        // Check Flex message format
        if (newVal.flexMessage) {
          try {
            var json = JSON.parse(newVal.flexMessage)
            var err = Utility.checkFlexMsgJson(json)
            if (err) {
              this.flexMsgInvalid = err
            } else {
              this.flexMsgInvalid = null
            }
          } catch (e) {
            this.flexMsgInvalid = 'invalid json'
          }
        }
        this.isInvalidScheduleTime = newVal.scheduletime != null && newVal.scheduletime < 0
      },
      deep: true,
    },
  },
  mounted() {
    this.$watch(
      'modalData',
      (modalData) => {
        this.loadData()
      },
      { immediate: true },
    )
    this.$nextTick(() => {
      var fileUrl = ''
      var dropzone = null
      if (this.formInput.urlPic != '') {
        fileUrl = this.formInput.urlPic
        dropzone = this.$refs.myVueDropzone
      } else if (this.formInput.richMsg.baseUrl != '') {
        // fileUrl = this.formInput.richMsg.baseUrl + "/1040.jpg";
        // dropzone = this.$refs.myVueDropzone;
      } else if (this.formInput.originalImageUrl && this.accountType === 'line') {
        fileUrl = this.formInput.originalImageUrl
        dropzone = this.$refs.OriginalVueDropzone
      }

      if (fileUrl != '') {
        var xhr = new XMLHttpRequest()
        xhr.open('HEAD', fileUrl, true)
        xhr.onreadystatechange = function () {
          if (xhr.readyState == 4) {
            if (xhr.status == 200) {
              var fileSize = xhr.getResponseHeader('Content-Length')
              var fileType = xhr.getResponseHeader('Content-Type')
              var tmpArr = fileUrl.split('/')
              var fileName = tmpArr[tmpArr.length - 1]
              var imgFile = { size: fileSize, name: fileName, type: fileType }
              dropzone.manuallyAddFile(imgFile, fileUrl)
            }
          }
        }
        xhr.send()
      }
    })
  },
  methods: {
    setNextNode(node) {
      this.formInput.conditionConfig.defaultNextNode.nextnodeType = node
      this.$forceUpdate()
    },
    addBubble() {
      this.formInput.carouselMsg.push({
        image: { url: '', aspectRatio: '16:9' },
        title: {
          text: '',
          color: '#000000',
        },
        subtitle: {
          text: '',
          color: '#000000',
        },
        buttons: [
          {
            label: '',
            color: '#000000',
            fontColor: '#FFFFFF',
            actionType: 'uri',
            value: '',
          },
        ],
      })
    },
    addImageBubble() {
      this.formInput.carouselImageMsg.push({
        image: { url: '', aspectRatio: '1:1', linkUri: '' },
      })
    },

    updateBubble(index, updatedBubble) {
      this.formInput.carouselMsg[index] = updatedBubble
    },
    updateImageBubble(index, updatedImageBubble) {
      this.formInput.carouselImageMsg[index] = updatedImageBubble
    },
    deleteBubble(index) {
      this.formInput.carouselMsg.splice(index, 1)
    },
    deleteImageBubble(index) {
      this.formInput.carouselImageMsg.splice(index, 1)
    },
    // Prepare page content
    loadData() {
      this.accountType = this.getAccountType()
      // Count current node and get Next level
      this.getNextLevelNodes(this.nodeData.totalNode)
      this.getNextAndSameLevelNodes(this.nodeData.totalNode)
      if (!localStorage.imgur) {
        // Get new imgur token
        this.getImgurAccessToken()
      }
      if (this.nodeData.type == 'condition') {
        this.formInput.nodeType = 'Condition'
      } else {
        this.formInput.nodeType = 'Message'
      }
      let nodeData = Utility.deepCopy(this.nodeData)

      this.formInput.nodeName = this.nodeData.nodeName
      this.formInput.subtitle = this.nodeData.subtitle
      this.formInput.boldText1 = this.nodeData.boldText1
      this.formInput.boldText2 = this.nodeData.boldText2
      this.formInput.urlTitle = this.nodeData.urlTitle
      this.formInput.urlPic = this.nodeData.urlPic
      this.formInput.urlPicTransition = this.nodeData.urlPicTransition
      this.formInput.picRatioX = this.nodeData.picRatioX
      this.formInput.picRatioY = this.nodeData.picRatioY
      this.formInput.text = this.nodeData.text
      this.formInput.textMsg = Utility.deepCopy(this.nodeData.textMsg)
      this.formInput.quickReply = Utility.deepCopy(this.nodeData.quickReply)
      this.formInput.flexMsgTitle = this.nodeData.flexMsgTitle
      this.formInput.originalImageUrl = this.nodeData.originalImageUrl
      this.formInput.originalVideoUrl = this.nodeData.originalVideoUrl
      this.formInput.replyMsg = this.nodeData.replyMsg
      this.formInput.flexSize = this.nodeData.flexSize
      this.formInput.bubbleSize = this.nodeData.bubbleSize
      this.formInput.datetimeTrigger = this.nodeData.datetimeTrigger
      this.formInput.carouselMsgTitle = this.nodeData.carouselMsgTitle
      this.formInput.carouselMsg = this.nodeData.carouselMsg
      this.formInput.carouselImageMsg = this.nodeData.carouselImageMsg

      // Button
      if (this.nodeData.buttonArea.length > 0) {
        let buttonArr = Utility.deepCopy(this.nodeData.buttonArea)
        let buttonForm = {}
        $.each(buttonArr, function (key, button) {
          let type = ''
          let nextNode = ''
          let nodetype = ''

          if (button.action.type == 'uri') {
            type = button.action.uri
            nodetype = 'uri'
          } else if (button.action.type == 'postback') {
            nextNode = button.action.data.split('&')[1].substr(11)
            nodetype = 'nnode'
            if (nextNode == "''" || nextNode == '') {
              nextNode = button.action.data.split('&')[0].substr(11)
              nodetype = 'snode'
            }
          } else if (button.action.type == '') {
            nextNode = button.action.data
          }

          buttonForm = {
            action: {
              data: nextNode,
              label: button.action.label,
              type: type,
              nodetype: nodetype,
            },
            color: button.color,
            style: button.style,
            tag: {
              color: button.tag ? button.tag.color : '',
              name: button.tag ? button.tag.name : '',
            },
            tagValue: button.tagValue ? button.tagValue : '',
            userVars: button.userVars ? button.userVars : [],
            type: 'button',
          }
          buttonArr.splice(key, 1, buttonForm)
        })
        this.formInput.button = buttonArr
      }
      this.buttonStyleSelect.align = this.nodeData.buttonStyleSelect
      // Datetime picker
      this.formInput.datetimepicker = Utility.deepCopy(this.nodeData.datetimepicker)
      if (this.formInput.datetimepicker.nextNode !== '') {
        this.formInput.datetimepicker.nodetype = 'datennode'
      } else if (this.formInput.datetimepicker.nextScenario !== '') {
        this.formInput.datetimepicker.nodetype = 'datesnode'
      }
      this.onDtPickerTypechanged()
      this.formInput.tags = Utility.deepCopy(this.nodeData.tags)
      if (this.nodeData.sticker !== null) {
        this.formInput.packageId = this.nodeData.sticker.packageId
        this.formInput.stickerId = this.nodeData.sticker.stickerId
      } else {
        this.formInput.packageId = null
        this.formInput.stickerId = null
      }
      this.formInput.flexMessage = this.nodeData.flexMessage
      if (this.formInput.flexMessage !== null) {
        this.formInput.flexMessage = JSON.stringify(this.formInput.flexMessage)
      }
      this.formInput.flexMsgConfigs = this.nodeData.flexMsgConfigs
      this.nextScenario = this.nodeData.nextScenario
      this.nextNode = this.nodeData.nextNode
      if (this.nextScenario != '') {
        this.jumpType = 'scenario'
      } else if (this.nextNode != '') {
        this.jumpType = 'node'
      } else {
        this.jumpType = ''
      }
      // Use config_url to determine which condition type is using
      if (
        this.nodeData.condition_config != null &&
        this.nodeData.condition_config.config_url != null &&
        this.nodeData.condition_config.config_url != 'bysegment' &&
        this.nodeData.condition_config.config_url != 'bytag'
      ) {
        this.condInput = this.nodeData.condition_config
      } else if (
        this.nodeData.condition_config != null &&
        this.nodeData.condition_config.options != null &&
        this.nodeData.condition_config.config_url === 'bysegment'
      ) {
        this.segmentInput = this.nodeData.condition_config
      } else if (
        this.nodeData.condition_config != null &&
        this.nodeData.condition_config.options != null &&
        this.nodeData.condition_config.config_url === 'bytag'
      ) {
        this.formInput.conditionConfig = nodeData.condition_config
        this.formInput.conditionConfig.options.forEach((item) => {
          item.multi_condition.forEach((subItem) => {
            if (subItem.expression == 'date_range') {
              if (subItem.range.start !== '') {
                subItem.range.start = this.$dateTime.toIsoFormat(subItem.range.start + ' UTC')
              }
              if (subItem.range.end !== '') {
                subItem.range.end = this.$dateTime.toIsoFormat(subItem.range.end + ' UTC')
              }
            }
          })
        })
      }

      if (this.nodeData.condition_config.default_next_node) {
        this.formInput.conditionConfig.defaultNextNode =
          this.nodeData.condition_config.default_next_node
      } else {
        this.formInput.conditionConfig.defaultNextNode = {}
        this.formInput.conditionConfig.defaultNextNode.nextnodeType = 'nnode'
        this.formInput.conditionConfig.defaultNextNode.nextNodeId = ''
        this.formInput.conditionConfig.defaultNextNode.scenario_id = ''
      }

      // Reservations config
      this.formInput.reservations.splice(0)
      if (this.nodeData.reservations_config != null) {
        this.nodeData.reservations_config.forEach((reservation) => {
          var scheduletime = reservation.reservationtime
          var scheduleunit = 'minutes'
          if (scheduletime >= 60) {
            scheduletime = scheduletime / 60
            scheduleunit = 'hours'
            if (scheduletime >= 24) {
              scheduletime = scheduletime / 24
              scheduleunit = 'days'
            }
          }
          let windowStartTime = ''
          let windowEndTime = ''
          if (reservation.window && reservation.window.start && reservation.window.start != '') {
            windowStartTime = reservation.window.start
          }
          if (reservation.window && reservation.window.end && reservation.window.end != '') {
            windowEndTime = reservation.window.end
          }
          this.formInput.reservations.push({
            schedule: reservation.scenario_id,
            scheduletime: scheduletime,
            scheduleunit: scheduleunit,
            window: {
              start: windowStartTime,
              end: windowEndTime,
            },
          })
        })
      }
      // User Action - User Reply
      let userAction = Utility.deepCopy(this.nodeData.userAction)

      this.formInput.userAction.userReply = {
        variables: [],
        validate: {
          inputList: [
            {
              type: null,
              value: '',
            },
          ],
          success: {
            nextNodeId: '',
            scenarioId: '',
          },
          failed: {
            nextNodeId: '',
            scenarioId: '',
          },
        },
        encryption: userAction.userReply.encryption,
        selectCompare: '',
        lensCompare: [
          {
            value: '<',
            label: 'lessThan',
          },
          {
            value: '>',
            label: 'moreThan',
          },
          {
            value: '=',
            label: 'equal',
          },
        ],
      }
      if (userAction.userReply.validate) {
        this.formInput.userAction.userReply.validate = userAction.userReply.validate
      }
      if (userAction.userReply.selectCompare) {
        this.formInput.userAction.userReply.selectCompare = userAction.userReply.selectCompare
      }
      // Check success scenario
      if (this.formInput.userAction.userReply.validate.success.nextNodeId !== '') {
        // nextNodeId of success scenario is exist.
        this.formInput.userAction.userReply.validate.success.scenarioId = ''
        this.userReplyValidateNextType.success = 'node'
      } else if (this.formInput.userAction.userReply.validate.success.scenarioId !== '') {
        // scenarioId of success scenario is exist.
        this.userReplyValidateNextType.success = 'scenario'
      } else {
        // nextNodeId and scenarioId both empty, reset the next type.
        this.userReplyValidateNextType.success = ''
      }
      // Check failed scenario
      if (this.formInput.userAction.userReply.validate.failed.nextNodeId !== '') {
        // nextNodeId of failed scenario is exist.
        this.formInput.userAction.userReply.validate.failed.scenarioId = ''
        this.userReplyValidateNextType.failed = 'node'
      } else if (this.formInput.userAction.userReply.validate.failed.scenarioId !== '') {
        // scenarioId of failed scenario is exist.
        this.userReplyValidateNextType.failed = 'scenario'
      } else {
        // nextNodeId and scenarioId both empty, reset the next type.
        this.userReplyValidateNextType.failed = ''
      }
      for (var variable in userAction.userReply.variable) {
        this.formInput.userAction.userReply.variables.push(variable)
      }
      // User Action - Remove Variable
      this.formInput.userAction.emptyVariable.variables.splice(0)
      for (var variable in userAction.emptyVariable.variable) {
        this.formInput.userAction.emptyVariable.variables.push(variable)
      }
      // reset tag list
      this.tagList.splice(0)
      this.allTags.forEach((tag) => {
        this.tagList.push({
          name: tag.name,
          color: tag.color,
        })
      })
      this.variables = this.allVariables
      // node constant
      this.formInput.constant = this.nodeData.constant
      // External Api Enable
      let externalApi = Utility.deepCopy(this.nodeData.externalApi)
      // since responseSetting is new feature, check if it exist.
      if (!externalApi.cros.responseSetting) {
        externalApi.cros.responseSetting = {
          success: {
            nextNodeId: '',
            scenarioId: '',
          },
          failed: {},
        }
      }
      let externalApiFailedArr = []
      for (var key in externalApi.cros.responseSetting.failed) {
        let item = externalApi.cros.responseSetting.failed[key]
        externalApiFailedArr.push({
          error: key,
          nextNodeId: item.nextNodeId,
          scenarioId: item.scenarioId,
        })
      }
      externalApi.cros.responseSetting.failed = externalApiFailedArr
      // Check if others node exist.
      if (!externalApi.cros.others) {
        externalApi.cros.others = {
          orderIdReplace: false,
        }
      }
      this.formInput.externalApi = externalApi
      // Rich Message
      this.formInput.richMsg = nodeData.richMsg
      let RichMsgAreaOptions = []
      this.formInput.richMsg.areaOptions.forEach((option) => {
        let type = 'message'
        let text = ''
        let url = ''
        if (option.url && option.url != '') {
          type = 'uri'
          url = option.url
        } else if (option.text && option.text != '') {
          type = 'message'
          text = option.text
        }
        let action = {
          type: type,
          label: option.label,
          text: text,
          url: url,
        }
        RichMsgAreaOptions.push(action)
      })
      this.formInput.richMsg.areaOptions = RichMsgAreaOptions
      // Facebook tag type
      if (nodeData.fbTag) {
        this.formInput.fbTag = nodeData.fbTag
      } else {
        // if no fbTag in nodeData, reset the value of fbTag in formInput.
        this.formInput.fbTag = 'NORMAL'
      }
      // Facebook Generic Template
      if (nodeData.fbGenericElements && nodeData.fbGenericElements.length > 0) {
        nodeData.fbGenericElements.forEach((element) => {
          // If there's no subtitle in fb generic element, create one for it.
          if (!element.subtitle) {
            element['subtitle'] = ''
          }
        })
        this.formInput.fbGenericElements = nodeData.fbGenericElements
      }
      this.refreshUserReplyValidateNodeList()
      // varForms
      this.formInput.varForms = nodeData.varForms
      $('#nodeModal').modal('show')
    },
    updateFormValue(name, val) {
      if (this.formInput[name]) {
        this.formInput[name] = val
      }
    },
    onmessage(event) {
      if (event == 'Message') {
        this.$nextTick(() => {
          if (this.accountType === 'ig' || this.accountType === 'fb') {
            $('#v-pills-image-tab').click()
            return
          }
          // 最上段の設定項目（タイトル）を選択した状態にする
          $('#v-pills-boldText1-tab').click()
        })
      } else {
        this.$nextTick(() => {
          $('#v-pills-conditions-tab').removeClass('active')
          $('#v-pills-segment-tab').removeClass('active')
          // 最上段の設定項目（URL）を選択した状態にする
          $('#v-pills-url-tab').click()
        })
      }
    },
    onchanged(event, index) {
      if (event.target.id != '') {
        if (event.target.id[0] == 'c') {
          //for condition
          if (event.target.id == 'condsnode') {
            this.condInput.options[index].nextnodeType = 'snode'
            this.condInput.options[index].nextNodeId = ''
          } else {
            this.condInput.options[index].nextnodeType = 'nnode'
            this.condInput.options[index].scenario_id = ''
          }
        } else if (event.target.id == 'segmentnnode' || event.target.id == 'segmentsnode') {
          //for segment condition
          if (event.target.id == 'segmentsnode') {
            this.segmentInput.options[index].nextnodeType = 'snode'
            this.segmentInput.options[index].nextNodeId = ''
          } else {
            this.segmentInput.options[index].nextnodeType = 'nnode'
            this.segmentInput.options[index].scenario_id = ''
          }
        } else if (event.target.id == 'defaultsnode' || event.target.id == 'defaultnnode') {
          //for default next node
          if (event.target.id == 'defaultsnode') {
            this.formInput.conditionConfig.defaultNextNode.nextnodeType = 'snode'
            this.formInput.conditionConfig.defaultNextNode.nextNodeId = ''
          } else {
            this.formInput.conditionConfig.defaultNextNode.nextnodeType = 'nnode'
            this.formInput.conditionConfig.defaultNextNode.scenario_id = ''
          }
        } else if (event.target.id == 'tagnnode' || event.target.id == 'tagsnode') {
          //for tag condition
          if (event.target.id == 'tagsnode') {
            this.formInput.conditionConfig.options[index].nextnodeType = 'snode'
            this.formInput.conditionConfig.options[index].nextNodeId = ''
          } else {
            this.formInput.conditionConfig.options[index].nextnodeType = 'nnode'
            this.formInput.conditionConfig.options[index].scenario_id = ''
          }
        } else if (event.target.id == 'nnode' || event.target.id == 'snode') {
          //for button scenarion/node selection
          this.formInput.button[index].action.nodetype = event.target.id
          this.formInput.button[index].action.type = ''
        } else if (event.target.id == 'datesnode') {
          this.formInput.datetimepicker.nodetype = event.target.id
          this.formInput.datetimepicker.nextNode = ''
        } else if (event.target.id == 'datennode') {
          this.formInput.datetimepicker.nodetype = event.target.id
          this.formInput.datetimepicker.nextScenario = ''
        } else if (event.target.id.indexOf('uri') != -1) {
          //for button url input
          this.formInput.button[index].action.nodetype = 'uri'
        } else if (event.target.id == 'btntag') {
          //for button tag selection, reset tag value
          this.formInput.button[index].tagValue = ''
        } else if (event.target.id == 'datetimepicker') {
          this.formInput.datetimepicker.tagValue = ''
        } else if (event.target.id == 'flexcfgtag') {
          //for flex config tag selection, reset tag value
          this.formInput.flexMsgConfigs[index].tagValue = ''
        } else if (event.target.id === 'jumpscenario') {
          //for jump next Scenario
          this.jumpType = 'scenario'
          this.nextNode = ''
        } else if (event.target.id === 'jumpnode') {
          //for jump next Node
          this.jumpType = 'node'
          this.nextScenario = ''
        }
      }
    },
    onQuickReplyActionChanged(event, index) {
      switch (event.target.id) {
        case 'quickReplyType':
          let type = this.formInput.quickReply.items[index].action.type
          switch (type) {
            case 'postback':
              this.formInput.quickReply.items[index].action = {
                type: 'postback',
                label: '',
                data: '',
                nodeType: '',
                nextScenario: '',
                nextNode: '',
                tag: {
                  color: '',
                  name: '',
                },
                tagValue: '',
                userVars: [],
              }
              break
            case 'message':
              this.formInput.quickReply.items[index].action = {
                type: 'message',
                label: '',
                text: '',
              }
              break
            case 'uri':
              this.formInput.quickReply.items[index].action = {
                type: 'uri',
                label: '',
                uri: '',
              }
              break
            case 'datetimepicker':
              this.formInput.quickReply.items[index].action = {
                type: 'datetimepicker',
                label: '',
                data: '',
                nodeType: '',
                nextScenario: '',
                nextNode: '',
                tag: {
                  color: '',
                  name: '',
                },
                tagValue: '',
                userVars: [],
                varName: '',
                mode: 'datetime',
                initial: '',
                max: '',
                min: '',
                dtPickerType: 'datetime-local',
              }
              break
          }
          break
        case 'quickReplyTag':
          this.formInput.quickReply.items[index].action.tagValue = ''
          break
        case 'quickReplyNextScenario':
          this.formInput.quickReply.items[index].action.nodeType = event.target.id
          this.formInput.quickReply.items[index].action.nextNode = ''
          break
        case 'quickReplyNextNode':
          this.formInput.quickReply.items[index].action.nodeType = event.target.id
          this.formInput.quickReply.items[index].action.nextScenario = ''
          break
        default:
          break
      }
    },
    onDtPickerTypechanged(clear = false) {
      let newType = ''
      switch (this.formInput.datetimepicker.action.mode) {
        case 'date':
          newType = 'date'
          break
        case 'time':
          newType = 'time'
          break
        default:
          newType = 'datetime-local'
          break
      }
      if (this.dtPickerType !== newType) {
        if (clear) {
          this.formInput.datetimepicker.action.initial = ''
          this.formInput.datetimepicker.action.min = ''
          this.formInput.datetimepicker.action.max = ''
        }
        this.dtPickerType = newType
      }
    },
    onNextScenarioNodeChanged(event) {
      if (event.target.id != '') {
        switch (event.target.id) {
          case 'userReplyValidateSuccessScenario':
            this.userReplyValidateNextType.success = 'scenario'
            this.formInput.userAction.userReply.validate.success.nextNodeId = ''
            break
          case 'userReplyValidateSuccessNode':
            this.userReplyValidateNextType.success = 'node'
            this.formInput.userAction.userReply.validate.success.scenarioId = ''
            break
          case 'userReplyValidateFailedScenario':
            this.userReplyValidateNextType.failed = 'scenario'
            this.formInput.userAction.userReply.validate.failed.nextNodeId = ''
            break
          case 'userReplyValidateFailedNode':
            this.userReplyValidateNextType.failed = 'node'
            this.formInput.userAction.userReply.validate.failed.scenarioId = ''
            break
        }
      }
    },
    onUserReplyValidateTypeChanged(event, index) {
      this.formInput.userAction.userReply.validate.inputList[index].value = ''
      this.formInput.userAction.userReply.selectCompare = ''
    },
    onApiResSuccessScenarioChanged() {
      this.formInput.externalApi.cros.responseSetting.success.nextNodeId = ''
      this.refreshUserReplyValidateNodeList()
    },
    onApiResFailedScenarioChanged(index) {
      this.formInput.externalApi.cros.responseSetting.failed[index].nextNodeId = ''
      this.refreshUserReplyValidateNodeList()
    },
    refreshUserReplyValidateNodeList() {
      // Init userReplyValidateNodes
      this.userReplyValidateNodes = {
        success: [],
        failed: [],
      }
      // Success Nodes
      let scenarioId = this.formInput.externalApi.cros.responseSetting.success.scenarioId
      if (scenarioId === this.scenarioId) {
        this.userReplyValidateNodes.success = this.nextAndSameNodeSelection
      } else {
        let targetScenario = this.allScenario.find((scenario) => scenario.id === scenarioId)
        if (targetScenario) {
          this.userReplyValidateNodes.success = targetScenario.nodes
        }
      }
      // Failed Nodes
      let tempNodes = []
      this.formInput.externalApi.cros.responseSetting.failed.forEach((setting) => {
        let scenarioId = setting.scenarioId
        if (scenarioId === this.scenarioId) {
          tempNodes.push(this.nextAndSameNodeSelection)
        } else {
          let targetScenario = this.allScenario.find((scenario) => scenario.id === scenarioId)
          if (targetScenario) {
            tempNodes.push(targetScenario.nodes)
          } else {
            tempNodes.push([])
          }
        }
      })
      this.userReplyValidateNodes.failed = tempNodes
    },
    onCondSourceChanged(event, index, subIndex) {
      let newSource = event.target.value
      this.formInput.conditionConfig.options[index].multi_condition[subIndex].value = ''
      this.formInput.conditionConfig.options[index].multi_condition[subIndex].tag = ''
      this.formInput.conditionConfig.options[index].multi_condition[subIndex].uservariable = {
        name: '',
      }
      this.formInput.conditionConfig.options[index].multi_condition[subIndex].range = {
        start: '',
        end: '',
      }
      let newExpression = ''
      if (newSource === 'followtime') {
        newExpression = 'date_range'
      } else if (newSource === 'cvkey') {
        newExpression = 'has_value'
        this.formInput.conditionConfig.options[index].multi_condition[subIndex].value = 'orderId'
      } else {
        newExpression = 'exactly_match'
      }
      this.formInput.conditionConfig.options[index].multi_condition[subIndex].expression =
        newExpression
    },
    onCondTagChanged(index, subIndex) {
      this.formInput.conditionConfig.options[index].multi_condition[subIndex].value = ''
    },
    // Get account type from _token
    getAccountType() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(',')
      let accountType = decrypt[3]
      return accountType
    },
    // Get account token from _token
    accountToken() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(',')
      let accountToken = decrypt[4]
      return accountToken
    },
    // Get imgur access token
    async getImgurAccessToken() {
      let client_id = '208d8a2829b83a4'
      let secret = '8acb5af7e2368314866e1c7300471509df22a712'

      let formData = new FormData()
      formData.append('refresh_token', 'ed342a5803077918e62e009d0964dd470fec010a') //required
      formData.append('client_id', client_id)
      formData.append('client_secret', secret)
      formData.append('grant_type', 'refresh_token')

      await this.axios({
        method: 'POST',
        url: 'https://api.imgur.com/oauth2/token',
        data: formData,
      })
        .then((res) => {
          localStorage.imgur = res.data.access_token
          // return (this.token = res.data.access_token);
        })
        .catch((e) => {
          console.log(e)
        })
    },
    flexmsgImgHandling(file) {
      if (file.manuallyAdded) {
        return
      }
      this.uploadImgFileToImgur(file)
        .then((url) => {
          this.formInput.urlPic = url
        })
        .catch((err) => {
          console.error(err)
          alert(this.$t('__Imagechangeerror'))
        })
    },
    originalImgHandling(file) {
      if (file.manuallyAdded) {
        return
      }
      if (this.accountType === 'fb') {
        // Invoke Fb API to upload file and get attachmentId
        let formData = new FormData()
        formData.append(
          'message',
          '{"attachment":{"type":"image", "payload":{"is_reusable":true}}}',
        )
        formData.append('filedata', file)
        let requestUrl = 'https://graph.facebook.com/v2.6/me/message_attachments'
        const params = new URLSearchParams()
        params.append('access_token', this.accountToken())
        this.axios({
          method: 'POST',
          url: requestUrl,
          params: params,
          data: formData,
        })
          .then((res) => {
            this.formInput.originalImageUrl = res.data.attachment_id
          })
          .catch((err) => {
            console.error(err)
            alert(this.$t('__Imagechangeerror'))
          })
      } else {
        this.uploadImgFileToImgur(file)
          .then((url) => {
            this.formInput.originalImageUrl = url
          })
          .catch((err) => {
            console.error(err)
            alert(this.$t('__Imagechangeerror'))
          })
      }
    },

    async imgHandling(file, resized = false) {
      const reader = new FileReader()
      return new Promise((resolve, reject) => {
        reader.readAsDataURL(file)
        reader.onload = (ev) => {
          let formData = {
            adminId: this.adminId,
            accountId: this.accountId,
            img: ev.target.result,
            category: 'ImageMap',
            resized: resized,
          }
          this.axios({
            method: 'POST',
            url: process.env.VUE_APP_API_ENDPOINT + 'imgMapfile',
            data: formData,
            headers: {
              Authorization: `Bearer ${this.jwtToken}`,
            },
          })
            .then((res) => {
              resolve(res.data.Result.link) // Return the uploaded image URL
            })
            .catch((err) => {
              console.error(err)
              alert(this.$t('__Imagechangeerror'))
              reject(err)
            })
        }
      })
    },

    async richMsgImgHandling(file) {
      const uploadedUrl = await this.imgHandling(file, true)
      this.formInput.richMsg.baseUrl = uploadedUrl
    },

    async uploadImgFileToImgur(file) {
      if (!localStorage.imgur) {
        await this.getImgurAccessToken()
      }
      let token = localStorage.imgur
      let formData = new FormData()
      formData.append('image', file)
      return this.axios({
        method: 'POST',
        url: 'https://imgur-apiv3.p.rapidapi.com/3/image',
        data: formData,
        headers: {
          'x-rapidapi-host': 'imgur-apiv3.p.rapidapi.com',
          'x-rapidapi-key': 'b05d4afeb3msh2e3d558f3538b7cp153622jsn0a8456bae916',
          authorization: 'Bearer ' + token,
          'content-type': 'application/x-www-form-urlencoded',
        },
        mimeType: 'multipart/form-data',
      })
        .then((res) => {
          return Promise.resolve(res.data.data.link)
        })
        .catch((err) => {
          localStorage.removeItem('imgur')
          console.error(err)
          return Promise.reject()
        })
    },
    // Display exist tag modal
    showExistTag() {
      if (this.nodeData.nodeId != '') {
        // nodeIdが空文字では無い（保存済みノード）の場合、現在のノード編集内容を一旦保存する
        this.submitNode()
      } else {
        // nodeIdが空文字（未保存ノード）の場合、一旦保存せず閉じる（ラベルの反映ができないので）
        $('#nodeModal').modal('hide')
      }
      $('#tag').modal('show')
    },
    // Display exist variable modal
    showExistVariable(mode) {
      this.variablesSelectMode = mode
      $('#variable').modal('show')
    },
    // Get next level nodes
    getNextLevelNodes(totalNode) {
      let nodes = []
      totalNode.forEach((node) => {
        if (Number(node.level) == Number(this.nodeData.level) + 1) {
          nodes.push(node)
        }
      })
      return (this.nextNodeSelection = nodes)
    },
    // Get next level nodes
    getNextAndSameLevelNodes(totalNode) {
      let nodes = []
      totalNode.forEach((node) => {
        if (
          Number(node.level) == Number(this.nodeData.level) + 1 ||
          (Number(node.level) == Number(this.nodeData.level) && node.nodeId != this.nodeData.nodeId)
        ) {
          nodes.push(node)
        }
      })
      return (this.nextAndSameNodeSelection = nodes)
    },
    // Flex message footer button
    addNewButton() {
      let buttonForm = {
        action: {
          data: '',
          label: '',
          type: '',
          nodetype: 'uri',
        },
        color: '#00b900',
        style: 'primary',
        type: 'button',
        tag: {
          color: '',
          name: '',
        },
        tagValue: '',
        userVars: [],
      }
      this.formInput.button.push(buttonForm)
    },
    // Delete target flex message footer button
    deleteButton(index) {
      this.formInput.button.splice(index, 1)
    },
    deleteButtonRegex(index) {
      this.formInput.userAction.userReply.validate.inputList.splice(index, 1)
    },
    // Flex message footer button
    addNewResponse() {
      let responseForm = {
        value: '',
        scenario_id: '',
        nextNodeId: '',
        nextnodeType: 'nnode',
      }
      this.condInput.options.push(responseForm)
    },
    addNewSegment() {
      let responseForm = {
        value: '',
        scenario_id: '',
        nextNodeId: '',
        nextnodeType: 'nnode',
      }
      this.segmentInput.options.push(responseForm)
    },
    addNewCondition() {
      let responseForm = {
        value: null,
        scenario_id: '',
        nextNodeId: '',
        nextnodeType: 'nnode',
        multi_condition: [
          {
            source: 'tag',
            tag: '',
            value: '',
            uservariable: {
              name: '',
            },
            range: {
              start: '',
              end: '',
            },
            expression: 'exactly_match',
          },
        ],
      }
      this.formInput.conditionConfig.options.push(responseForm)
    },
    deleteCondition(index) {
      this.formInput.conditionConfig.options.splice(index, 1)
    },
    addSubCondition(index) {
      this.formInput.conditionConfig.options[index].multi_condition.push({
        source: 'tag',
        tag: '',
        value: '',
        uservariable: {
          name: '',
        },
        range: {
          start: '',
          end: '',
        },
        expression: 'exactly_match',
      })
    },
    deleteSubCondition(index, subIndex) {
      this.formInput.conditionConfig.options[index].multi_condition.splice(subIndex, 1)
    },
    addNewReservation() {
      let responseForm = {
        scheduletime: 1,
        schedule: '',
        scheduleunit: 'days',
        window: {
          start: '',
          end: '',
        },
      }
      this.formInput.reservations.push(responseForm)
    },
    // Delete target flex message footer button
    deleteResponse(index) {
      this.condInput.options.splice(index, 1)
    },
    deleteSegment(index) {
      this.segmentInput.options.splice(index, 1)
    },
    deleteNewReservation(index) {
      this.formInput.reservations.splice(index, 1)
    },
    clearReservationTimeRange(index, type) {
      this.formInput.reservations[index].window[type] = ''
    },
    // Flex message configs
    addFlexMsgConfig() {
      let configForm = {
        replacement_str: '',
        scenarioId: '',
        tag: {
          color: '',
          name: '',
        },
        tagValue: '',
        userVars: [],
      }
      this.formInput.flexMsgConfigs.push(configForm)
    },
    // Delete target flex message Config
    deleteFlexMsgConfig(index) {
      this.formInput.flexMsgConfigs.splice(index, 1)
    },
    addNewApiResFailedSetting() {
      let form = {
        error: '',
        nextNodeId: '',
        scenarioId: '',
      }
      this.formInput.externalApi.cros.responseSetting.failed.push(form)
    },
    deleteApiResFailedSetting(index) {
      this.formInput.externalApi.cros.responseSetting.failed.splice(index, 1)
      this.userReplyValidateNodes.failed.splice(index, 1)
    },
    // Validate Url for node button
    validURL(str) {
      var res = str.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
      )
      return res !== null
    },
    // Sort nodes
    getNewSort(level) {
      let nodes = this.nodeData.totalNode
      let sort = 1

      nodes.forEach((node) => {
        if (node.sort <= sort) {
          sort = sort + 1
        }
      })

      return sort
    },
    // Delete tag when clicking tag on top of modal
    deleteTag(targetTag) {
      if (
        targetTag.name.length >= 3 &&
        targetTag.name.substring(0, 2) == 'Lv' &&
        targetTag.color == 'bg-danger'
      ) {
        return this.$fire({
          title: this.$t('__failedtodelete'),
          text: this.$t('__UnabletodeleteLevellabel'),
          type: 'error',
        })
      }

      this.$fire({
        title: this.$t('__Areyousureyouwanttodeletethelabel'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          let deletingIndex = -1
          this.formInput.tags.forEach((tag, index) => {
            if (tag.name == targetTag.name && tag.color == targetTag.color) {
              deletingIndex = index
            }
          })
          this.formInput.tags.splice(deletingIndex, 1)

          if (deletingIndex == -1) {
            return this.$fire({
              title: this.$t('__failedtodelete'),
              text: this.$t('__oopsSomethingwentwrong'),
              type: 'error',
            })
          }
        }
      })
    },
    // Create & edit node
    submitNode() {
      const NODE_NAME_SIZE_MAX = 60
      let self = this
      let flextemplate = {}
      let content = {}
      let nodeName = ''
      let nodeType = ''
      let error = 0
      let spaceCheckError = []
      let buttonSpaceCheck = 0

      // Default level tag
      let nodeTag = [{ color: 'bg-danger', name: 'Lv' + this.nodeData.level }]
      if (this.formInput.flexSize == '') {
        this.formInput.flexSize = 'mega'
      }
      let flexTemp = {
        type: 'bubble',
        size: this.formInput.flexSize,
      }
      // === Bold text 1(Title)
      if (this.formInput.boldText1 != '') {
        if (!this.formInput.boldText1.trim() == '') {
          content = {
            size: 'xl',
            text: this.formInput.boldText1,
            type: 'text',
            weight: 'bold',
            wrap: true,
          }
          nodeType = 'input'
          nodeName = this.formInput.boldText1.substr(0, NODE_NAME_SIZE_MAX)
          if (!('body' in flexTemp)) {
            flexTemp.body = { contents: [] }
          } else {
            if (!('contents' in flexTemp.body)) {
              flexTemp.body = { contents: [] }
            }
          }
          flexTemp.body.contents.push(content)
        } else {
          error = 1
          spaceCheckError.push(this.$t('__Titlebold'))
        }
      }
      // === Bold text 2(Sub Title)
      if (this.formInput.boldText2 != '') {
        if (!this.formInput.boldText2.trim() == '') {
          content = {
            size: 'lg',
            text: this.formInput.boldText2,
            type: 'text',
            weight: 'bold',
            wrap: true,
          }
          nodeType = 'input'
          if (!nodeName) {
            nodeName = this.formInput.boldText2.substr(0, NODE_NAME_SIZE_MAX)
          }
          if (!('body' in flexTemp)) {
            flexTemp.body = { contents: [] }
          } else {
            if (!('contents' in flexTemp.body)) {
              flexTemp.body = { contents: [] }
            }
          }
          flexTemp.body.contents.push(content)
        } else {
          error = 1
          spaceCheckError.push(this.$t('__Subtitlebold'))
        }
      }
      // === Text
      if (this.formInput.text != '') {
        if (!this.formInput.text.trim() == '') {
          content = {
            text: this.formInput.text,
            type: 'text',
            size: 'md',
            wrap: true,
          }
          nodeType = 'input'
          if (!nodeName) {
            nodeName = this.formInput.text.substr(0, NODE_NAME_SIZE_MAX)
          }
          if (!('body' in flexTemp)) {
            flexTemp.body = { contents: [] }
          } else {
            if (!('contents' in flexTemp.body)) {
              flexTemp.body = { contents: [] }
            }
          }
          flexTemp.body.contents.push(content)
        } else {
          error = 1
          spaceCheckError.push(this.$t('__Textcontent'))
        }
      }
      // === Picture upload
      if (this.formInput.urlPic != '') {
        const parseUrl = this.formInput.urlPic.split('.').pop().toLowerCase()
        const extension = parseUrl.includes('png')
        content = {
          type: 'image',
          url: this.formInput.urlPic,
          size: 'full',
          aspectRatio: this.formInput.picRatioX + ':' + this.formInput.picRatioY,
          aspectMode: 'cover',
          title: this.formInput.urlTitle,
          animated: extension,
        }
        if (this.formInput.urlPicTransition != '') {
          content.action = {
            type: 'uri',
            uri: this.formInput.urlPicTransition,
          }
        }
        flexTemp.hero = content
        nodeType = 'input'
        if (!nodeName) {
          if (this.formInput.urlTitle) {
            nodeName = `${this.formInput.urlTitle.substr(0, NODE_NAME_SIZE_MAX)}`
          } else {
            nodeName = `${this.$t('__image')} ${this.formInput.urlPic.substr(
              0,
              NODE_NAME_SIZE_MAX,
            )}}`
          }
        }
      }

      if ('body' in flexTemp) {
        if ('contents' in flexTemp.body) {
          flexTemp.body.layout = 'vertical'
          flexTemp.body.type = 'box'
        }
      }

      let nodeId = this.nodeData.nodeId
      // If new node, create id
      if (nodeId === '') {
        nodeId = Utility.generateNewId(this.$dateTime.getDateTime(), this.nodeData.totalNode.length)
      }
      // === Button
      if (this.formInput.button.length > 0) {
        // change uri form
        if (!nodeName) {
          nodeName = this.$t('__Button')
        }
        let scenarioId = this.scenarioId
        let buttonInputs = []

        $.each(this.formInput.button, function (key, button) {
          if (button.action.label == null || button.action.label == '') {
            error = 1
          } else if (button.action.label.trim() == '') {
            button.action.label = ''
            error = 1
            buttonSpaceCheck = 1
          }
          var newButton = {
            type: 'button',
            color: button.color,
            style: button.style,
            tag: button.tag,
            tagValue: button.tagValue,
            userVars: button.userVars,
            height: 'sm',
            action: {
              type: 'uri',
              label: button.action.label,
              uri: '',
            },
          }
          // 若type欄位不為空 則為url連結
          //nodetype: uri / snode /nnode
          if (button.action.nodetype == 'uri') {
            // url 驗證
            if (button.action.type.indexOf('http') === 0) {
              newButton.action.uri = button.action.type
            } else {
              error = 1
            }
          }
          // 設定post back data
          else if (button.action.nodetype == 'nnode' || button.action.nodetype == 'snode') {
            // Prepare post data
            let scenarioIdValue = ''
            let nextNodeIdValue = ''
            if (button.action.nodetype == 'nnode') {
              scenarioIdValue = scenarioId
              nextNodeIdValue = button.action.data
            } else if (button.action.nodetype == 'snode') {
              scenarioIdValue = button.action.data
              nextNodeIdValue = "''"
            }
            let postData = {
              scenarioId: scenarioIdValue,
              nextNodeId: nextNodeIdValue,
              nodeId: nodeId,
              tagColor: button.tag.color,
              tagName: button.tag.name,
              tagValue: button.tagValue,
              userVars: '',
            }
            let userVarsDict = {}
            button.userVars.forEach((varItem) => {
              userVarsDict[varItem.variable] = varItem.value
            })
            let userVarsString = JSON.stringify(userVarsDict)
            postData.userVars = userVarsString
            let postDataString = jQuery.param(postData)
            // Update action object with postdata.
            newButton.action = {
              type: 'postback',
              label: button.action.label,
              data: postDataString,
            }
          } else {
            error = 1
          }
          buttonInputs.push(newButton)
        })

        nodeType = 'input'
        // Update to button
        this.formInput.button = buttonInputs
        flexTemp.footer = {
          spacing: 'sm',
          contents: this.formInput.button,
          layout: this.buttonStyleSelect.align, //select
          type: 'box',
        }
      }

      let nextScenario = this.jumpType === 'scenario' ? this.nextScenario : ''
      let nextNode = this.jumpType === 'node' ? this.nextNode : ''

      if (buttonSpaceCheck == 1) {
        spaceCheckError.push(this.$t('__Buttontext'))
      }

      if (spaceCheckError.length > 0) {
        // スペース混入チェックエラー
        this.$alert(spaceCheckError.join(',') + this.$t('__characterinput'))
      }

      nextNode = this.nextNode
      flextemplate = flexTemp

      // === Sticker(Stamp)
      // If sticker, replace current template
      if (
        this.formInput.packageId !== null &&
        this.formInput.packageId !== '' &&
        this.formInput.stickerId !== null &&
        this.formInput.stickerId !== ''
      ) {
        nodeType = 'sticker'
        nodeName = `${this.$t('__Texture')} ${this.formInput.packageId}/${this.formInput.stickerId}`
        flextemplate = {
          type: 'sticker',
          packageId: this.formInput.packageId,
          stickerId: this.formInput.stickerId,
        }
      }
      // === Image Message
      if (this.formInput.originalImageUrl !== '') {
        if (this.accountType === 'fb') {
          if (nodeType == '') {
            nodeType = 'image'
          }
          if (nodeName == '') {
            nodeName = 'ImageMessage'
          }
          // save attachment id value into flextemplate for Fb usage.
          flextemplate['attachmentId'] = this.formInput.originalImageUrl
        } else {
          nodeType = 'image'
          nodeName = 'ImageMessage'
          this.formInput.replyMsg = {
            type: 'image',
            originalContentUrl: this.formInput.originalImageUrl,
            previewImageUrl: this.formInput.originalImageUrl,
          }
          flextemplate = this.formInput.replyMsg
        }
      }
      // === Video Message
      if (this.formInput.originalVideoUrl !== '') {
        nodeType = 'video'
        nodeName = 'VideoMessage'
        this.formInput.replyMsg = {
          type: 'video',
          originalContentUrl: this.formInput.originalVideoUrl,
          previewImageUrl: this.formInput.originalImageUrl,
        }
        flextemplate = this.formInput.replyMsg
      }
      //Datetime picker
      if (this.formInput.datetimepicker.action.label !== '') {
        if (!nodeName) {
          nodeName = this.$t('__timepickerText')
        }
        let dtPicker = this.formInput.datetimepicker
        let tempScenario = this.scenarioId
        if (dtPicker.nextScenario !== '') {
          tempScenario = dtPicker.nextScenario
        }
        let tempNextNode = "''"
        if (dtPicker.nextNode !== '') {
          tempNextNode = dtPicker.nextNode
        }
        let datetimepicker = {
          type: 'button',
          color: dtPicker.color,
          style: dtPicker.style,
          action: {
            type: 'datetimepicker',
            label: dtPicker.action.label,
            data: '',
            mode: dtPicker.action.mode,
            initial: dtPicker.action.initial,
            max: dtPicker.action.max,
            min: dtPicker.action.min,
          },
          height: 'sm',
          tag: dtPicker.tag,
          tagValue: dtPicker.tagValue,
          userVars: dtPicker.userVars,
          varName: dtPicker.varName,
          nextNode: dtPicker.nextNode,
          nextScenario: dtPicker.nextScenario,
        }
        // Prepare post data
        let postData = {
          scenarioId: tempScenario,
          nextNodeId: tempNextNode,
          nodeId: nodeId,
          tagColor: dtPicker.tag.color,
          tagName: dtPicker.tag.name,
          tagValue: dtPicker.tagValue,
          varName: dtPicker.varName,
          userVars: '',
        }
        let userVarsDict = {}
        dtPicker.userVars.forEach((varItem) => {
          userVarsDict[varItem.variable] = varItem.value
        })
        let userVarsString = JSON.stringify(userVarsDict)
        postData.userVars = userVarsString
        datetimepicker.action.data = jQuery.param(postData)
        if (flextemplate.footer && flextemplate.footer.contents) {
          flextemplate.footer.contents.push(datetimepicker)
        } else {
          flexTemp.footer = {
            spacing: 'sm',
            contents: [datetimepicker],
            layout: this.buttonStyleSelect.align,
            type: 'box',
          }
        }
        nodeType = 'input'
      }
      // === Flex Message
      // If flex message, replace current template
      if (this.formInput.flexMessage || this.formInput.flexMsgTitle) {
        nodeType = 'flex'
        if (this.formInput.flexMsgTitle) {
          nodeName = this.formInput.flexMsgTitle.substr(0, NODE_NAME_SIZE_MAX)
        } else {
          nodeName = this.$t('__flexMessage')
        }
        if (this.formInput.flexMessage) {
          flextemplate = JSON.parse(this.formInput.flexMessage)
        } else {
          flextemplate = null
        }
        if (!this.formInput.flexMessage && this.formInput.flexMsgTitle) {
          error = 1
        }
      }
      let flexConfig = this.formInput.flexMsgConfigs
      // If tag is chosen, append tag
      if (this.formInput.tags.length > 0) {
        nodeTag = []
        this.formInput.tags.forEach((tag) => {
          nodeTag.push(tag)
        })
      }

      // Carousel Message
      if (
        this.formInput.carouselMsg.length > 0 && // Check if there is at least one bubble
        this.formInput.carouselMsg.every(
          (bubble) =>
            bubble.image.url &&
            bubble.image.aspectRatio &&
            bubble.title.text &&
            bubble.title.color && // Ensure the title is not empty
            bubble.subtitle.text &&
            bubble.subtitle.color && // Ensure the subtitle is not empty
            bubble.buttons.length > 0 && // Ensure there is at least one button
            bubble.buttons.every(
              (button) => button.label && button.value && button.color && button.fontColor,
            ), // Ensure all buttons have values
        )
      ) {
        nodeType = 'carousel'
        nodeName = this.formInput.carouselMsgTitle.substr(0, NODE_NAME_SIZE_MAX)
        flextemplate = {
          type: 'carousel',
          contents: this.formInput.carouselMsg.map((bubble) => ({
            hero: {
              type: 'image',
              url: bubble.image.url,
              aspect_ratio: bubble.image.aspectRatio,
            },
            body: [
              {
                type: 'text',
                text: bubble.title.text,
                weight: 'bold',
                size: 'xl',
                align: 'center',
                color: bubble.title.color,
                wrap: true,
              },
              {
                type: 'text',
                text: bubble.subtitle.text,
                weight: 'regular',
                size: 'md',
                align: 'center',
                color: bubble.subtitle.color,
                wrap: true,
              },
            ],
            footer: bubble.buttons.map((button) => ({
              type: 'button',
              action: {
                type: button.actionType === 'uri' ? 'uri' : 'message',
                ...(button.actionType === 'uri'
                  ? { link_uri: button.value }
                  : { text: button.value }),
              },
              color: button.color,
              text: button.label,
              font_color: button.fontColor,
            })),
          })),
        }
      }

      // Carousel Image Message
      if (
        this.formInput.carouselImageMsg.length > 0 && // Check if there is at least one bubble
        this.formInput.carouselImageMsg.every(
          (bubble) => bubble.image.url && bubble.image.aspectRatio && bubble.image.linkUri,
        )
      ) {
        nodeType = 'carousel_image'
        nodeName = this.formInput.carouselMsgTitle.substr(0, NODE_NAME_SIZE_MAX)
        flextemplate = {
          type: 'carousel_image',
          contents: this.formInput.carouselImageMsg.map((bubble) => ({
            hero: {
              type: 'image',
              url: bubble.image.url,
              aspect_ratio: bubble.image.aspectRatio,
              link_uri: bubble.image.linkUri,
            },
          })),
        }
      }

      if (this.formInput.richMsg.title && this.formInput.richMsg.baseUrl) {
        // Rich Message
        // If rich message, replace current template
        nodeType = 'imagemap'
        nodeName = this.formInput.richMsg.title.substr(0, NODE_NAME_SIZE_MAX)
        let layout = this.formInput.richMsg.layout
        let areaOptions = this.formInput.richMsg.areaOptions
        flextemplate = {
          type: 'imagemap',
          layout: layout,
          baseUrl: this.formInput.richMsg.baseUrl,
          altText: this.formInput.richMsg.title,
          baseSize: {
            width: '1040',
            height: '1040',
          },
          areaOptions: areaOptions,
        }
        flextemplate['actions'] = ImageMapActions.getActions(layout, areaOptions)
        flextemplate['actions'].forEach(function (item) {
          if (item.linkUri === '') {
            error = 1
          }
        })
      }

      // === Text Message
      // If flex message, replace current template
      if (this.formInput.textMsg.text !== null && this.formInput.textMsg.text !== '') {
        let textMsgContent = this.formInput.textMsg.text
        nodeType = 'text'
        nodeName = `TEXT ${textMsgContent.substr(0, NODE_NAME_SIZE_MAX)}`
        flextemplate = {
          type: 'text',
          text: textMsgContent,
          emojis: this.formInput.textMsg.emojis,
        }
      }

      // == Facebook Generic Template
      if (this.formInput.fbGenericElements.length > 0) {
        let title = this.formInput.fbGenericElements[0].title
        nodeType = 'fb_generic'
        nodeName = `GENERIC ${title.substr(0, NODE_NAME_SIZE_MAX)}`
        this.formInput.fbGenericElements.forEach((element) => {
          if (element.buttons && element.buttons.length > 0) {
            element.buttons.forEach((button) => {
              if (button.action.nodetype == 'snode' || button.action.nodetype == 'nnode') {
                // Prepare post data
                let scenarioIdValue = ''
                let nextNodeIdValue = ''
                if (button.action.nodetype == 'nnode') {
                  scenarioIdValue = this.scenarioId
                  nextNodeIdValue = button.action.data
                } else if (button.action.nodetype == 'snode') {
                  scenarioIdValue = button.action.data
                  nextNodeIdValue = "''"
                }
                let postData = {
                  scenarioId: scenarioIdValue,
                  nextNodeId: nextNodeIdValue,
                  nodeId: nodeId,
                  tagColor: button.tag.color,
                  tagName: button.tag.name,
                  tagValue: button.tagValue,
                  userVars: '',
                }
                let userVarsDict = {}
                button.userVars.forEach((varItem) => {
                  userVarsDict[varItem.variable] = varItem.value
                })
                let userVarsString = JSON.stringify(userVarsDict)
                postData.userVars = userVarsString
                let postDataString = jQuery.param(postData)
                button.action['payload'] = postDataString
              }
            })
          }
        })
        flextemplate = {
          type: 'generic',
          elements: this.formInput.fbGenericElements,
        }
      }

      // == Quick Reply
      // Prepare post data of quick reply items
      if (this.formInput.quickReply.items.length > 0) {
        flextemplate['quickReply'] = this.formInput.quickReply
        flextemplate.quickReply.items.forEach((item, index) => {
          if (item.action.type === 'postback' || item.action.type === 'datetimepicker') {
            let tempScenario = this.scenarioId
            if (item.action.nextScenario !== '') {
              tempScenario = item.action.nextScenario
            }
            let tempNextNode = "''"
            if (item.action.nextNode !== '') {
              tempNextNode = item.action.nextNode
            }
            let postData = {
              scenarioId: tempScenario,
              nextNodeId: tempNextNode,
              nodeId: nodeId,
              tagColor: item.action.tag.color,
              tagName: item.action.tag.name,
              tagValue: item.action.tagValue,
              userVars: '',
            }
            if (item.action.type === 'datetimepicker') {
              postData['varName'] = item.action.varName
            }
            let userVarsDict = {}
            item.action.userVars.forEach((varItem) => {
              userVarsDict[varItem.variable] = varItem.value
            })
            let userVarsString = JSON.stringify(userVarsDict)
            postData.userVars = userVarsString
            flextemplate.quickReply.items[index].action.data = jQuery.param(postData)
          }
          // Quick Reply required field Validation
          if (item.action.label == '') {
            error = 1
          } else if (item.action.type === 'postback' || item.action.type === 'datetimepicker') {
            if (item.action.nextScenario == '' && item.action.nextNode == '') {
              error = 1
            }
          } else if (item.action.type === 'datetimepicker' && item.action.mode == '') {
            error = 1
          } else if (item.action.type === 'message' && item.action.text == '') {
            error = 1
          } else if (item.action.type === 'uri' && item.action.uri == '') {
            error = 1
          }
        })
      }
      // == Conditions
      let condition_config = {}
      if (
        this.condInput != null &&
        this.condInput.config_url != null &&
        this.condInput.config_url != '' &&
        this.condInput.config_url != 'bysegment' &&
        this.condInput.config_url != 'bytag'
      ) {
        condition_config = this.condInput
        if (nodeName == '') {
          nodeName = this.$t('__nodeNameConditionUrl')
        }
      } else if (
        this.segmentInput != null &&
        this.segmentInput.options != null &&
        this.segmentInput.options.length > 0
      ) {
        condition_config = this.segmentInput
        condition_config.config_url = 'bysegment'
        if (nodeName == '') {
          nodeName = this.$t('__nodeNameConditionSegment')
        }
      } else if (
        this.formInput.conditionConfig != null &&
        this.formInput.conditionConfig.options != null &&
        this.formInput.conditionConfig.options.length > 0
      ) {
        condition_config = this.formInput.conditionConfig
        condition_config.config_url = 'bytag'
        condition_config.options.forEach(function (ele) {
          ele.multi_condition.forEach((cond) => {
            // Validate date range
            if (cond.expression == 'date_range') {
              if (cond.range.start != '') {
                cond.range.start = self.$dateTime.toBackendTimeFormat(cond.range.start)
              }
              if (cond.range.end != '') {
                cond.range.end = self.$dateTime.toBackendTimeFormat(cond.range.end)
              }
            }
          })
        })
        if (nodeName == '') {
          nodeName = this.$t('__MultiCondition')
        }
      }

      const isDefaultNextNode =
        this.formInput.conditionConfig.defaultNextNode.nextNodeId ||
        this.formInput.conditionConfig.defaultNextNode.scenario_id

      if (this.formInput.conditionConfig.defaultNextNode && isDefaultNextNode) {
        condition_config.default_next_node = this.formInput.conditionConfig.defaultNextNode
      } else {
        condition_config.default_next_node = null
      }

      // Reservation config
      let reservations_config = []
      if (this.formInput.reservations.length > 0) {
        this.formInput.reservations.forEach((reservation) => {
          let rettime = 0
          if (reservation.scheduletime) {
            rettime = reservation.scheduletime
          }
          switch (reservation.scheduleunit) {
            case 'days':
              rettime = rettime * 1440
              break
            case 'hours':
              rettime = rettime * 60
              break
          }
          reservations_config.push({
            scenario_id: reservation.schedule,
            reservationtime: rettime,
            window: {
              start: reservation.window.start,
              end: reservation.window.end,
            },
          })
          if (reservation.schedule == '' || Number.isNaN(rettime)) {
            error = 1
          }
        })
      }

      // Condition content validation
      if (this.formInput.nodeType == 'Condition') {
        nodeType = 'condition'

        // If URL is not set, see it as error
        if (
          this.segmentInput == null &&
          this.formInput.conditionConfig == null &&
          this.condInput != null &&
          (this.condInput.config_url == null || this.condInput.config_url == '')
        ) {
          error = 1
        }
        // If URL condition has content, check all required field.
        if (
          this.condInput != null &&
          this.condInput.options != null &&
          this.condInput.options.length > 0
        ) {
          this.condInput.options.forEach(function (ele) {
            if (ele.value == null || ele.value == '') {
              error = 1
            }
            if (ele.nextnodeType == 'nnode' && (ele.nextNodeId == null || ele.nextNodeId == '')) {
              error = 1
            }
            if (ele.nextnodeType == 'snode' && (ele.scenario_id == null || ele.scenario_id == '')) {
              error = 1
            }
          })
        }
        // If segment condition has content, check all required field.
        if (
          this.segmentInput != null &&
          this.segmentInput.options != null &&
          this.segmentInput.options.length > 0
        ) {
          this.segmentInput.options.forEach(function (ele) {
            if (ele.value == null || ele.value == '') {
              error = 1
            }
            if (ele.nextnodeType == 'nnode' && (ele.nextNodeId == null || ele.nextNodeId == '')) {
              error = 1
            }
            if (ele.nextnodeType == 'snode' && (ele.scenario_id == null || ele.scenario_id == '')) {
              error = 1
            }
          })
        }
        // If tag condition has content, check all required field.
        if (
          this.formInput.conditionConfig != null &&
          this.formInput.conditionConfig.options != null &&
          this.formInput.conditionConfig.options.length > 0
        ) {
          this.formInput.conditionConfig.options.forEach(function (ele) {
            if (ele.multi_condition) {
              ele.multi_condition.forEach((cond) => {
                if (cond.source == 'tag') {
                  // Didn't select tag. show error.
                  if (cond.tag == '') {
                    error = 1
                  } else if (cond.tag.name == '' || cond.tag.color == '') {
                    error = 1
                  }
                } else if ((cond.source == 'uservariable') & (cond.uservariable == '')) {
                  error = 1
                } else if (
                  (cond.expression == 'exactly_match' && cond.value == '') ||
                  (cond.expression == 'fuzzy_match' && cond.value == '')
                ) {
                  error = 1
                }
                // Validate date range
                if (
                  (cond.expression == 'date_range') &
                  (cond.range.start == '' || cond.range.end == '')
                ) {
                  error = 1
                }
              })
            }
            if (ele.nextnodeType == 'nnode' && (ele.nextNodeId == null || ele.nextNodeId == '')) {
              error = 1
            }
            if (ele.nextnodeType == 'snode' && (ele.scenario_id == null || ele.scenario_id == '')) {
              error = 1
            }
          })
        }
      }
      // User Reply
      let userReply = {
        variable: {},
        validate: this.formInput.userAction.userReply.validate,
        encryption: this.formInput.userAction.userReply.encryption,
        selectCompare: this.formInput.userAction.userReply.selectCompare,
        lensCompare: this.formInput.userAction.userReply.lensCompare,
      }
      if (userReply.validate.success.nextNodeId !== '') {
        userReply.validate.success.scenarioId = this.scenarioId
      }
      if (userReply.validate.failed.nextNodeId !== '') {
        userReply.validate.failed.scenarioId = this.scenarioId
      }
      this.formInput.userAction.userReply.variables.forEach((variable) => {
        userReply.variable[variable] = {
          name: variable,
        }
      })
      // Remove Variable
      let emptyVariable = {
        variable: {},
      }
      this.formInput.userAction.emptyVariable.variables.forEach((variable) => {
        emptyVariable.variable[variable] = {
          name: variable,
        }
      })
      // External Api
      let externalApiResFailed = {}
      this.formInput.externalApi.cros.responseSetting.failed.forEach((item) => {
        if (item.error !== '') {
          externalApiResFailed[item.error] = {
            scenarioId: item.scenarioId,
            nextNodeId: item.nextNodeId,
          }
          if ((item.scenarioId === '') | (item.nextNodeId === '')) {
            error = 1
          }
        }
      })
      this.formInput.externalApi.cros.responseSetting.failed = externalApiResFailed

      // Check varForms
      let varForms = []
      this.formInput.varForms.forEach((varForm) => {
        let exisFormIndex = this.allVarForms.findIndex((form) => form.id === varForm.formId)
        if (exisFormIndex > -1) {
          varForms.push(varForm)
        }
      })

      let newNode = {
        type: nodeType,
        level: this.nodeData.level.toString(),
        condition_config: condition_config,
        reservations_config: reservations_config,
        nextScenario: nextScenario,
        nextNode: nextNode,
        nodeId: nodeId,
        nodeTag: nodeTag,
        sort: this.getNewSort(this.nodeData.level),
        template: flextemplate,
        flex_config: flexConfig,
        title: nodeName,
        subtitle: this.formInput.subtitle,
        error: error,
        userAction: {
          userReply: userReply,
          emptyVariable: emptyVariable,
        },
        nodeConst: this.formInput.constant,
        externalApi: this.formInput.externalApi,
        varForms: varForms,
        datetimeTrigger: this.formInput.datetimeTrigger,
      }
      // For Facebook Tag type
      if (this.accountType == 'fb') {
        newNode['fb_tag'] = this.formInput.fbTag
      }
      // Pass saving date to method childToParent in story/Component/Nodes.vue
      this.$emit('childToParent', newNode)
      $('#nodeModal').modal('hide')
    },
    // Get value array from all tags
    valuesOfTag(color, name) {
      var values = []
      let tag = this.allTags.find((tag) => tag.color === color && tag.name === name)
      if (tag) {
        values = tag.values
      }
      return values
    },
    addDtPickerVar() {
      this.formInput.datetimepicker.userVars.push({
        variable: '',
        value: '',
      })
    },
    deleteDtPickerVar(index) {
      this.formInput.datetimepicker.userVars.splice(index, 1)
    },
    clearDtPickerDate(type) {
      switch (type) {
        case 'initial':
          this.formInput.datetimepicker.action.initial = ''
          break
        case 'min':
          this.formInput.datetimepicker.action.min = ''
          break
        case 'max':
          this.formInput.datetimepicker.action.max = ''
          break
        default:
          break
      }
    },
    addTextMsgEmoji() {
      this.formInput.textMsg.emojis.push({
        index: 0,
        productId: '',
        emojiId: '',
      })
    },
    deleteTextMsgEmoji(index) {
      this.formInput.textMsg.emojis.splice(index, 1)
    },
    addQuickReplyAction() {
      this.formInput.quickReply.items.push({
        type: 'action',
        action: {
          type: 'postback',
          label: '',
          data: '',
          nodeType: '',
          nextScenario: '',
          nextNode: '',
          tag: {
            color: '',
            name: '',
          },
          tagValue: '',
          userVars: [],
        },
      })
    },
    deleteQuickReplyAction(index) {
      this.formInput.quickReply.items.splice(index, 1)
    },
    addQuickReplyVar(index) {
      this.formInput.quickReply.items[index].action.userVars.push({
        variable: '',
        value: '',
      })
    },
    deleteQuickReplyVar(index, varIndex) {
      this.formInput.quickReply.items[index].action.userVars.splice(varIndex, 1)
    },
    onQuickReplyDtPickerTypechanged(index, clear = false) {
      let newType = ''
      switch (this.formInput.quickReply.items[index].action.mode) {
        case 'date':
          newType = 'date'
          break
        case 'time':
          newType = 'time'
          break
        default:
          newType = 'datetime-local'
          break
      }
      if (this.formInput.quickReply.items[index].action.dtPickerType !== newType) {
        if (clear) {
          this.formInput.quickReply.items[index].action.initial = ''
          this.formInput.quickReply.items[index].action.min = ''
          this.formInput.quickReply.items[index].action.max = ''
        }
        this.formInput.quickReply.items[index].action.dtPickerType = newType
      }
    },
    clearQuickReplyDtPickerDate(index, type) {
      switch (type) {
        case 'initial':
          this.formInput.quickReply.items[index].action.initial = ''
          break
        case 'min':
          this.formInput.quickReply.items[index].action.min = ''
          break
        case 'max':
          this.formInput.quickReply.items[index].action.max = ''
          break
      }
    },
    // Add variable to create & edit node
    addVariable(variables) {
      switch (this.variablesSelectMode) {
        case 'UserReply':
          this.formInput.userAction.userReply.variables.splice(0)
          variables.forEach((variable) => {
            this.formInput.userAction.userReply.variables.push(variable)
          })
          break
        case 'RemoveVariable':
          this.formInput.userAction.emptyVariable.variables.splice(0)
          variables.forEach((variable) => {
            this.formInput.userAction.emptyVariable.variables.push(variable)
          })
          break
        default:
          break
      }
    },
    // Display form creation modal
    showCreateFormModal(formObject) {
      this.formObjectModel = formObject
      $('#var-form-modal').modal('show')
    },
    saveForm(formId, formName, formVariables) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        name: formName,
      }
      let variableFields = []
      formVariables.forEach((variable) => {
        variableFields.push({
          var_name: variable,
        })
      })
      formData['fields'] = variableFields
      if (formId && formId != '') {
        formData['formId'] = formId
      }
      apiFunction.saveForm(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          })
          this.$router.push('/login')
        } else {
          let replyFormId = data.data.Result.data.id
          let formIndex = this.allVarForms.findIndex((form) => form.id === replyFormId)
          if (formIndex > -1) {
            this.allVarForms[formIndex] = data.data.Result.data
            console.log('this.allVarForms', this.allVarForms)
          } else {
            this.allVarForms.push(data.data.Result.data)
          }
          $('#var-form-modal').modal('hide')
        }
      })
    },
    deleteForm(formId) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        formId: formId,
      }

      this.$fire({
        title: this.$t('__Areyousureyouwanttodeleteit'),
        text: this.$t('__Thesystemwillnotbeabletoretrieveyourdata'),
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('__YesIwanttodelete'),
        cancelButtonText: this.$t('__cancel'),
      }).then((result) => {
        if (result.value) {
          apiFunction.deleteForm(formData, this.jwtToken).then((data) => {
            if (data.error) {
              this.$fire({
                title: this.$t('__failedtodelete'),
                text: this.$t('__oopsSomethingwentwrong'),
                type: 'error',
              })
            } else {
              this.$fire({
                title: this.$t('__successfullydeleted'),
                text: this.$t('__Yourdatahasbeensuccessfullydeleted'),
                type: 'success',
                timer: 1500,
              })
              // Update allVarForms.
              let exisFormIndex = this.allVarForms.findIndex((form) => form.id === formId)
              if (exisFormIndex > -1) {
                this.allVarForms.splice(exisFormIndex, 1)
              }
              // Update varForms in formInput if the formId is exist.
              let varFormIndex = this.formInput.varForms.findIndex((form) => form.formId === formId)
              if (varFormIndex > -1) {
                this.formInput.varForms.splice(varFormIndex, 1)
              }
            }
          })
        }
      })
    },
    addButtonVar(btnIndex) {
      this.formInput.button[btnIndex].userVars.push({
        variable: '',
        value: '',
      })
    },
    addButtonRegex(regexIndex) {
      this.formInput.userAction.userReply.validate.inputList.push({
        type: null,
        value: '',
      })
    },
    deleteButtonVar(btnIndex, varIndex) {
      this.formInput.button[btnIndex].userVars.splice(varIndex, 1)
    },
    addFlexMsgVar(cfgIndex) {
      this.formInput.flexMsgConfigs[cfgIndex].userVars.push({
        variable: '',
        value: '',
      })
    },
    deleteFlexMsgVar(cfgIndex, varIndex) {
      this.formInput.flexMsgConfigs[cfgIndex].userVars.splice(varIndex, 1)
    },
    // Pass new variable
    newVariable(newVariable) {
      if (this.allVariables.find((variable) => variable === newVariable)) {
        $('#variable').modal('show')
        return
      }
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountVariable: [newVariable],
      }
      apiFunction.saveVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          })
          this.$router.push('/login')
        } else {
          this.allVariables.push(newVariable)
          $('#variable').modal('show')
        }
      })
    },
    removeVariable(variable) {
      let formData = {
        adminId: this.adminId,
        accountId: this.accountId,
        accountVariable: [variable],
      }
      apiFunction.deleteVariable(formData, this.jwtToken).then((data) => {
        if (data.error) {
          this.$fire({
            title: this.$t('__authExpired'),
            text: this.$t('__pleaseLogin'),
            type: 'error',
          })
          this.$router.push('/login')
        }
      })
    },
    getCrosErrorMsg(code) {
      let msg = ''
      let error = this.crosApiErrs.find((err) => err.code === code)
      if (error) {
        msg = error.msg
      }
      return msg
    },
    editSubTitle() {
      $('#node-subTitle').hide()
      $('#node-subTitle-input').show()
      $('#node-subTitle-edit').hide()
    },
    editSubTitleDone($event) {
      if ($event.which == 13) {
        $('#node-subTitle').show()
        $('#node-subTitle-input').hide()
        $('#node-subTitle-edit').show()
        return false
      }
    },
    onRichMsgLayoutChanged(layout) {
      let oldAreaOptions = Utility.deepCopy(this.formInput.richMsg.areaOptions)
      this.formInput.richMsg.layout = layout
      let labelRef = ['A', 'B', 'C', 'D']
      let actionSizeRef = {
        A: 1,
        B: 2,
        C: 2,
        D: 3,
        E: 4,
      }
      this.formInput.richMsg.areaOptions.splice(0)
      let actionSize = actionSizeRef[layout]
      for (let i = 0; i < actionSize; i++) {
        let type = 'message'
        let url = ''
        let text = ''
        if (oldAreaOptions.length > i) {
          if (oldAreaOptions[i].url && oldAreaOptions[i].url != '') {
            url = oldAreaOptions[i].url
            type = 'uri'
          } else if (oldAreaOptions[i].text && oldAreaOptions[i].text != '') {
            text = oldAreaOptions[i].text
            type = 'message'
          }
        }
        this.formInput.richMsg.areaOptions.push({
          type: type,
          label: labelRef[i],
          url: url,
          text: text,
        })
      }
    },

    // Trigger this function once user click a specific input.
    onRichMsgActionTypeChanged(event, index) {
      if (event.target.id.indexOf('uri') != -1) {
        this.formInput.richMsg.areaOptions[index].type = 'uri'
      } else if (event.target.id.indexOf('text') != -1) {
        this.formInput.richMsg.areaOptions[index].type = 'message'
      }
    },
  },
}
</script>

<style scope>
input[type='radio'] + label {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  box-shadow: 2px 2px 0px rgba(51, 51, 51, 0.2);
  transition: transform 0.2s ease;

  margin-left: 20px;
  margin-right: 10px;
}
.divider-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
}

.divider-with-text::before,
.divider-with-text::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ccc;
  margin: 0 10px;
}

.tag {
  color: #ffffff !important;
}

.error {
  background-color: lightpink !important;
}

.bubbles-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 1rem; /* Adds space between the bubbles */
  overflow-x: auto; /* Enables horizontal scrolling if content exceeds the container */
  padding: 1rem 0;
}

.bubble-wrapper {
  flex: 0 0 auto; /* Prevents shrinking and maintains the size of each bubble */
  width: 300px; /* Adjust the width to match your bubble design */
}

.next-node-option {
  background: transparent;
  padding: 0;
  border: none;
  width: fit-content;
  height: fit-content;
  .md-radio {
    pointer-events: none;
  }
}
</style>
