export const RichMenuActions = {
  getActions: function (layout, areaOptions, containerWidth, containerHeight) {
    var actions;
    switch (layout) {
      case "A":
        actions = this.action_layoutA(
          layout,
          areaOptions,
          containerWidth,
          containerHeight,
        );
        break;
      case "B":
        actions = this.action_layoutB(
          layout,
          areaOptions,
          containerWidth,
          containerHeight,
        );
        break;
      case "C":
        actions = this.action_layoutC(
          layout,
          areaOptions,
          containerWidth,
          containerHeight,
        );
        break;
      case "D":
        actions = this.action_layoutD(
          layout,
          areaOptions,
          containerWidth,
          containerHeight,
        );
        break;
      default:
        actions = [];
    }
    for (let i = 0; i < actions.length; i++) {
      if (actions[i].type == "message") {
        actions[i]["text"] = areaOptions[i].text;
        areaOptions[i]["url"] = "";
      } else if (actions[i].type == "uri") {
        actions[i]["link_uri"] = areaOptions[i].url;
        areaOptions[i]["text"] = "";
      }
    }
    return actions;
  },

  action_layoutA: function (
    layout,
    areaOptions,
    containerWidth,
    containerHeight,
  ) {
    // Calculate cell width and height (3x2 grid)
    const cellWidth = containerWidth / 3; // 3 columns
    const cellHeight = containerHeight / 2; // 2 rows

    // Map each option to its corresponding area in the grid
    return areaOptions.map((option, index) => {
      const col = index % 3;
      const row = Math.floor(index / 3);

      return {
        layout: layout,
        type: option.type,
        x: col * cellWidth,
        y: row * cellHeight,
        width: cellWidth,
        height: cellHeight,
      };
    });
  },
  action_layoutB: function (
    layout,
    areaOptions,
    containerWidth,
    containerHeight,
  ) {
    // Calculate heights and widths
    const rowHeightA = containerHeight * 0.5; // A takes half the height
    const rowHeightBCD = containerHeight * 0.5; // B, C, D share the other half
    const colWidthBCD = containerWidth / 3; // Each of B, C, D takes one-third width

    return areaOptions.map((option, index) => {
      if (index === 0) {
        // A occupies the first row
        return {
          layout: layout,
          type: option.type,
          x: 0,
          y: 0,
          width: containerWidth,
          height: rowHeightA,
        };
      }
      if (index >= 1 && index <= 3) {
        // B, C, D occupy the second row
        const col = index - 1; // Columns for B, C, D are 0, 1, 2
        return {
          layout: layout,
          type: option.type,
          x: col * colWidthBCD,
          y: rowHeightA,
          width: colWidthBCD,
          height: rowHeightBCD,
        };
      }
    });
  },
  action_layoutC: function (
    layout,
    areaOptions,
    containerWidth,
    containerHeight,
  ) {
    // Calculate the width of each section (A, B, C)
    const sectionWidth = containerWidth / 3; // 3 equal sections
    const sectionHeight = containerHeight; // Full height for each section

    return areaOptions.map((option, index) => {
      return {
        layout: layout,
        type: option.type,
        x: index * sectionWidth,
        y: 0,
        width: sectionWidth,
        height: sectionHeight,
      };
    });
  },
  action_layoutD: function (
    layout,
    areaOptions,
    containerWidth,
    containerHeight,
  ) {
    // Calculate the width and height for each quadrant
    const quadrantWidth = containerWidth / 2; // Two columns
    const quadrantHeight = containerHeight / 2; // Two rows

    return areaOptions.map((option, index) => {
      const row = Math.floor(index / 2); // Row index (0 or 1)
      const col = index % 2; // Column index (0 or 1)

      return {
        layout: layout,
        type: option.type,
        x: col * quadrantWidth, // Horizontal position
        y: row * quadrantHeight, // Vertical position
        width: quadrantWidth,
        height: quadrantHeight,
      };
    });
  },
};
