<template>
  <div class="wrapper">
    <side-bar id="sidebar">
      <template slot="links" v-if="$route.name != 'account' && $route.name != 'login'">
        <sidebar-link to="/dashboard">
          <font-awesome-icon icon="tachometer-alt" fixed-width />
          <span data-translate="_topPage.sidebar">{{ $t('__home') }}</span>
        </sidebar-link>

        <sidebar-link to="/story">
          <font-awesome-icon icon="comment-medical" fixed-width />
          <span data-translate="_story.sidebar">{{ $t('__dialogsetup') }}</span>
        </sidebar-link>

        <sidebar-link to="/richmenu" v-if="accountType() === 'line' || accountType() === 'webbot'">
          <font-awesome-icon icon="grip-horizontal" fixed-width />
          <span data-translate="_richmenu.sidebar">{{ $t('__richMenu') }}</span>
        </sidebar-link>

        <sidebar-link to="/tagList">
          <font-awesome-icon icon="tags" fixed-width />
          <span data-translate="_tagList.sidebar">{{ $t('__tagList') }}</span>
        </sidebar-link>

        <sidebar-link to="/segment">
          <font-awesome-icon icon="user-tag" fixed-width />
          <span data-translate="_userSegment.sidebar">{{ $t('__segment') }}</span>
        </sidebar-link>

        <sidebar-link to="/pushMessage">
          <font-awesome-icon icon="paper-plane" fixed-width />
          <span data-translate="_pushMsg.sidebar">{{ $t('__pushMessage') }}</span>
        </sidebar-link>

        <!-- <sidebar-link to="/chart">
        <font-awesome-icon icon="chart-area" />
        <span data-translate="_chart.sidebar">{{$t('__statics')}}</span></sidebar-link>-->

        <sidebar-link to="/data">
          <font-awesome-icon icon="file-export" fixed-width />
          <span data-translate>{{ $t('__exportData') }}</span>
        </sidebar-link>

        <sidebar-link to="/externalApiConfig">
          <font-awesome-icon icon="external-link-square-alt" fixed-width />
          <span data-translate>{{ $t('__externalApiConfig') }}</span>
        </sidebar-link>

        <sidebar-link to="/setting">
          <font-awesome-icon icon="cogs" fixed-width />
          <span data-translate="_commonSetting.sidebar">{{ $t('__generalSetup') }}</span>
        </sidebar-link>

        <sidebar-link to="/webbotSetting" v-if="accountType() == 'webbot'">
          <font-awesome-icon icon="robot" fixed-width />
          <span data-translate="_webbotSetting.sidebar">{{ $t('__webbotSetting') }}</span>
        </sidebar-link>

        <sidebar-link to="/metaUtils" v-if="accountType() == 'ig'">
          <font-awesome-icon icon="magnet" fixed-width />
          <span data-translate>{{ $t('__metaUtils') }}</span>
        </sidebar-link>

        <sidebar-link to="/userIcon" v-if="accountType() == 'line'">
          <font-awesome-icon icon="icons" fixed-width />
          <span data-translate="_userIcon.sidebar">{{ $t('__iconSetting') }}</span>
        </sidebar-link>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>
      <dashboard-content @click.native="toggleSidebar"></dashboard-content>
      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss"></style>
<script>
import TopNavbar from './TopNavbar.vue'
import ContentFooter from './ContentFooter.vue'
import DashboardContent from './Content.vue'
import MobileMenu from './MobileMenu'
export default {
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu,
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false)
      }
    },
    // Get account type from _token
    accountType() {
      let decrypt = this.$aes.decrypt(localStorage._token).split(',')
      let accountType = decrypt[3]
      return accountType
    },
  },
}
</script>
