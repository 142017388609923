<template>
  <div class="bubble-editor">
    <h4>{{ $t('__bubble') }} {{ index + 1 }}</h4>

    <div class="form-group">
      <label>{{ $t('__image') }}</label>

      <div class="form-check">
        <vue-dropzone
          ref="bubbleImageDropzone"
          id="bubbleImageDropzone"
          :options="dropzoneOptions"
          v-on:vdropzone-file-added="handleUploadImage"
        ></vue-dropzone>
      </div>
      <div v-if="bubble.image.url" class="mt-2">
        <label>{{ $t('__imageUrl') }}</label>
        <input type="text" :value="bubble.image.url" class="form-control" readonly disabled />
        <label>Preview</label>
        <br />
        <img
          :src="bubble.image.url"
          width="50%"
          height="50%"
          label="Image Preview"
          class="img-thumbnail"
          alt="Image Preview"
        />
      </div>
      <label>{{ $t('__imageAspectRatio') }}</label>
      <select v-model="bubble.image.aspectRatio" class="form-control" @change="updateBubble">
        <option value="1:1">1:1</option>
        <option value="4:3">4:3</option>
        <option value="3:4">3:4</option>
        <option value="16:9">16:9</option>
        <option value="9:16">9:16</option>
      </select>
    </div>
    <div class="border my-3"></div>
    <!-- Title -->
    <div class="form-group">
      <label>{{ $t('__title') }}</label>
      <textarea
        v-model="bubble.title.text"
        maxlength="80"
        class="form-control"
        required
        @input="updateBubble"
      ></textarea>
      <label>{{ $t('__titleColor') }}</label>
      <input type="color" v-model="bubble.title.color" class="form-control" @input="updateBubble" />
    </div>
    <!-- Subtitle -->
    <div class="form-group">
      <label>{{ $t('__subtitle') }}</label>
      <textarea
        v-model="bubble.subtitle.text"
        maxlength="80"
        class="form-control"
        required
        @input="updateBubble"
      ></textarea>
      <label>{{ $t('__subtitleColor') }}</label>
      <input
        type="color"
        v-model="bubble.subtitle.color"
        class="form-control"
        @input="updateBubble"
      />
    </div>
    <div class="border my-3"></div>
    <!-- Buttons -->
    <div class="form-group">
      <div v-for="(button, idx) in bubble.buttons" :key="idx">
        <ButtonEditor
          :button="button"
          :accountType="accountType"
          :buttonNumber="idx + 1"
          @updateButton="updateButton(idx, $event)"
          @deleteButton="deleteButton(idx)"
        />
      </div>

      <button class="btn btn-secondary mt-2" @click="addButton">
        {{ $t('__addButton') }}
      </button>
    </div>
    <button class="btn btn-danger mt-3" @click="$emit('deleteBubble')">
      {{ $t('__deleteBubble') }}
    </button>
  </div>
</template>

<script>
import ButtonEditor from './ButtonEditor.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  props: ['bubble', 'index', 'accountType', 'uploadImage', 'dropzoneOptions'],
  components: { ButtonEditor, vueDropzone: vue2Dropzone },

  methods: {
    async handleUploadImage(file) {
      if (file)
        try {
          const imgUrl = await this.uploadImage(file)
          this.bubble.image.url = imgUrl
          this.updateBubble()
        } catch (error) {
          console.error('Image upload failed: ', error)
        }
    },
    addButton() {
      this.bubble.buttons.push({
        label: '',
        actionType: 'uri',
        value: '',
        color: '#000000',
        fontColor: '#FFFFFF',
      })
    },
    updateButton(index, updatedButton) {
      this.$set(this.bubble.buttons, index, updatedButton)
    },
    deleteButton(index) {
      this.bubble.buttons.splice(index, 1)
    },
    updateBubble() {
      this.$emit('updateBubble', this.index, this.bubble)
    },
  },
}
</script>
