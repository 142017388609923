var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading ? _c("div", [_vm._v("Loading...")]) : _vm._e(),
    _vm.error
      ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
      : _vm._e(),
    !_vm.loading && !_vm.error
      ? _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.updateAccount($event)
              }
            }
          },
          [
            _c("h4", [_vm._v("Icon")]),
            _c("hr"),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 col-form-label",
                  attrs: { for: "icon_url" }
                },
                [_vm._v(_vm._s(this.$t("__iconUrl")))]
              ),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.icon_url,
                      expression: "form.icon_url"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "icon_url" },
                  domProps: { value: _vm.form.icon_url },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "icon_url", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _vm.form.icon_url
              ? _c("div", { staticClass: "form-group row" }, [
                  _c("div", { staticClass: "col-sm-2 col-form-label" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("__iconPreview")) +
                        "\n      "
                    )
                  ]),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("img", {
                      staticStyle: {
                        "max-width": "100px",
                        border: "1px solid #ccc"
                      },
                      attrs: { src: _vm.form.icon_url, alt: "icon preview" }
                    })
                  ])
                ])
              : _vm._e(),
            _c("h4", [_vm._v("Header")]),
            _c("hr"),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 col-form-label",
                  attrs: { for: "header_background_color" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$t("__headerBackgroundColor")) +
                      "\n      "
                  )
                ]
              ),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.header_background_color,
                      expression: "form.header_background_color"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "color", id: "header_background_color" },
                  domProps: { value: _vm.form.header_background_color },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "header_background_color",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 col-form-label",
                  attrs: { for: "header_title" }
                },
                [_vm._v(_vm._s(this.$t("__headerTitle")))]
              ),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.header_title,
                      expression: "form.header_title"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "header_title" },
                  domProps: { value: _vm.form.header_title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "header_title", $event.target.value)
                    }
                  }
                })
              ])
            ]),
            _c("h4", [_vm._v("Textarea")]),
            _c("hr"),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 col-form-label",
                  attrs: { for: "textarea_placeholder_text" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$t("__textareaPlaceholder")) +
                      "\n      "
                  )
                ]
              ),
              _c("div", { staticClass: "col-sm-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.textarea_placeholder_text,
                      expression: "form.textarea_placeholder_text"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "textarea_placeholder_text" },
                  domProps: { value: _vm.form.textarea_placeholder_text },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "textarea_placeholder_text",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            ]),
            _c("h4", [_vm._v("Chat Area")]),
            _c("hr"),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 col-form-label",
                  attrs: { for: "background_type" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$t("__backgroundType")) +
                      "\n      "
                  )
                ]
              ),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.backgroundType,
                        expression: "backgroundType"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "background_type" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.backgroundType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "color" } }, [
                      _vm._v(_vm._s(this.$t("__useBackgroundColor")))
                    ]),
                    _c("option", { attrs: { value: "url" } }, [
                      _vm._v(_vm._s(this.$t("__useBackgroundImage")))
                    ])
                  ]
                )
              ])
            ]),
            _vm.backgroundType === "color"
              ? _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "chat_area_background_color" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(this.$t("__chatAreaBackgroundColor")) +
                          "\n      "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.chat_area_background_color,
                          expression: "form.chat_area_background_color"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "color",
                        id: "chat_area_background_color"
                      },
                      domProps: { value: _vm.form.chat_area_background_color },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "chat_area_background_color",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _vm.backgroundType === "url"
              ? _c("div", { staticClass: "form-group row" }, [
                  _c(
                    "label",
                    {
                      staticClass: "col-sm-2 col-form-label",
                      attrs: { for: "chat_area_background_image_url" }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(this.$t("__chatAreaBackgroundImageUrl")) +
                          "\n      "
                      )
                    ]
                  ),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.chat_area_background_image_url,
                          expression: "form.chat_area_background_image_url"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "chat_area_background_image_url"
                      },
                      domProps: {
                        value: _vm.form.chat_area_background_image_url
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "chat_area_background_image_url",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.form.chat_area_background_image_url &&
                      _vm.backgroundType === "url",
                    expression:
                      "form.chat_area_background_image_url && backgroundType === 'url'"
                  }
                ],
                staticClass: "form-group row"
              },
              [
                _c("div", { staticClass: "col-sm-2 col-form-label" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("__backgroundImagePreview")) +
                      "\n      "
                  )
                ]),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("img", {
                    staticStyle: {
                      "max-width": "100px",
                      border: "1px solid #ccc"
                    },
                    attrs: {
                      src: _vm.form.chat_area_background_image_url,
                      alt: "icon preview"
                    }
                  })
                ])
              ]
            ),
            _c("h4", [_vm._v("Font Size")]),
            _c("hr"),
            _c("div", { staticClass: "form-group row" }, [
              _c(
                "label",
                {
                  staticClass: "col-sm-2 col-form-label",
                  attrs: { for: "default_font_size" }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(this.$t("__defaultFontSize")) +
                      "\n      "
                  )
                ]
              ),
              _c("div", { staticClass: "col-sm-6" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.default_font_size,
                        expression: "form.default_font_size"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { id: "default_font_size" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "default_font_size",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  [
                    _c("option", { attrs: { value: "sm" } }, [_vm._v("Small")]),
                    _c("option", { attrs: { value: "md" } }, [
                      _vm._v("Medium")
                    ]),
                    _c("option", { attrs: { value: "lg" } }, [_vm._v("Large")])
                  ]
                )
              ])
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _c("div", { staticClass: "col-sm-6 offset-sm-2" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(_vm._s(this.$t("__updateSetting")))]
                )
              ])
            ])
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }