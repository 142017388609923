import { render, staticRenderFns } from "./StatsCard.vue?vue&type=template&id=63d1c903&"
import script from "./StatsCard.vue?vue&type=script&lang=js&"
export * from "./StatsCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src3137720765/src/github.com/Hitobito-Inc-Taiwan-branch/chatbot-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('63d1c903')) {
      api.createRecord('63d1c903', component.options)
    } else {
      api.reload('63d1c903', component.options)
    }
    module.hot.accept("./StatsCard.vue?vue&type=template&id=63d1c903&", function () {
      api.rerender('63d1c903', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Cards/StatsCard.vue"
export default component.exports