var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bubble-editor" }, [
    _c("h4", [
      _vm._v(_vm._s(_vm.$t("__bubble")) + " " + _vm._s(_vm.index + 1))
    ]),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__image")))]),
      _c(
        "div",
        { staticClass: "form-check" },
        [
          _c("vue-dropzone", {
            ref: "bubbleImageDropzone",
            attrs: { id: "bubbleImageDropzone", options: _vm.dropzoneOptions },
            on: { "vdropzone-file-added": _vm.handleUploadImage }
          })
        ],
        1
      ),
      _vm.bubble.image.url
        ? _c("div", { staticClass: "mt-2" }, [
            _c("label", [_vm._v(_vm._s(_vm.$t("__imageUrl")))]),
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "text", readonly: "", disabled: "" },
              domProps: { value: _vm.bubble.image.url }
            }),
            _c("label", [_vm._v("Preview")]),
            _c("br"),
            _c("img", {
              staticClass: "img-thumbnail",
              attrs: {
                src: _vm.bubble.image.url,
                width: "50%",
                height: "50%",
                label: "Image Preview",
                alt: "Image Preview"
              }
            })
          ])
        : _vm._e(),
      _c("label", [_vm._v(_vm._s(_vm.$t("__imageAspectRatio")))]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.bubble.image.aspectRatio,
              expression: "bubble.image.aspectRatio"
            }
          ],
          staticClass: "form-control",
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.bubble.image,
                  "aspectRatio",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.updateBubble
            ]
          }
        },
        [
          _c("option", { attrs: { value: "1:1" } }, [_vm._v("1:1")]),
          _c("option", { attrs: { value: "4:3" } }, [_vm._v("4:3")]),
          _c("option", { attrs: { value: "3:4" } }, [_vm._v("3:4")]),
          _c("option", { attrs: { value: "16:9" } }, [_vm._v("16:9")]),
          _c("option", { attrs: { value: "9:16" } }, [_vm._v("9:16")])
        ]
      )
    ]),
    _c("div", { staticClass: "border my-3" }),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__title")))]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.bubble.title.text,
            expression: "bubble.title.text"
          }
        ],
        staticClass: "form-control",
        attrs: { maxlength: "80", required: "" },
        domProps: { value: _vm.bubble.title.text },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.bubble.title, "text", $event.target.value)
            },
            _vm.updateBubble
          ]
        }
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("__titleColor")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.bubble.title.color,
            expression: "bubble.title.color"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.bubble.title.color },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.bubble.title, "color", $event.target.value)
            },
            _vm.updateBubble
          ]
        }
      })
    ]),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__subtitle")))]),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.bubble.subtitle.text,
            expression: "bubble.subtitle.text"
          }
        ],
        staticClass: "form-control",
        attrs: { maxlength: "80", required: "" },
        domProps: { value: _vm.bubble.subtitle.text },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.bubble.subtitle, "text", $event.target.value)
            },
            _vm.updateBubble
          ]
        }
      }),
      _c("label", [_vm._v(_vm._s(_vm.$t("__subtitleColor")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.bubble.subtitle.color,
            expression: "bubble.subtitle.color"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.bubble.subtitle.color },
        on: {
          input: [
            function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.bubble.subtitle, "color", $event.target.value)
            },
            _vm.updateBubble
          ]
        }
      })
    ]),
    _c("div", { staticClass: "border my-3" }),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _vm._l(_vm.bubble.buttons, function(button, idx) {
          return _c(
            "div",
            { key: idx },
            [
              _c("ButtonEditor", {
                attrs: {
                  button: button,
                  accountType: _vm.accountType,
                  buttonNumber: idx + 1
                },
                on: {
                  updateButton: function($event) {
                    return _vm.updateButton(idx, $event)
                  },
                  deleteButton: function($event) {
                    return _vm.deleteButton(idx)
                  }
                }
              })
            ],
            1
          )
        }),
        _c(
          "button",
          {
            staticClass: "btn btn-secondary mt-2",
            on: { click: _vm.addButton }
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("__addButton")) + "\n    ")]
        )
      ],
      2
    ),
    _c(
      "button",
      {
        staticClass: "btn btn-danger mt-3",
        on: {
          click: function($event) {
            return _vm.$emit("deleteBubble")
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("__deleteBubble")) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }