var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "modal fade bd-example-modal-lg",
          attrs: {
            id: "nodeModal",
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "exampleModalCenterTitle",
            "aria-hidden": "true"
          }
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog modal-lg",
              attrs: { role: "document" }
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-content tag-modal",
                  staticStyle: { width: "1000px" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-header",
                      staticStyle: { "align-items": "center" }
                    },
                    [
                      this.nodeData.nodeId != ""
                        ? _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("__modalTitleNodeId")) +
                                ": " +
                                _vm._s(this.nodeData.nodeId) +
                                "\n          "
                            )
                          ])
                        : _vm._e(),
                      _vm._m(0)
                    ]
                  ),
                  _c("div", { staticClass: "modal-body" }, [
                    _c(
                      "div",
                      { staticClass: "row ml-5 align-items-center" },
                      [
                        _c(
                          "md-radio",
                          {
                            attrs: {
                              type: "radio",
                              id: "one",
                              value: "Message"
                            },
                            on: { change: _vm.onmessage },
                            model: {
                              value: _vm.formInput.nodeType,
                              callback: function($$v) {
                                _vm.$set(_vm.formInput, "nodeType", $$v)
                              },
                              expression: "formInput.nodeType"
                            }
                          },
                          [
                            _c("label", { attrs: { for: "one" } }),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("__Message")))
                            ])
                          ]
                        ),
                        _c(
                          "md-radio",
                          {
                            attrs: {
                              type: "radio",
                              id: "two",
                              value: "Condition"
                            },
                            on: { change: _vm.onmessage },
                            model: {
                              value: _vm.formInput.nodeType,
                              callback: function($$v) {
                                _vm.$set(_vm.formInput, "nodeType", $$v)
                              },
                              expression: "formInput.nodeType"
                            }
                          },
                          [
                            _c("label", { attrs: { for: "two" } }),
                            _c("span", [
                              _vm._v(" " + _vm._s(_vm.$t("__Condition")))
                            ])
                          ]
                        ),
                        _vm.accountType == "line" ||
                        _vm.accountType == "fb" ||
                        _vm.accountType == "ig"
                          ? _c("div", { staticClass: "form-check" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.constant.ecsite,
                                    expression: "formInput.constant.ecsite"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: { type: "checkbox", id: "ecsiteBox" },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.formInput.constant.ecsite
                                  )
                                    ? _vm._i(
                                        _vm.formInput.constant.ecsite,
                                        null
                                      ) > -1
                                    : _vm.formInput.constant.ecsite
                                },
                                on: {
                                  change: function($event) {
                                    var $$a = _vm.formInput.constant.ecsite,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.formInput.constant,
                                            "ecsite",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.formInput.constant,
                                            "ecsite",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.formInput.constant,
                                        "ecsite",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "ecsiteBox" }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("__ecsiteBox")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm.accountType == "line" ||
                        _vm.accountType == "fb" ||
                        _vm.accountType == "ig"
                          ? _c("div", { staticClass: "form-check ml-3" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.formInput.constant.chichatcatBox,
                                    expression:
                                      "formInput.constant.chichatcatBox"
                                  }
                                ],
                                staticClass: "form-check-input",
                                attrs: {
                                  type: "checkbox",
                                  id: "chichatcatBox"
                                },
                                domProps: {
                                  checked: Array.isArray(
                                    _vm.formInput.constant.chichatcatBox
                                  )
                                    ? _vm._i(
                                        _vm.formInput.constant.chichatcatBox,
                                        null
                                      ) > -1
                                    : _vm.formInput.constant.chichatcatBox
                                },
                                on: {
                                  change: function($event) {
                                    var $$a =
                                        _vm.formInput.constant.chichatcatBox,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            _vm.formInput.constant,
                                            "chichatcatBox",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            _vm.formInput.constant,
                                            "chichatcatBox",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(
                                        _vm.formInput.constant,
                                        "chichatcatBox",
                                        $$c
                                      )
                                    }
                                  }
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass: "form-check-label",
                                  attrs: { for: "chichatcatBox" }
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("__chichatcatBox")) +
                                      "\n              "
                                  )
                                ]
                              )
                            ])
                          : _vm._e(),
                        _vm.accountType == "fb" || _vm.accountType == "ig"
                          ? _c("div", { staticClass: "ml-auto mr-3" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.formInput.fbTag,
                                      expression: "formInput.fbTag"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: { id: "fb_tag_type" },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.formInput,
                                        "fbTag",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "option",
                                    {
                                      attrs: { value: "NORMAL", selected: "" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$t("__FbtagNormal")) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "ACCOUNT_UPDATE",
                                        selected: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("__FbtagAccountUpdate")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "CONFIRMED_EVENT_UPDATE",
                                        selected: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("__FbtagConfirmEvenUpdate")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "option",
                                    {
                                      attrs: {
                                        value: "POST_PURCHASE_UPDATE",
                                        selected: ""
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(
                                            _vm.$t("__FbtagPurchaseUpdate")
                                          ) +
                                          "\n                "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row px-3 py-2" },
                      [
                        _vm._l(_vm.formInput.tags, function(tag, index) {
                          return _c(
                            "ul",
                            {
                              key: index,
                              staticClass: "tags",
                              attrs: { id: "node-tag-area" }
                            },
                            [
                              _c("li", [
                                _c(
                                  "a",
                                  {
                                    staticClass: "tag",
                                    class: tag.color,
                                    attrs: {
                                      href: "javascript:void(0)",
                                      id: "newLevelTag"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteTag(tag)
                                      }
                                    }
                                  },
                                  [_vm._v(_vm._s(tag.name))]
                                )
                              ])
                            ]
                          )
                        }),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-outline-secondary btn-sm ml-auto",
                            attrs: {
                              "data-toggle": "modal",
                              "data-target": "#tag"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showExistTag()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("__Addlabel")) +
                                "\n            "
                            )
                          ]
                        )
                      ],
                      2
                    ),
                    _c("div", { staticClass: "row px-3 mb-1" }, [
                      _vm.formInput.subTitle !== ""
                        ? _c(
                            "p",
                            {
                              staticClass: "text-bold",
                              attrs: { id: "node-subTitle" }
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.formInput.subtitle) +
                                  "\n            "
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.formInput.subtitle == ""
                        ? _c(
                            "p",
                            {
                              staticClass: "text-bold",
                              attrs: { id: "node-subTitle" }
                            },
                            [_vm._v("Subtitle")]
                          )
                        : _vm._e(),
                      _c(
                        "span",
                        {
                          attrs: { id: "node-subTitle-edit" },
                          on: {
                            click: function($event) {
                              return _vm.editSubTitle()
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-pencil-alt cursor-pointer pl-1"
                          })
                        ]
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formInput.subtitle,
                            expression: "formInput.subtitle"
                          }
                        ],
                        staticClass: "form-control element-hide",
                        attrs: { type: "text", id: "node-subTitle-input" },
                        domProps: { value: _vm.formInput.subtitle },
                        on: {
                          keypress: function($event) {
                            return _vm.editSubTitleDone($event)
                          },
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.formInput,
                              "subtitle",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ]),
                    _vm.formInput.nodeType == "Condition"
                      ? _c(
                          "div",
                          {
                            staticClass: "row border",
                            staticStyle: { height: "60vh", overflow: "auto" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "nav flex-column nav-pills col-2 border-right",
                                attrs: {
                                  id: "v-pills-cond-tab",
                                  role: "tablist",
                                  "aria-orientation": "vertical"
                                }
                              },
                              [
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link active",
                                        attrs: {
                                          id: "v-pills-url-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-url",
                                          role: "tab",
                                          "aria-controls": "v-pills-url",
                                          "aria-selected": "true"
                                        }
                                      },
                                      [_vm._v("\n                URL")]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-segment-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-segment",
                                          role: "tab",
                                          "aria-controls": "v-pills-segment",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__segment"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      id: "v-pills-conditions-tab",
                                      "data-toggle": "pill",
                                      href: "#v-pills-conditions",
                                      role: "tab",
                                      "aria-controls": "v-pills-conditions",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("__MultiCondition"))
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "divider-with-text" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("__default")))
                                    ])
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      id: "v-pills-defaultNextNode-tab",
                                      "data-toggle": "pill",
                                      href: "#v-pills-defaultNextNode",
                                      role: "tab",
                                      "aria-controls":
                                        "v-pills-defaultNextNode",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("__defaultNextNode"))
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-content col-10",
                                attrs: { id: "v-pills-cond-tabContent" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade show active",
                                    attrs: {
                                      id: "v-pills-url",
                                      role: "tabpanel",
                                      "aria-labelledby": "v-pills-url-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("div", { staticClass: "form-group" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("__conditionUrl"))
                                          )
                                        ]),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.condInput.config_url,
                                              expression: "condInput.config_url"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: {
                                            type: "text",
                                            placeholder: _vm.$t(
                                              "__urlPlaceHolder"
                                            )
                                          },
                                          domProps: {
                                            value: _vm.condInput.config_url
                                          },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.$set(
                                                _vm.condInput,
                                                "config_url",
                                                $event.target.value
                                              )
                                            }
                                          }
                                        })
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(_vm.$t("__branchSettings"))
                                            )
                                          ]),
                                          _c("div", [
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(
                                                  _vm.$t("__requiredItem")
                                                ) +
                                                "\n                    "
                                            )
                                          ]),
                                          _vm._l(
                                            _vm.condInput.options,
                                            function(item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "border p-2 mb-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "float-right"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-danger float-right",
                                                          attrs: {
                                                            href:
                                                              "javascript:void(0)"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteResponse(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-trash text-danger"
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-row align-items-center"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: item.value,
                                                              expression:
                                                                "item.value"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-8",
                                                          attrs: {
                                                            type: "text"
                                                          },
                                                          domProps: {
                                                            value: item.value
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__whenMatching"
                                                              )
                                                            )
                                                          )
                                                        ])
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-row align-items-center"
                                                      },
                                                      [
                                                        _c("md-radio", {
                                                          staticClass: "ml-3",
                                                          attrs: {
                                                            value: "nnode"
                                                          },
                                                          model: {
                                                            value:
                                                              item.nextnodeType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "nextnodeType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.nextnodeType"
                                                          }
                                                        }),
                                                        item.nextnodeType ==
                                                        "nnode"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [_vm._v("*")]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.nextNodeId,
                                                                expression:
                                                                  "item.nextNodeId"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control col-3",
                                                            attrs: {
                                                              id: "condnnode"
                                                            },
                                                            on: {
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                return _vm.onchanged(
                                                                  $event,
                                                                  index
                                                                )
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "nextNodeId",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__NextNodeoptional"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.nextNodeSelection,
                                                              function(
                                                                node,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: idx,
                                                                    domProps: {
                                                                      value:
                                                                        node.nodeId
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          node.title.substr(
                                                                            0,
                                                                            25
                                                                          )
                                                                        ) +
                                                                        "\n                              " +
                                                                        _vm._s(
                                                                          node.subtitle
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        ),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__jumpTo")
                                                            )
                                                          )
                                                        ]),
                                                        _c("md-radio", {
                                                          staticClass: "ml-5",
                                                          attrs: {
                                                            value: "snode"
                                                          },
                                                          model: {
                                                            value:
                                                              item.nextnodeType,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "nextnodeType",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.nextnodeType"
                                                          }
                                                        }),
                                                        item.nextnodeType ==
                                                        "snode"
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [_vm._v("*")]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.scenario_id,
                                                                expression:
                                                                  "item.scenario_id"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control col-3",
                                                            attrs: {
                                                              id: "condsnode"
                                                            },
                                                            on: {
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                return _vm.onchanged(
                                                                  $event,
                                                                  index
                                                                )
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "scenario_id",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                              " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__NextScenariooptional"
                                                                      )
                                                                    ) +
                                                                    "\n                            "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.allScenario,
                                                              function(
                                                                scenario,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: idx,
                                                                    domProps: {
                                                                      value:
                                                                        scenario
                                                                          .scenario
                                                                          .scenarioId
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                              " +
                                                                        _vm._s(
                                                                          scenario
                                                                            .scenario
                                                                            .scenarioName
                                                                        ) +
                                                                        "\n                            "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        ),
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t("__jumpTo")
                                                            )
                                                          )
                                                        ])
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row pt-4 add-btn-input cursor-pointer",
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNewResponse()
                                                }
                                              }
                                            },
                                            [_vm._m(1)]
                                          )
                                        ],
                                        2
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade show",
                                    attrs: {
                                      id: "v-pills-segment",
                                      role: "tabpanel",
                                      "aria-labelledby": "v-pills-segment-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pt-5" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("__branchSettings"))
                                          )
                                        ]),
                                        _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("__requiredItem")) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._l(
                                          _vm.segmentInput.options,
                                          function(item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "border p-2 mb-2"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "float-right"
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-danger float-right",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteSegment(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-trash text-danger"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("div", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row align-items-center"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-danger"
                                                        },
                                                        [_vm._v("*")]
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: item.value,
                                                              expression:
                                                                "item.value"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-3 ml-0",
                                                          attrs: {
                                                            id: "condnnode"
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "value",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__segment"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.allSegments,
                                                            function(
                                                              segment,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: idx,
                                                                  domProps: {
                                                                    value:
                                                                      segment.id
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        segment.name
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__whenMatching"
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row align-items-center"
                                                    },
                                                    [
                                                      _c("md-radio", {
                                                        staticClass: "ml-3",
                                                        attrs: {
                                                          value: "nnode"
                                                        },
                                                        model: {
                                                          value:
                                                            item.nextnodeType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "nextnodeType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.nextnodeType"
                                                        }
                                                      }),
                                                      item.nextnodeType ==
                                                      "nnode"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.nextNodeId,
                                                              expression:
                                                                "item.nextNodeId"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-3",
                                                          attrs: {
                                                            id: "segmentnnode"
                                                          },
                                                          on: {
                                                            focus: function(
                                                              $event
                                                            ) {
                                                              return _vm.onchanged(
                                                                $event,
                                                                index
                                                              )
                                                            },
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "nextNodeId",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__NextNodeoptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.nextNodeSelection,
                                                            function(
                                                              node,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: idx,
                                                                  domProps: {
                                                                    value:
                                                                      node.nodeId
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        node.title.substr(
                                                                          0,
                                                                          25
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        node.subtitle
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("__jumpTo")
                                                          )
                                                        )
                                                      ]),
                                                      _c("md-radio", {
                                                        staticClass: "ml-5",
                                                        attrs: {
                                                          value: "snode"
                                                        },
                                                        model: {
                                                          value:
                                                            item.nextnodeType,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              item,
                                                              "nextnodeType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "item.nextnodeType"
                                                        }
                                                      }),
                                                      item.nextnodeType ==
                                                      "snode"
                                                        ? _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [_vm._v("*")]
                                                          )
                                                        : _vm._e(),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.scenario_id,
                                                              expression:
                                                                "item.scenario_id"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-3",
                                                          attrs: {
                                                            id: "segmentsnode"
                                                          },
                                                          on: {
                                                            focus: function(
                                                              $event
                                                            ) {
                                                              return _vm.onchanged(
                                                                $event,
                                                                index
                                                              )
                                                            },
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "scenario_id",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__NextScenariooptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.allScenario,
                                                            function(
                                                              scenario,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: idx,
                                                                  domProps: {
                                                                    value:
                                                                      scenario
                                                                        .scenario
                                                                        .scenarioId
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        scenario
                                                                          .scenario
                                                                          .scenarioName
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c("div", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("__jumpTo")
                                                          )
                                                        )
                                                      ])
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ]
                                            )
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row pt-4 add-btn-input cursor-pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.addNewSegment()
                                              }
                                            }
                                          },
                                          [_vm._m(2)]
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade show",
                                    attrs: {
                                      id: "v-pills-conditions",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-conditions-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "pt-5",
                                        staticStyle: { "min-width": "653.25px" }
                                      },
                                      [
                                        _vm._l(
                                          _vm.formInput.conditionConfig.options,
                                          function(item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "border p-2 mb-2"
                                              },
                                              [
                                                _c(
                                                  "a",
                                                  {
                                                    staticClass:
                                                      "text-danger pull-right",
                                                    attrs: {
                                                      href: "javascript:void(0)"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.deleteCondition(
                                                          index
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-trash text-danger"
                                                    })
                                                  ]
                                                ),
                                                _c("div", {
                                                  staticClass: "clearfix"
                                                }),
                                                _vm._l(
                                                  item.multi_condition,
                                                  function(subItem, subIndex) {
                                                    return _c(
                                                      "div",
                                                      { key: subIndex },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-row px-4 pt-2"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group col-3"
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__source"
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          subItem.source,
                                                                        expression:
                                                                          "subItem.source"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    on: {
                                                                      change: [
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            subItem,
                                                                            "source",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                        function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onCondSourceChanged(
                                                                            $event,
                                                                            index,
                                                                            subIndex
                                                                          )
                                                                        }
                                                                      ]
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "tag"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__label"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "uservariable"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__variable"
                                                                              )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "followtime"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__FollowAccountTime"
                                                                              )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "cvkey"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                              " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__CvParam"
                                                                              )
                                                                            ) +
                                                                            "\n                            "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-group col-2"
                                                              },
                                                              [
                                                                _c("label", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__Condition"
                                                                      )
                                                                    )
                                                                  )
                                                                ]),
                                                                _c(
                                                                  "select",
                                                                  {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          subItem.expression,
                                                                        expression:
                                                                          "subItem.expression"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "form-control",
                                                                    attrs: {
                                                                      "aria-placeholder": _vm.$t(
                                                                        "__fontcolor"
                                                                      )
                                                                    },
                                                                    on: {
                                                                      change: function(
                                                                        $event
                                                                      ) {
                                                                        var $$selectedVal = Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function(
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function(
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                        _vm.$set(
                                                                          subItem,
                                                                          "expression",
                                                                          $event
                                                                            .target
                                                                            .multiple
                                                                            ? $$selectedVal
                                                                            : $$selectedVal[0]
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "exactly_match",
                                                                              selected:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__ExactlyMatch"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "fuzzy_match"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__FuzzyMatch"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable") |
                                                                    (subItem.source ==
                                                                      "cvkey")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "has_value"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__segmentcondition"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable") |
                                                                    (subItem.source ==
                                                                      "cvkey")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "no_value"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__segmentnocondition"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "num_range"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__NumberRange"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "num_gt"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__GreaterThanNumber"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "num_lt"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__LessThanNumber"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                    (subItem.source ==
                                                                      "tag") |
                                                                    (subItem.source ==
                                                                      "uservariable") |
                                                                    (subItem.source ==
                                                                      "followtime")
                                                                      ? _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "date_range"
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__DataRange"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            subItem.source ==
                                                            "tag"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__label"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem.tag,
                                                                            expression:
                                                                              "subItem.tag"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        on: {
                                                                          change: [
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal = Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function(
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function(
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                              _vm.$set(
                                                                                subItem,
                                                                                "tag",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            },
                                                                            function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.onCondTagChanged(
                                                                                index,
                                                                                subIndex
                                                                              )
                                                                            }
                                                                          ]
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "",
                                                                              disabled:
                                                                                "",
                                                                              selected:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__TagSelectOptional"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.tagList,
                                                                          function(
                                                                            tag,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key:
                                                                                  100 +
                                                                                  index,
                                                                                domProps: {
                                                                                  value: tag
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      tag.name
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            (subItem.source ==
                                                              "tag") &
                                                            (subItem.expression ==
                                                              "exactly_match")
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__value"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem.value,
                                                                            expression:
                                                                              "subItem.value"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          "aria-placeholder": _vm.$t(
                                                                            "__fontcolor"
                                                                          )
                                                                        },
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              subItem,
                                                                              "value",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "",
                                                                              disabled:
                                                                                "",
                                                                              selected:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__TagValueSelectOptional"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.valuesOfTag(
                                                                            subItem
                                                                              .tag
                                                                              .color,
                                                                            subItem
                                                                              .tag
                                                                              .name
                                                                          ),
                                                                          function(
                                                                            value,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key:
                                                                                  100 +
                                                                                  index,
                                                                                domProps: {
                                                                                  value: value
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      value
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            subItem.source ==
                                                            "uservariable"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__variable"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem
                                                                                .uservariable
                                                                                .name,
                                                                            expression:
                                                                              "subItem.uservariable.name"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              subItem.uservariable,
                                                                              "name",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "",
                                                                              disabled:
                                                                                "",
                                                                              selected:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__UserVariableSelectOptional"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.allVariables,
                                                                          function(
                                                                            value,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key:
                                                                                  "variable-" +
                                                                                  index,
                                                                                domProps: {
                                                                                  value: value
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      value
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            (subItem.expression ==
                                                              "exactly_match") &
                                                              (subItem.source ==
                                                                "uservariable") ||
                                                            subItem.expression ==
                                                              "fuzzy_match" ||
                                                            subItem.expression ==
                                                              "num_gt" ||
                                                            subItem.expression ==
                                                              "num_lt"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__value"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem.value,
                                                                            expression:
                                                                              "subItem.value"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          type:
                                                                            "text"
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            subItem.value
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.$set(
                                                                              subItem,
                                                                              "value",
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            subItem.source ==
                                                            "cvkey"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__SelectCvParam"
                                                                            )
                                                                          )
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem.value,
                                                                            expression:
                                                                              "subItem.value"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              subItem,
                                                                              "value",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "orderId",
                                                                              selected:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "orderId"
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            subItem.expression ==
                                                            "num_range"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "開始"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem
                                                                                .range
                                                                                .start,
                                                                            expression:
                                                                              "subItem.range.start"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          type:
                                                                            "text"
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            subItem
                                                                              .range
                                                                              .start
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.$set(
                                                                              subItem.range,
                                                                              "start",
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            subItem.expression ==
                                                            "num_range"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "結束"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              subItem
                                                                                .range
                                                                                .end,
                                                                            expression:
                                                                              "subItem.range.end"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "form-control",
                                                                        attrs: {
                                                                          type:
                                                                            "text"
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            subItem
                                                                              .range
                                                                              .end
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.$set(
                                                                              subItem.range,
                                                                              "end",
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            subItem.source ==
                                                              "followtime" &&
                                                            subItem.expression ==
                                                              "date_range"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "開始"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "datetime",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "date",
                                                                          "input-class":
                                                                            "form-control"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            subItem
                                                                              .range
                                                                              .start,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              subItem.range,
                                                                              "start",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "subItem.range.start"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            subItem.source ==
                                                              "followtime" &&
                                                            subItem.expression ==
                                                              "date_range"
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "結束"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "datetime",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "date",
                                                                          "input-class":
                                                                            "form-control"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            subItem
                                                                              .range
                                                                              .end,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              subItem.range,
                                                                              "end",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "subItem.range.end"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        subItem.source !==
                                                          "followtime" &&
                                                        subItem.expression ==
                                                          "date_range"
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "form-row px-4 pt-2"
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "開始"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "datetime",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "date",
                                                                          "input-class":
                                                                            "form-control"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            subItem
                                                                              .range
                                                                              .start,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              subItem.range,
                                                                              "start",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "subItem.range.start"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-group col-3"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      [
                                                                        _vm._v(
                                                                          "結束"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "datetime",
                                                                      {
                                                                        attrs: {
                                                                          type:
                                                                            "date",
                                                                          "input-class":
                                                                            "form-control"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            subItem
                                                                              .range
                                                                              .end,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              subItem.range,
                                                                              "end",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "subItem.range.end"
                                                                        }
                                                                      }
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "d-flex justify-content-end mt-3"
                                                          },
                                                          [
                                                            item.multi_condition
                                                              .length > 1
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-danger mr-2",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.deleteSubCondition(
                                                                          index,
                                                                          subIndex
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          Remove\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                            subIndex ==
                                                            item.multi_condition
                                                              .length -
                                                              1
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-info",
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.addSubCondition(
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          AND\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          ]
                                                        ),
                                                        _c("hr", {
                                                          staticClass: "solid"
                                                        })
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "row px-4 mt-3"
                                                  },
                                                  [
                                                    _c("md-radio", {
                                                      staticClass:
                                                        "md-primary ml-3 mr-0",
                                                      attrs: { value: "nnode" },
                                                      model: {
                                                        value:
                                                          item.nextnodeType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "nextnodeType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.nextnodeType"
                                                      }
                                                    }),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.nextNodeId,
                                                            expression:
                                                              "item.nextNodeId"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control col-4 ml-2",
                                                        attrs: {
                                                          id: "tagnnode"
                                                        },
                                                        on: {
                                                          focus: function(
                                                            $event
                                                          ) {
                                                            return _vm.onchanged(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              item,
                                                              "nextNodeId",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                              disabled: "",
                                                              selected: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__NextNodeoptional"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.nodeData
                                                            .totalNode,
                                                          function(node, idx) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: idx,
                                                                domProps: {
                                                                  value:
                                                                    node.nodeId
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      node.title.substr(
                                                                        0,
                                                                        25
                                                                      )
                                                                    ) +
                                                                    " " +
                                                                    _vm._s(
                                                                      node.subtitle
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    ),
                                                    _c("md-radio", {
                                                      staticClass:
                                                        "md-primary ml-3 mr-0",
                                                      attrs: { value: "snode" },
                                                      model: {
                                                        value:
                                                          item.nextnodeType,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            item,
                                                            "nextnodeType",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "item.nextnodeType"
                                                      }
                                                    }),
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.scenario_id,
                                                            expression:
                                                              "item.scenario_id"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control col-4 ml-2",
                                                        attrs: {
                                                          id: "tagsnode"
                                                        },
                                                        on: {
                                                          focus: function(
                                                            $event
                                                          ) {
                                                            return _vm.onchanged(
                                                              $event,
                                                              index
                                                            )
                                                          },
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              item,
                                                              "scenario_id",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "",
                                                              disabled: "",
                                                              selected: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__NextScenariooptional"
                                                                  )
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          _vm.allScenario,
                                                          function(
                                                            scenario,
                                                            idx
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: idx,
                                                                domProps: {
                                                                  value:
                                                                    scenario
                                                                      .scenario
                                                                      .scenarioId
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      scenario
                                                                        .scenario
                                                                        .scenarioName
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                        _c("div", { staticClass: "row pt-4" }, [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary mx-auto",
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNewCondition()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.formInput
                                                      .conditionConfig.options
                                                      .length === 0
                                                      ? "Add"
                                                      : "OR"
                                                  ) +
                                                  "\n                    "
                                              )
                                            ]
                                          )
                                        ])
                                      ],
                                      2
                                    ),
                                    _vm._m(3)
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade show",
                                    attrs: {
                                      id: "v-pills-defaultNextNode",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-defaultNextNode-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "form-row align-items-center"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "next-node-option",
                                            on: {
                                              click: function($event) {
                                                return _vm.setNextNode("nnode")
                                              }
                                            }
                                          },
                                          [
                                            _c("md-radio", {
                                              staticClass: "ml-3",
                                              attrs: {
                                                type: "radio",
                                                value: "nnode",
                                                name: "defaultNextNodeType"
                                              },
                                              model: {
                                                value:
                                                  _vm.formInput.conditionConfig
                                                    .defaultNextNode
                                                    .nextnodeType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formInput
                                                      .conditionConfig
                                                      .defaultNextNode,
                                                    "nextnodeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formInput.conditionConfig.defaultNextNode.nextnodeType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.formInput.conditionConfig
                                          .defaultNextNode.nextnodeType ===
                                        "nnode"
                                          ? _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.formInput.conditionConfig
                                                    .defaultNextNode.nextNodeId,
                                                expression:
                                                  "formInput.conditionConfig.defaultNextNode.nextNodeId"
                                              }
                                            ],
                                            staticClass: "form-control col-3",
                                            attrs: { id: "defaultnnode" },
                                            on: {
                                              focus: function($event) {
                                                return _vm.onchanged($event)
                                              },
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.formInput.conditionConfig
                                                    .defaultNextNode,
                                                  "nextNodeId",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "",
                                                  disabled: "",
                                                  selected: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__NextNodeoptional"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            ),
                                            _vm._l(
                                              _vm.nextNodeSelection,
                                              function(node, idx) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: idx,
                                                    domProps: {
                                                      value: node.nodeId
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          node.title.substr(
                                                            0,
                                                            25
                                                          )
                                                        ) +
                                                        " " +
                                                        _vm._s(node.subtitle) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          ],
                                          2
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.$t("__jumpTo")))
                                        ]),
                                        _c(
                                          "button",
                                          {
                                            staticClass: "next-node-option",
                                            on: {
                                              click: function($event) {
                                                return _vm.setNextNode("snode")
                                              }
                                            }
                                          },
                                          [
                                            _c("md-radio", {
                                              staticClass: "ml-5",
                                              attrs: {
                                                type: "radio",
                                                value: "snode",
                                                name: "defaultNextNodeType"
                                              },
                                              model: {
                                                value:
                                                  _vm.formInput.conditionConfig
                                                    .defaultNextNode
                                                    .nextnodeType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formInput
                                                      .conditionConfig
                                                      .defaultNextNode,
                                                    "nextnodeType",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formInput.conditionConfig.defaultNextNode.nextnodeType"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _vm.formInput.conditionConfig
                                          .defaultNextNode.nextnodeType ===
                                        "snode"
                                          ? _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [_vm._v("*")]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.formInput.conditionConfig
                                                    .defaultNextNode
                                                    .scenario_id,
                                                expression:
                                                  "formInput.conditionConfig.defaultNextNode.scenario_id"
                                              }
                                            ],
                                            staticClass: "form-control col-3",
                                            attrs: { id: "defaultsnode" },
                                            on: {
                                              focus: function($event) {
                                                return _vm.onchanged($event)
                                              },
                                              change: function($event) {
                                                var $$selectedVal = Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function(o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function(o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                                _vm.$set(
                                                  _vm.formInput.conditionConfig
                                                    .defaultNextNode,
                                                  "scenario_id",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: {
                                                  value: "",
                                                  disabled: "",
                                                  selected: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__NextScenariooptional"
                                                      )
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            ),
                                            _vm._l(_vm.allScenario, function(
                                              scenario,
                                              idx
                                            ) {
                                              return _c(
                                                "option",
                                                {
                                                  key: idx,
                                                  domProps: {
                                                    value:
                                                      scenario.scenario
                                                        .scenarioId
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        scenario.scenario
                                                          .scenarioName
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            })
                                          ],
                                          2
                                        ),
                                        _c("div", [
                                          _vm._v(_vm._s(_vm.$t("__jumpTo")))
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm.formInput.nodeType == "Message"
                      ? _c(
                          "div",
                          {
                            staticClass: "row border",
                            staticStyle: { height: "65vh", overflow: "auto" }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "nav flex-column nav-pills col-2 border-right pt-2",
                                attrs: {
                                  id: "v-pills-tab",
                                  role: "tablist",
                                  "aria-orientation": "vertical"
                                }
                              },
                              [
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link active",
                                        attrs: {
                                          id: "v-pills-image_size-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-image_size",
                                          role: "tab",
                                          "aria-controls": "v-pills-image_size",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__flexmessageSize"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-boldText1-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-boldText1",
                                          role: "tab",
                                          "aria-controls": "v-pills-boldText1",
                                          "aria-selected": "true"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__Titlebold"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-image-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-image",
                                          role: "tab",
                                          "aria-controls": "v-pills-image",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__image"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-boldText2-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-boldText2",
                                          role: "tab",
                                          "aria-controls": "v-pills-boldText2",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__Subtitlebold"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      id: "v-pills-text-tab",
                                      "data-toggle": "pill",
                                      href: "#v-pills-text",
                                      role: "tab",
                                      "aria-controls": "v-pills-text",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("__Textcontent"))
                                    )
                                  ]
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      id: "v-pills-buttonList-tab",
                                      "data-toggle": "pill",
                                      href: "#v-pills-buttonList",
                                      role: "tab",
                                      "aria-controls": "v-pills-buttonList",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("__Button"))
                                    )
                                  ]
                                ),
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-fb-multi-temp-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-fb-multi-temp",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-fb-multi-temp",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__templateUnion"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c("div", {
                                      staticClass: "dropdown-divider"
                                    })
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-sticker-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-sticker",
                                          role: "tab",
                                          "aria-controls": "v-pills-sticker",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__Texture"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-flexMsg-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-flexMsg",
                                          role: "tab",
                                          "aria-controls": "v-pills-flexMsg",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [_vm._v("Flex Message")]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "webbot" ||
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-carouselMsg-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-carouselMsg",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-carouselMsg",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [_vm._v("Carousel Message")]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "webbot" ||
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-carouselImageMsg-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-carouselImageMsg",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-carouselImageMsg",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [_vm._v("Carousel Image Message")]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-richMsg-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-richMsg",
                                          role: "tab",
                                          "aria-controls": "v-pills-richMsg",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [_vm._v("Rich Message")]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "v-pills-jump-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-jump",
                                          role: "tab",
                                          "aria-controls": "v-pills-jump",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__Jump"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-schedule-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-schedule",
                                          role: "tab",
                                          "aria-controls": "v-pills-schedule",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("__Sendbyappointment")
                                            )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-userreply-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-userreply",
                                          role: "tab",
                                          "aria-controls": "v-pills-userreply",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__Userreply"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-removevariable-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-removevariable",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-removevariable",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__RemoveVariable"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      id: "#v-pills-imageVideo-tab",
                                      "data-toggle": "pill",
                                      href: "#v-pills-imageVideo",
                                      role: "tab",
                                      "aria-controls": "v-pills-imageVideo",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.$t("__ImageVideoMessage"))
                                    )
                                  ]
                                ),
                                _vm.accountType == "line"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-datetimepicker-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-datetimepicker",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-datetimepicker",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("__timepickerText"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-externalapisetting-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-externalapisetting",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-externalapisetting",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.$t("__Externalapisetting")
                                            )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "dropdown-divider" }),
                                _vm.accountType == "line" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-textmessage-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-textmessage",
                                          role: "tab",
                                          "aria-controls":
                                            "v-pills-textmessage",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [_vm._v("Text Message")]
                                    )
                                  : _vm._e(),
                                _c(
                                  "a",
                                  {
                                    staticClass: "nav-link",
                                    attrs: {
                                      id: "#v-pills-quickreply-tab",
                                      "data-toggle": "pill",
                                      href: "#v-pills-quickreply",
                                      role: "tab",
                                      "aria-controls": "v-pills-quickreply",
                                      "aria-selected": "false"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.$t("__QuickReply")))]
                                ),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-datetime-trigger-tab",
                                          "data-toggle": "pill",
                                          href: "#v-datetime-trigger",
                                          role: "tab",
                                          "aria-controls": "v-datetime-trigger",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("__DateTimeTrigger"))
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "#v-pills-form-tab",
                                          "data-toggle": "pill",
                                          href: "#v-pills-form",
                                          role: "tab",
                                          "aria-controls": "v-pills-form",
                                          "aria-selected": "false"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.$t("__Form")))]
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "dropdown-divider" })
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "tab-content col-10",
                                attrs: { id: "v-pills-tabContent" }
                              },
                              [
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tab-pane fade active show",
                                        attrs: {
                                          id: "v-pills-image_size",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-image_size-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "__flexmessageSizeChange"
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _c("div", { staticClass: "pb-3" }, [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.formInput.flexSize,
                                                  expression:
                                                    "formInput.flexSize"
                                                }
                                              ],
                                              staticClass: "col-3 form-control",
                                              attrs: {
                                                name: "bubbleSize",
                                                id: "bubbleSize"
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.formInput,
                                                    "flexSize",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t("__setSize")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.formInput.bubbleSize,
                                                function(item) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: item,
                                                      domProps: { value: item }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                      " +
                                                          _vm._s(item) +
                                                          "\n                    "
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-boldText1",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-boldText1-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("__Titlebold"))
                                            )
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formInput.boldText1,
                                                expression:
                                                  "formInput.boldText1"
                                              }
                                            ],
                                            staticClass: "form-control col-6",
                                            attrs: {
                                              type: "text",
                                              id: "bold-text1-input"
                                            },
                                            domProps: {
                                              value: _vm.formInput.boldText1
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.formInput,
                                                  "boldText1",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-image",
                                          role: "tabpanel",
                                          "aria-labelledby": "v-pills-image-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-check" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-12 pb-4 my-1"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "border" },
                                                    [
                                                      _c("vue-dropzone", {
                                                        ref: "myVueDropzone",
                                                        attrs: {
                                                          id: "myDropzone",
                                                          options:
                                                            _vm.dropzoneOptions
                                                        },
                                                        on: {
                                                          "vdropzone-file-added":
                                                            _vm.flexmsgImgHandling
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-check" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("__urltitle"))
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.urlTitle,
                                                    expression:
                                                      "formInput.urlTitle"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  id: "image-title"
                                                },
                                                domProps: {
                                                  value: _vm.formInput.urlTitle
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "urlTitle",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-check pt-3" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("__ImageUrl"))
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.formInput.urlPic,
                                                    expression:
                                                      "formInput.urlPic"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  id: "url-pic-upload",
                                                  placeholder: _vm.$t(
                                                    "__urlPlaceHolder"
                                                  )
                                                },
                                                domProps: {
                                                  value: _vm.formInput.urlPic
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "urlPic",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c("hr"),
                                          _c(
                                            "div",
                                            { staticClass: "form-check" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__urllinkoptional"
                                                      )
                                                    )
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput
                                                        .urlPicTransition,
                                                    expression:
                                                      "formInput.urlPicTransition"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  id: "url-pic-transition",
                                                  placeholder: _vm.$t(
                                                    "__urlPlaceHolder"
                                                  )
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.formInput
                                                      .urlPicTransition
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "urlPicTransition",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-boldText2",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-boldText2-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c("label", [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("__Subtitlebold"))
                                            )
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.formInput.boldText2,
                                                expression:
                                                  "formInput.boldText2"
                                              }
                                            ],
                                            staticClass: "form-control col-6",
                                            attrs: {
                                              type: "text",
                                              id: "bold-text2-input"
                                            },
                                            domProps: {
                                              value: _vm.formInput.boldText2
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.formInput,
                                                  "boldText2",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-text",
                                      role: "tabpanel",
                                      "aria-labelledby": "v-pills-text-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("label", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("__Textcontent"))
                                        )
                                      ]),
                                      _vm._m(4),
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.formInput.text,
                                            expression: "formInput.text"
                                          }
                                        ],
                                        staticClass: "form-control col-9",
                                        attrs: { rows: "6", id: "text-input" },
                                        domProps: { value: _vm.formInput.text },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.formInput,
                                              "text",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-buttonList",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-buttonList-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pt-5" },
                                      [
                                        _vm.accountType != "fb"
                                          ? _c("label", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("__Buttondirection")
                                                  )
                                              )
                                            ])
                                          : _vm._e(),
                                        _vm.accountType != "fb"
                                          ? _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.buttonStyleSelect
                                                        .align,
                                                    expression:
                                                      "buttonStyleSelect.align"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-8",
                                                on: {
                                                  change: function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.buttonStyleSelect,
                                                      "align",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "horizontal"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      " +
                                                        _vm._s(
                                                          _vm.$t("__Horizontal")
                                                        ) +
                                                        "\n                    "
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: { value: "vertical" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__Vertical")
                                                      )
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "label",
                                          {
                                            staticClass: "number-count mt-4",
                                            attrs: { "data-number-count": "1" }
                                          },
                                          [_vm._v(_vm._s(_vm.$t("__Button")))]
                                        ),
                                        _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "text-danger" },
                                            [_vm._v("*")]
                                          ),
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("__requiredItem")) +
                                              "\n                  "
                                          )
                                        ]),
                                        _vm._l(_vm.formInput.button, function(
                                          item,
                                          index
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              key: index,
                                              staticClass: "border p-2 mb-2"
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "float-right" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-danger float-right",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteButton(
                                                            index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-trash text-danger"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c("br"),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "form-row px-4"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-4"
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__Textcontent"
                                                              )
                                                            )
                                                        )
                                                      ]),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              item.action.label,
                                                            expression:
                                                              "item.action.label"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value:
                                                            item.action.label
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              item.action,
                                                              "label",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                    ]
                                                  ),
                                                  _vm.accountType == "line" ||
                                                  _vm.accountType == "webbot"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-4"
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__Buttoncolor"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.color,
                                                                expression:
                                                                  "item.color"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            attrs: {
                                                              type: "text",
                                                              list:
                                                                "buttonColors"
                                                            },
                                                            domProps: {
                                                              value: item.color
                                                            },
                                                            on: {
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  item,
                                                                  "color",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          }),
                                                          _c(
                                                            "datalist",
                                                            {
                                                              attrs: {
                                                                id:
                                                                  "buttonColors"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "#00b900"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__buttonColorGreen"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "#0d6efd"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__buttonColorBlue"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "#dc3545"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__buttonColorRed"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "#ffc107"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__buttonColorYellow"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "#adb5bd"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__buttonColorGray"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "#000000"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__buttonColorBlack"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-muted"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__setHexColorCode"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm.accountType == "line" ||
                                                  _vm.accountType == "webbot"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-3"
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__fontcolor"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c(
                                                            "select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.style,
                                                                  expression:
                                                                    "item.style"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              on: {
                                                                change: function(
                                                                  $event
                                                                ) {
                                                                  var $$selectedVal = Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function(
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                  _vm.$set(
                                                                    item,
                                                                    "style",
                                                                    $event
                                                                      .target
                                                                      .multiple
                                                                      ? $$selectedVal
                                                                      : $$selectedVal[0]
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "primary"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__Fontwhite"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "option",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "secondary"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__Fontblack"
                                                                        )
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "px-4 mt-2 mb-3"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(_vm.$t("__label"))
                                                    )
                                                  ]),
                                                  _c(
                                                    "div",
                                                    { staticClass: "form-row" },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: item.tag,
                                                              expression:
                                                                "item.tag"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "col-3 form-control ml-2",
                                                          attrs: {
                                                            id: "btntag"
                                                          },
                                                          on: {
                                                            focus: function(
                                                              $event
                                                            ) {
                                                              return _vm.onchanged(
                                                                $event,
                                                                index
                                                              )
                                                            },
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "tag",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                disabled: "",
                                                                selected: ""
                                                              },
                                                              domProps: {
                                                                value: {
                                                                  color: "",
                                                                  name: ""
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__TagSelectOptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.nodeData.tags,
                                                            function(
                                                              tag,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key:
                                                                    100 + index,
                                                                  domProps: {
                                                                    value: tag
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        tag.name
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.tagValue,
                                                              expression:
                                                                "item.tagValue"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "col-3 form-control ml-2",
                                                          attrs: {
                                                            "aria-placeholder": _vm.$t(
                                                              "__fontcolor"
                                                            )
                                                          },
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "tagValue",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__TagValueSelectOptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.valuesOfTag(
                                                              item.tag.color,
                                                              item.tag.name
                                                            ),
                                                            function(
                                                              value,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key:
                                                                    100 + index,
                                                                  domProps: {
                                                                    value: value
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        value
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "px-4 mt-2 mb-3"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t("__variable")
                                                      )
                                                    )
                                                  ]),
                                                  item.userVars.length == 0
                                                    ? _c(
                                                        "div",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _c(
                                                            "a",
                                                            {
                                                              staticClass:
                                                                "text-info m-1",
                                                              attrs: {
                                                                href:
                                                                  "javascript:void(0)"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.addButtonVar(
                                                                    index
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-plus text-info"
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._l(
                                                    item.userVars,
                                                    function(
                                                      varItem,
                                                      varIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        { key: varIndex },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row mt-2"
                                                            },
                                                            [
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        varItem.variable,
                                                                      expression:
                                                                        "varItem.variable"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "col-3 form-control ml-2",
                                                                  on: {
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        varItem,
                                                                        "variable",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        selected:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                              " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__UserVariableSelectOptional"
                                                                            )
                                                                          ) +
                                                                          "\n                            "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allVariables,
                                                                    function(
                                                                      value,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            100 +
                                                                            index,
                                                                          domProps: {
                                                                            value: value
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                value
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              ),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      varItem.value,
                                                                    expression:
                                                                      "varItem.value"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "col-3 form-control ml-2",
                                                                attrs: {
                                                                  type: "text"
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    varItem.value
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      varItem,
                                                                      "value",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ml-1"
                                                                },
                                                                [
                                                                  item.userVars
                                                                    .length > 0
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-danger m-1",
                                                                          attrs: {
                                                                            href:
                                                                              "javascript:void(0)"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.deleteButtonVar(
                                                                                index,
                                                                                varIndex
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-minus text-danger"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "ml-1"
                                                                },
                                                                [
                                                                  varIndex ==
                                                                  item.userVars
                                                                    .length -
                                                                    1
                                                                    ? _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-info m-1",
                                                                          attrs: {
                                                                            href:
                                                                              "javascript:void(0)"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.addButtonVar(
                                                                                index
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-plus text-info"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-row px-4 mt-3"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-group col-12 mb-0"
                                                    },
                                                    [
                                                      _c("label", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [_vm._v("*")]
                                                        ),
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__buttonTappedAction"
                                                              )
                                                            )
                                                        )
                                                      ]),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-auto mb-0"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group col-auto"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__jumpToSpecificUrl"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-row align-items-center"
                                                                },
                                                                [
                                                                  _c(
                                                                    "md-radio",
                                                                    {
                                                                      staticClass:
                                                                        "ml-3",
                                                                      attrs: {
                                                                        type:
                                                                          "radio",
                                                                        value:
                                                                          "uri"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item
                                                                            .action
                                                                            .nodetype,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            item.action,
                                                                            "nodetype",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "item.action.nodetype"
                                                                      }
                                                                    }
                                                                  ),
                                                                  _c("input", {
                                                                    directives: [
                                                                      {
                                                                        name:
                                                                          "model",
                                                                        rawName:
                                                                          "v-model",
                                                                        value:
                                                                          item
                                                                            .action
                                                                            .type,
                                                                        expression:
                                                                          "item.action.type"
                                                                      }
                                                                    ],
                                                                    staticClass:
                                                                      "form-control col-10 ml-0",
                                                                    attrs: {
                                                                      type:
                                                                        "text",
                                                                      placeholder: _vm.$t(
                                                                        "__urlPlaceHolder"
                                                                      ),
                                                                      id:
                                                                        "uri-" +
                                                                        index
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        item
                                                                          .action
                                                                          .type
                                                                    },
                                                                    on: {
                                                                      focus: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.onchanged(
                                                                          $event,
                                                                          index
                                                                        )
                                                                      },
                                                                      input: function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          $event
                                                                            .target
                                                                            .composing
                                                                        ) {
                                                                          return
                                                                        }
                                                                        _vm.$set(
                                                                          item.action,
                                                                          "type",
                                                                          $event
                                                                            .target
                                                                            .value
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                ],
                                                                1
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row col-auto"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-6"
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__jumpToSpecificScenario"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-row align-items-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "md-radio",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            type:
                                                                              "radio",
                                                                            value:
                                                                              "snode"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item
                                                                                .action
                                                                                .nodetype,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item.action,
                                                                                "nodetype",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.action.nodetype"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "select",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                item
                                                                                  .action
                                                                                  .data,
                                                                              expression:
                                                                                "item.action.data"
                                                                            }
                                                                          ],
                                                                          staticClass:
                                                                            "form-control col-9",
                                                                          attrs: {
                                                                            id:
                                                                              "snode"
                                                                          },
                                                                          on: {
                                                                            focus: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.onchanged(
                                                                                $event,
                                                                                index
                                                                              )
                                                                            },
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal = Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function(
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function(
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                              _vm.$set(
                                                                                item.action,
                                                                                "data",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "option",
                                                                            {
                                                                              attrs: {
                                                                                value:
                                                                                  "",
                                                                                disabled:
                                                                                  "",
                                                                                selected:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "__NextScenariooptional"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                  "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._l(
                                                                            _vm.allScenario,
                                                                            function(
                                                                              scenario,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "option",
                                                                                {
                                                                                  key:
                                                                                    100 +
                                                                                    index,
                                                                                  domProps: {
                                                                                    value:
                                                                                      scenario
                                                                                        .scenario
                                                                                        .scenarioId
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    " +
                                                                                      _vm._s(
                                                                                        scenario
                                                                                          .scenario
                                                                                          .scenarioName
                                                                                      ) +
                                                                                      "\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          )
                                                                        ],
                                                                        2
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-6"
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__jumpToSpecificNode"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-row align-items-center"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "md-radio",
                                                                        {
                                                                          staticClass:
                                                                            "ml-3",
                                                                          attrs: {
                                                                            type:
                                                                              "radio",
                                                                            value:
                                                                              "nnode"
                                                                          },
                                                                          model: {
                                                                            value:
                                                                              item
                                                                                .action
                                                                                .nodetype,
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                item.action,
                                                                                "nodetype",
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "item.action.nodetype"
                                                                          }
                                                                        }
                                                                      ),
                                                                      _c(
                                                                        "select",
                                                                        {
                                                                          directives: [
                                                                            {
                                                                              name:
                                                                                "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                item
                                                                                  .action
                                                                                  .data,
                                                                              expression:
                                                                                "item.action.data"
                                                                            }
                                                                          ],
                                                                          staticClass:
                                                                            "form-control col-9",
                                                                          attrs: {
                                                                            id:
                                                                              "nnode"
                                                                          },
                                                                          on: {
                                                                            focus: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.onchanged(
                                                                                $event,
                                                                                index
                                                                              )
                                                                            },
                                                                            change: function(
                                                                              $event
                                                                            ) {
                                                                              var $$selectedVal = Array.prototype.filter
                                                                                .call(
                                                                                  $event
                                                                                    .target
                                                                                    .options,
                                                                                  function(
                                                                                    o
                                                                                  ) {
                                                                                    return o.selected
                                                                                  }
                                                                                )
                                                                                .map(
                                                                                  function(
                                                                                    o
                                                                                  ) {
                                                                                    var val =
                                                                                      "_value" in
                                                                                      o
                                                                                        ? o._value
                                                                                        : o.value
                                                                                    return val
                                                                                  }
                                                                                )
                                                                              _vm.$set(
                                                                                item.action,
                                                                                "data",
                                                                                $event
                                                                                  .target
                                                                                  .multiple
                                                                                  ? $$selectedVal
                                                                                  : $$selectedVal[0]
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "option",
                                                                            {
                                                                              attrs: {
                                                                                value:
                                                                                  "",
                                                                                disabled:
                                                                                  "",
                                                                                selected:
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                    " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "__NextNodeoptional"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                  "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _vm._l(
                                                                            _vm
                                                                              .nodeData
                                                                              .totalNode,
                                                                            function(
                                                                              node,
                                                                              idx
                                                                            ) {
                                                                              return _c(
                                                                                "option",
                                                                                {
                                                                                  key: idx,
                                                                                  domProps: {
                                                                                    value:
                                                                                      node.nodeId
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                    " +
                                                                                      _vm._s(
                                                                                        node.title.substr(
                                                                                          0,
                                                                                          25
                                                                                        )
                                                                                      ) +
                                                                                      "\n                                    " +
                                                                                      _vm._s(
                                                                                        node.subtitle
                                                                                      ) +
                                                                                      "\n                                  "
                                                                                  )
                                                                                ]
                                                                              )
                                                                            }
                                                                          )
                                                                        ],
                                                                        2
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        }),
                                        _vm.accountType != "fb" ||
                                        (_vm.accountType == "fb" &&
                                          _vm.formInput.button.length < 3)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row pt-4 add-btn-input cursor-pointer",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addNewButton()
                                                  }
                                                }
                                              },
                                              [_vm._m(5)]
                                            )
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-fb-multi-temp",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-fb-multi-temp-tab"
                                        }
                                      },
                                      [
                                        _c("fb-generic-template-setting", {
                                          attrs: {
                                            elementsInput:
                                              _vm.formInput.fbGenericElements,
                                            tagsInput: _vm.nodeData.tags,
                                            allTags: _vm.allTags,
                                            allNodes: _vm.nodeData.totalNode,
                                            allScenario: _vm.allScenario,
                                            allVariables: _vm.allVariables
                                          },
                                          on: { update: _vm.updateFormValue }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-sticker",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-sticker-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center ml-2"
                                            },
                                            [
                                              _c(
                                                "label",
                                                { staticClass: "mt-2" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("__packageID")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.packageId,
                                                    expression:
                                                      "formInput.packageId"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-3 ml-1",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.formInput.packageId
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "packageId",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _c(
                                                "label",
                                                { staticClass: "mt-2 ml-4" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("__stickerID")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.stickerId,
                                                    expression:
                                                      "formInput.stickerId"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-3 ml-1",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value: _vm.formInput.stickerId
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "stickerId",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "row mt-3 ml-3" },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: this.$t(
                                                      "__stickerRefUrl"
                                                    ),
                                                    target: "_blank",
                                                    rel: "noopener noreferrer"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.$t("__StickerList")
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-flexMsg",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-flexMsg-tab"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pt-5" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.flexMsgInvalid,
                                                    expression: "flexMsgInvalid"
                                                  }
                                                ],
                                                staticClass:
                                                  "alert alert-danger",
                                                attrs: { role: "alert" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(_vm.flexMsgInvalid) +
                                                    "\n                  "
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "pb-3" }, [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("__flexMsgTitle")
                                                  )
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput
                                                        .flexMsgTitle,
                                                    expression:
                                                      "formInput.flexMsgTitle"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-6",
                                                attrs: {
                                                  type: "text",
                                                  id: "flexMsg-title"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.flexMsgTitle
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput,
                                                      "flexMsgTitle",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]),
                                            _c("label", [
                                              _vm._v("Flex Message:")
                                            ]),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.flexMessage,
                                                  expression:
                                                    "formInput.flexMessage"
                                                }
                                              ],
                                              staticClass: "form-control col-9",
                                              attrs: {
                                                rows: "9",
                                                id: "flexMsg-input"
                                              },
                                              domProps: {
                                                value: _vm.formInput.flexMessage
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formInput,
                                                    "flexMessage",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            }),
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "__WhenusingFlexMessageyoucanuse"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _c(
                                              "a",
                                              {
                                                staticStyle: {
                                                  color: "#007bff"
                                                },
                                                attrs: {
                                                  href:
                                                    "https://developers.line.biz/flex-simulator/",
                                                  target: "_blank",
                                                  rel: "noopener noreferrer"
                                                }
                                              },
                                              [_vm._v("Flex Message Simulator")]
                                            ),
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "__Viewtheeditedcontentinthewindowinstantly"
                                                )
                                              ) + "\n                  "
                                            ),
                                            _vm._l(
                                              _vm.formInput.flexMsgConfigs,
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "border p-2 mt-2 mb-2"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "float-right"
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger float-right",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteFlexMsgConfig(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-trash text-danger"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "row px-3"
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.replacement_str,
                                                              expression:
                                                                "item.replacement_str"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "col-2 form-control",
                                                          attrs: {
                                                            type: "text",
                                                            placeholder: _vm.$t(
                                                              "__FlexReplaceTxtHint"
                                                            )
                                                          },
                                                          domProps: {
                                                            value:
                                                              item.replacement_str
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                item,
                                                                "replacement_str",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        }),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.scenarioId,
                                                                expression:
                                                                  "item.scenarioId"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "col-3 form-control ml-2",
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "scenarioId",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__NextScenariooptional"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.allScenario,
                                                              function(
                                                                scenario,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      100 +
                                                                      index,
                                                                    domProps: {
                                                                      value:
                                                                        scenario
                                                                          .scenario
                                                                          .scenarioId
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          scenario
                                                                            .scenario
                                                                            .scenarioName
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value: item.tag,
                                                                expression:
                                                                  "item.tag"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "col-3 form-control ml-2",
                                                            attrs: {
                                                              id: "flexcfgtag"
                                                            },
                                                            on: {
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                return _vm.onchanged(
                                                                  $event,
                                                                  index
                                                                )
                                                              },
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "tag",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  disabled: "",
                                                                  selected: ""
                                                                },
                                                                domProps: {
                                                                  value: {
                                                                    color: "",
                                                                    name: ""
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__TagSelectOptional"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.nodeData.tags,
                                                              function(
                                                                tag,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      100 +
                                                                      index,
                                                                    domProps: {
                                                                      value: tag
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          tag.name
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        ),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.tagValue,
                                                                expression:
                                                                  "item.tagValue"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "col-3 form-control ml-2",
                                                            attrs: {
                                                              "aria-placeholder": _vm.$t(
                                                                "__fontcolor"
                                                              )
                                                            },
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "tagValue",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  disabled: "",
                                                                  selected: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__TagValueSelectOptional"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.valuesOfTag(
                                                                item.tag.color,
                                                                item.tag.name
                                                              ),
                                                              function(
                                                                value,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key:
                                                                      100 +
                                                                      index,
                                                                    domProps: {
                                                                      value: value
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          value
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "px-1 mt-2 mb-3"
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__variable"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        item.userVars.length ==
                                                        0
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ml-1"
                                                              },
                                                              [
                                                                _c(
                                                                  "a",
                                                                  {
                                                                    staticClass:
                                                                      "text-info m-1",
                                                                    attrs: {
                                                                      href:
                                                                        "javascript:void(0)"
                                                                    },
                                                                    on: {
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        return _vm.addFlexMsgVar(
                                                                          index
                                                                        )
                                                                      }
                                                                    }
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "fas fa-plus text-info"
                                                                    })
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e(),
                                                        _vm._l(
                                                          item.userVars,
                                                          function(
                                                            varItem,
                                                            varIndex
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              { key: varIndex },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "form-row mt-2"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "select",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              varItem.variable,
                                                                            expression:
                                                                              "varItem.variable"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "col-3 form-control ml-2",
                                                                        on: {
                                                                          change: function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              varItem,
                                                                              "variable",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          }
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "option",
                                                                          {
                                                                            attrs: {
                                                                              value:
                                                                                "",
                                                                              disabled:
                                                                                "",
                                                                              selected:
                                                                                ""
                                                                            }
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              " +
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "__UserVariableSelectOptional"
                                                                                  )
                                                                                ) +
                                                                                "\n                            "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._l(
                                                                          _vm.allVariables,
                                                                          function(
                                                                            value,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "option",
                                                                              {
                                                                                key:
                                                                                  100 +
                                                                                  index,
                                                                                domProps: {
                                                                                  value: value
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                              " +
                                                                                    _vm._s(
                                                                                      value
                                                                                    ) +
                                                                                    "\n                            "
                                                                                )
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      ],
                                                                      2
                                                                    ),
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives: [
                                                                          {
                                                                            name:
                                                                              "model",
                                                                            rawName:
                                                                              "v-model",
                                                                            value:
                                                                              varItem.value,
                                                                            expression:
                                                                              "varItem.value"
                                                                          }
                                                                        ],
                                                                        staticClass:
                                                                          "col-3 form-control ml-2",
                                                                        attrs: {
                                                                          type:
                                                                            "text"
                                                                        },
                                                                        domProps: {
                                                                          value:
                                                                            varItem.value
                                                                        },
                                                                        on: {
                                                                          input: function(
                                                                            $event
                                                                          ) {
                                                                            if (
                                                                              $event
                                                                                .target
                                                                                .composing
                                                                            ) {
                                                                              return
                                                                            }
                                                                            _vm.$set(
                                                                              varItem,
                                                                              "value",
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          }
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-1"
                                                                      },
                                                                      [
                                                                        item
                                                                          .userVars
                                                                          .length >
                                                                        0
                                                                          ? _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger m-1",
                                                                                attrs: {
                                                                                  href:
                                                                                    "javascript:void(0)"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.deleteFlexMsgVar(
                                                                                      index,
                                                                                      varIndex
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fas fa-minus text-danger"
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "ml-1"
                                                                      },
                                                                      [
                                                                        varIndex ==
                                                                        item
                                                                          .userVars
                                                                          .length -
                                                                          1
                                                                          ? _c(
                                                                              "a",
                                                                              {
                                                                                staticClass:
                                                                                  "text-info m-1",
                                                                                attrs: {
                                                                                  href:
                                                                                    "javascript:void(0)"
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.addFlexMsgVar(
                                                                                      index
                                                                                    )
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fas fa-plus text-info"
                                                                                  }
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row pt-4 add-btn-input cursor-pointer",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addFlexMsgConfig()
                                                  }
                                                }
                                              },
                                              [_vm._m(6)]
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "webbot" ||
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-carouselMsg",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-carouselMsg-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pb-3" }, [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("__carouselMsgTitle")
                                              )
                                            )
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.formInput
                                                    .carouselMsgTitle,
                                                expression:
                                                  "formInput.carouselMsgTitle"
                                              }
                                            ],
                                            staticClass: "form-control col-6",
                                            attrs: {
                                              type: "text",
                                              id: "carouselMsg-title"
                                            },
                                            domProps: {
                                              value:
                                                _vm.formInput.carouselMsgTitle
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.formInput,
                                                  "carouselMsgTitle",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "bubbles-container" },
                                          [
                                            _vm._l(
                                              _vm.formInput.carouselMsg,
                                              function(bubble, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "bubble-wrapper"
                                                  },
                                                  [
                                                    _c("BubbleEditor", {
                                                      attrs: {
                                                        bubble: bubble,
                                                        index: index,
                                                        accountType:
                                                          _vm.accountType,
                                                        uploadImage:
                                                          _vm.imgHandling,
                                                        dropzoneOptions:
                                                          _vm.dropzoneOptions
                                                      },
                                                      on: {
                                                        updateBubble:
                                                          _vm.updateBubble,
                                                        deleteBubble:
                                                          _vm.deleteBubble
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mt-3",
                                                on: { click: _vm.addBubble }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("__addBubble")
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "webbot" ||
                                _vm.accountType == "line"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-carouselImageMsg",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-carouselImageMsg-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pb-3" }, [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("__carouselMsgTitle")
                                              )
                                            )
                                          ]),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.formInput
                                                    .carouselMsgTitle,
                                                expression:
                                                  "formInput.carouselMsgTitle"
                                              }
                                            ],
                                            staticClass: "form-control col-6",
                                            attrs: {
                                              type: "text",
                                              id: "carouselMsg-title"
                                            },
                                            domProps: {
                                              value:
                                                _vm.formInput.carouselMsgTitle
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.formInput,
                                                  "carouselMsgTitle",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "bubbles-container" },
                                          [
                                            _vm._l(
                                              _vm.formInput.carouselImageMsg,
                                              function(bubble, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "bubble-wrapper"
                                                  },
                                                  [
                                                    _c("ImageBubbleEditor", {
                                                      attrs: {
                                                        bubble: bubble,
                                                        index: index,
                                                        uploadImage:
                                                          _vm.imgHandling,
                                                        dropzoneOptions:
                                                          _vm.dropzoneOptions
                                                      },
                                                      on: {
                                                        updateBubble:
                                                          _vm.updateImageBubble,
                                                        deleteBubble:
                                                          _vm.deleteImageBubble
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "btn btn-primary mt-3",
                                                on: {
                                                  click: _vm.addImageBubble
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t("__addBubble")
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-richMsg",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-richMsg-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-check" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "col-12 pb-4" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "border" },
                                                    [
                                                      _c("vue-dropzone", {
                                                        ref:
                                                          "RichMsgVueDropzone",
                                                        attrs: {
                                                          id: "richMsgDropzone",
                                                          options:
                                                            _vm.dropzoneOptions
                                                        },
                                                        on: {
                                                          "vdropzone-file-added":
                                                            _vm.richMsgImgHandling
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-check mt-2" },
                                            [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("__richMsgTitle")
                                                    )
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.richMsg
                                                        .title,
                                                    expression:
                                                      "formInput.richMsg.title"
                                                  }
                                                ],
                                                staticClass:
                                                  "form-control col-6",
                                                attrs: {
                                                  type: "text",
                                                  id: "richMsg-title"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.richMsg.title
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput.richMsg,
                                                      "title",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-check mt-2" },
                                            [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("__ImageUrl"))
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput.richMsg
                                                        .baseUrl,
                                                    expression:
                                                      "formInput.richMsg.baseUrl"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  id: "richMsg-url-pic-upload",
                                                  placeholder: _vm.$t(
                                                    "__urlPlaceHolder"
                                                  ),
                                                  disabled: ""
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.richMsg
                                                      .baseUrl
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput.richMsg,
                                                      "baseUrl",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _vm.formInput.richMsg.baseUrl
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "form-check mt-2"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v("Preview")
                                                  ]),
                                                  _c("br"),
                                                  _c("img", {
                                                    staticClass:
                                                      "img-thumbnail",
                                                    attrs: {
                                                      src:
                                                        _vm.formInput.richMsg
                                                          .baseUrl + "/240",
                                                      label: "Image Preview",
                                                      alt: "Image Preview"
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "form-check mt-2" },
                                            [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("__richMsgLayout")
                                                    )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                { staticClass: "container" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "row" },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container col-2",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onRichMsgLayoutChanged(
                                                                "A"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row layout-container",
                                                              class: {
                                                                "layout-selected":
                                                                  _vm.formInput
                                                                    .richMsg
                                                                    .layout ===
                                                                  "A"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12 richmsg-layout-unit1 align-items-center",
                                                                  class: {
                                                                    "layout-selected":
                                                                      _vm
                                                                        .formInput
                                                                        .richMsg
                                                                        .layout ===
                                                                      "A"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-align":
                                                                          "center",
                                                                        "margin-top":
                                                                          "40%"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "A"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container col-2",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onRichMsgLayoutChanged(
                                                                "B"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row layout-container",
                                                              class: {
                                                                "layout-selected":
                                                                  _vm.formInput
                                                                    .richMsg
                                                                    .layout ===
                                                                  "B"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-6 richmsg-layout-unit1",
                                                                  class: {
                                                                    "layout-selected":
                                                                      _vm
                                                                        .formInput
                                                                        .richMsg
                                                                        .layout ===
                                                                      "B"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-align":
                                                                          "center",
                                                                        "margin-top":
                                                                          "80%"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "A"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-6 richmsg-layout-unit1",
                                                                  class: {
                                                                    "layout-selected":
                                                                      _vm
                                                                        .formInput
                                                                        .richMsg
                                                                        .layout ===
                                                                      "B"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-align":
                                                                          "center",
                                                                        "margin-top":
                                                                          "80%"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "B"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container col-2",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onRichMsgLayoutChanged(
                                                                "C"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row layout-container",
                                                              class: {
                                                                "layout-selected":
                                                                  _vm.formInput
                                                                    .richMsg
                                                                    .layout ===
                                                                  "C"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12 richmsg-layout-unit2",
                                                                  class: {
                                                                    "layout-selected":
                                                                      _vm
                                                                        .formInput
                                                                        .richMsg
                                                                        .layout ===
                                                                      "C"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-align":
                                                                          "center",
                                                                        "margin-top":
                                                                          "20%"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "A"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12 richmsg-layout-unit2",
                                                                  class: {
                                                                    "layout-selected":
                                                                      _vm
                                                                        .formInput
                                                                        .richMsg
                                                                        .layout ===
                                                                      "C"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-align":
                                                                          "center",
                                                                        "margin-top":
                                                                          "20%"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "B"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container col-2",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onRichMsgLayoutChanged(
                                                                "D"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row layout-container",
                                                              class: {
                                                                "layout-selected":
                                                                  _vm.formInput
                                                                    .richMsg
                                                                    .layout ===
                                                                  "D"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "col-12 richmsg-layout-unit2",
                                                                  class: {
                                                                    "layout-selected":
                                                                      _vm
                                                                        .formInput
                                                                        .richMsg
                                                                        .layout ===
                                                                      "D"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "p",
                                                                    {
                                                                      staticStyle: {
                                                                        "text-align":
                                                                          "center",
                                                                        "margin-top":
                                                                          "20%"
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "A"
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                2,
                                                                function(n) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: n,
                                                                      staticClass:
                                                                        "col-6 richmsg-layout-unit2",
                                                                      class: {
                                                                        "layout-selected":
                                                                          _vm
                                                                            .formInput
                                                                            .richMsg
                                                                            .layout ===
                                                                          "D"
                                                                      }
                                                                    },
                                                                    [
                                                                      n == 1
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticStyle: {
                                                                                "text-align":
                                                                                  "center",
                                                                                "margin-top":
                                                                                  "40%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "B"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      n == 2
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticStyle: {
                                                                                "text-align":
                                                                                  "center",
                                                                                "margin-top":
                                                                                  "40%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "C"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container col-2",
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.onRichMsgLayoutChanged(
                                                                "E"
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row layout-container",
                                                              class: {
                                                                "layout-selected":
                                                                  _vm.formInput
                                                                    .richMsg
                                                                    .layout ===
                                                                  "E"
                                                              }
                                                            },
                                                            [
                                                              _vm._l(
                                                                2,
                                                                function(n) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        "richlayout-ab-" +
                                                                        n,
                                                                      staticClass:
                                                                        "col-6 richmsg-layout-unit2",
                                                                      class: {
                                                                        "layout-selected":
                                                                          _vm
                                                                            .formInput
                                                                            .richMsg
                                                                            .layout ===
                                                                          "E"
                                                                      }
                                                                    },
                                                                    [
                                                                      n == 1
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticStyle: {
                                                                                "text-align":
                                                                                  "center",
                                                                                "margin-top":
                                                                                  "40%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "A"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      n == 2
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticStyle: {
                                                                                "text-align":
                                                                                  "center",
                                                                                "margin-top":
                                                                                  "40%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "B"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              _vm._l(
                                                                2,
                                                                function(n) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key:
                                                                        "richlayout-cd-" +
                                                                        n,
                                                                      staticClass:
                                                                        "col-6 richmsg-layout-unit2",
                                                                      class: {
                                                                        "layout-selected":
                                                                          _vm
                                                                            .formInput
                                                                            .richMsg
                                                                            .layout ===
                                                                          "E"
                                                                      }
                                                                    },
                                                                    [
                                                                      n == 1
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticStyle: {
                                                                                "text-align":
                                                                                  "center",
                                                                                "margin-top":
                                                                                  "40%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "C"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                      n == 2
                                                                        ? _c(
                                                                            "p",
                                                                            {
                                                                              staticStyle: {
                                                                                "text-align":
                                                                                  "center",
                                                                                "margin-top":
                                                                                  "40%"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "D"
                                                                              )
                                                                            ]
                                                                          )
                                                                        : _vm._e()
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-check mt-2" },
                                            [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t("__action"))
                                                )
                                              ]),
                                              _vm._l(
                                                _vm.formInput.richMsg
                                                  .areaOptions,
                                                function(action, actionIndex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: action.label,
                                                      staticClass:
                                                        "action-container mb-1"
                                                    },
                                                    [
                                                      _c(
                                                        "label",
                                                        {
                                                          staticClass:
                                                            "ml-3 mt-2"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(action.label)
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row px-4 mt-2"
                                                        },
                                                        [
                                                          _c("md-radio", {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              type: "radio",
                                                              value: "message"
                                                            },
                                                            model: {
                                                              value:
                                                                action.type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  action,
                                                                  "type",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "action.type"
                                                            }
                                                          }),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  action.text,
                                                                expression:
                                                                  "action.text"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control col-10 ml-0",
                                                            attrs: {
                                                              type: "text",
                                                              placeholder: _vm.$t(
                                                                "__Textcontent"
                                                              ),
                                                              id:
                                                                "text-" +
                                                                action.label
                                                            },
                                                            domProps: {
                                                              value: action.text
                                                            },
                                                            on: {
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                return _vm.onRichMsgActionTypeChanged(
                                                                  $event,
                                                                  actionIndex
                                                                )
                                                              },
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  action,
                                                                  "text",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "row px-4 mt-2"
                                                        },
                                                        [
                                                          _c("md-radio", {
                                                            staticClass: "ml-3",
                                                            attrs: {
                                                              type: "radio",
                                                              value: "uri"
                                                            },
                                                            model: {
                                                              value:
                                                                action.type,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  action,
                                                                  "type",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "action.type"
                                                            }
                                                          }),
                                                          _c("input", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  action.url,
                                                                expression:
                                                                  "action.url"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control col-10 ml-0",
                                                            attrs: {
                                                              type: "text",
                                                              placeholder: _vm.$t(
                                                                "__urllinkoptional"
                                                              ),
                                                              id:
                                                                "uri-" +
                                                                action.label
                                                            },
                                                            domProps: {
                                                              value: action.url
                                                            },
                                                            on: {
                                                              focus: function(
                                                                $event
                                                              ) {
                                                                return _vm.onRichMsgActionTypeChanged(
                                                                  $event,
                                                                  actionIndex
                                                                )
                                                              },
                                                              input: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  action,
                                                                  "url",
                                                                  $event.target
                                                                    .value
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-jump",
                                          role: "tabpanel",
                                          "aria-labelledby": "v-pills-jump-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row align-items-center mt-2"
                                            },
                                            [
                                              _c("md-radio", {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  type: "radio",
                                                  value: "scenario"
                                                },
                                                model: {
                                                  value: _vm.jumpType,
                                                  callback: function($$v) {
                                                    _vm.jumpType = $$v
                                                  },
                                                  expression: "jumpType"
                                                }
                                              }),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.nextScenario,
                                                      expression: "nextScenario"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control col-4 mr-2",
                                                  attrs: { id: "jumpscenario" },
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.onchanged(
                                                        $event
                                                      )
                                                    },
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.nextScenario = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "",
                                                        selected: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__NextScenariooptional"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.allScenario,
                                                    function(scenario, index) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: 100 + index,
                                                          domProps: {
                                                            value:
                                                              scenario.scenario
                                                                .scenarioId
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                scenario
                                                                  .scenario
                                                                  .scenarioName
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c("md-radio", {
                                                staticClass: "ml-3",
                                                attrs: {
                                                  type: "radio",
                                                  value: "node"
                                                },
                                                model: {
                                                  value: _vm.jumpType,
                                                  callback: function($$v) {
                                                    _vm.jumpType = $$v
                                                  },
                                                  expression: "jumpType"
                                                }
                                              }),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.nextNode,
                                                      expression: "nextNode"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control col-4 mr-2",
                                                  attrs: { id: "jumpnode" },
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.onchanged(
                                                        $event
                                                      )
                                                    },
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.nextNode = $event
                                                        .target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "",
                                                        selected: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__NextNodeoptional"
                                                            )
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.nextAndSameNodeSelection,
                                                    function(node, index) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: index,
                                                          domProps: {
                                                            value: node.nodeId
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                node.title.substr(
                                                                  0,
                                                                  25
                                                                )
                                                              ) +
                                                              " " +
                                                              _vm._s(
                                                                node.subtitle
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade pt-5",
                                        attrs: {
                                          id: "v-pills-schedule",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-schedule-tab"
                                        }
                                      },
                                      [
                                        _vm._l(
                                          _vm.formInput.reservations,
                                          function(item, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: index,
                                                staticClass: "border p-2 mb-2"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "float-right"
                                                  },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-danger float-right",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteNewReservation(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-trash text-danger"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c("br"),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-row px-4"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-5"
                                                      },
                                                      [
                                                        _c("label", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [_vm._v("*")]
                                                          ),
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__Sentconversation"
                                                                )
                                                              )
                                                          )
                                                        ]),
                                                        _c(
                                                          "select",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  item.schedule,
                                                                expression:
                                                                  "item.schedule"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "form-control",
                                                            on: {
                                                              change: function(
                                                                $event
                                                              ) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "schedule",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "option",
                                                              {
                                                                attrs: {
                                                                  value: "",
                                                                  selected: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__NextScenariooptional"
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              _vm.allScenario,
                                                              function(
                                                                scenario,
                                                                idx
                                                              ) {
                                                                return _c(
                                                                  "option",
                                                                  {
                                                                    key: idx,
                                                                    domProps: {
                                                                      value:
                                                                        scenario
                                                                          .scenario
                                                                          .scenarioId
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                          " +
                                                                        _vm._s(
                                                                          scenario
                                                                            .scenario
                                                                            .scenarioName
                                                                        ) +
                                                                        "\n                        "
                                                                    )
                                                                  ]
                                                                )
                                                              }
                                                            )
                                                          ],
                                                          2
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-6"
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__Appointment"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-inline"
                                                          },
                                                          [
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.scheduletime,
                                                                  expression:
                                                                    "item.scheduletime"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "form-control col-4 mr-0",
                                                              attrs: {
                                                                type: "number"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  item.scheduletime
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    item,
                                                                    "scheduletime",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            }),
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item.scheduleunit,
                                                                    expression:
                                                                      "item.scheduleunit"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control ml-1",
                                                                on: {
                                                                  change: function(
                                                                    $event
                                                                  ) {
                                                                    var $$selectedVal = Array.prototype.filter
                                                                      .call(
                                                                        $event
                                                                          .target
                                                                          .options,
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          return o.selected
                                                                        }
                                                                      )
                                                                      .map(
                                                                        function(
                                                                          o
                                                                        ) {
                                                                          var val =
                                                                            "_value" in
                                                                            o
                                                                              ? o._value
                                                                              : o.value
                                                                          return val
                                                                        }
                                                                      )
                                                                    _vm.$set(
                                                                      item,
                                                                      "scheduleunit",
                                                                      $event
                                                                        .target
                                                                        .multiple
                                                                        ? $$selectedVal
                                                                        : $$selectedVal[0]
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "days"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__days"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "hours"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__hours"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "minutes"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "__minutes"
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            ),
                                                            _c("label", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "__Rear"
                                                                  )
                                                                )
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "form-row px-4"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3"
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__timepickerStartTime"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger m-1",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.clearReservationTimeRange(
                                                                  index,
                                                                  "start"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-undo text-success"
                                                            })
                                                          ]
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.window
                                                                  .start,
                                                              expression:
                                                                "item.window.start"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "time"
                                                          },
                                                          domProps: {
                                                            value:
                                                              item.window.start
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                item.window,
                                                                "start",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-group col-3"
                                                      },
                                                      [
                                                        _c("label", [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__timepickerEndTime"
                                                              )
                                                            )
                                                          )
                                                        ]),
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger m-1",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.clearReservationTimeRange(
                                                                  index,
                                                                  "end"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-undo text-success"
                                                            })
                                                          ]
                                                        ),
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.window.end,
                                                              expression:
                                                                "item.window.end"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control",
                                                          attrs: {
                                                            type: "time"
                                                          },
                                                          domProps: {
                                                            value:
                                                              item.window.end
                                                          },
                                                          on: {
                                                            input: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                $event.target
                                                                  .composing
                                                              ) {
                                                                return
                                                              }
                                                              _vm.$set(
                                                                item.window,
                                                                "end",
                                                                $event.target
                                                                  .value
                                                              )
                                                            }
                                                          }
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row pt-4 add-btn-input cursor-pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.addNewReservation()
                                              }
                                            }
                                          },
                                          [_vm._m(7)]
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-imageVideo",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-imageVideo-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("div", { staticClass: "form-check" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-9 pb-4 my-1" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "border" },
                                              [
                                                _c("vue-dropzone", {
                                                  ref: "OriginalVueDropzone",
                                                  attrs: {
                                                    id: "originalDropzone",
                                                    options: _vm.dropzoneOptions
                                                  },
                                                  on: {
                                                    "vdropzone-file-added":
                                                      _vm.originalImgHandling
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ])
                                    ]),
                                    _vm.accountType != "fb"
                                      ? _c(
                                          "div",
                                          { staticClass: "form-check" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("__ImageUrl"))
                                              )
                                            ]),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput
                                                      .originalImageUrl,
                                                  expression:
                                                    "formInput.originalImageUrl"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "origin-url-pic-upload",
                                                placeholder: "https://..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.formInput.originalImageUrl
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formInput,
                                                    "originalImageUrl",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.accountType == "fb" ||
                                    _vm.accountType == "ig"
                                      ? _c(
                                          "div",
                                          { staticClass: "form-check" },
                                          [
                                            _c("label", [
                                              _vm._v("Attachment Id")
                                            ]),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput
                                                      .originalImageUrl,
                                                  expression:
                                                    "formInput.originalImageUrl"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "attach-id-pic-upload-fb"
                                              },
                                              domProps: {
                                                value:
                                                  _vm.formInput.originalImageUrl
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formInput,
                                                    "originalImageUrl",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.accountType == "line" ||
                                    _vm.accountType == "webbot"
                                      ? _c(
                                          "div",
                                          { staticClass: "form-check pt-3" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(_vm.$t("__VideoUrl"))
                                              )
                                            ]),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput
                                                      .originalVideoUrl,
                                                  expression:
                                                    "formInput.originalVideoUrl"
                                                }
                                              ],
                                              staticClass: "form-control",
                                              attrs: {
                                                type: "text",
                                                id: "origin-url-video-upload",
                                                placeholder: "https://..."
                                              },
                                              domProps: {
                                                value:
                                                  _vm.formInput.originalVideoUrl
                                              },
                                              on: {
                                                input: function($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.formInput,
                                                    "originalVideoUrl",
                                                    $event.target.value
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-datetimepicker",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-datetimepicker-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("__requiredItem")) +
                                            "\n                  "
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "form-row px-4 pt-4" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group col-4" },
                                            [
                                              _c("label", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v("*")]
                                                ),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t("__Textcontent")
                                                    )
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput
                                                        .datetimepicker.action
                                                        .label,
                                                    expression:
                                                      "formInput.datetimepicker.action.label"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.datetimepicker
                                                      .action.label
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput
                                                        .datetimepicker.action,
                                                      "label",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group col-4" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("__Buttoncolor")
                                                  )
                                                )
                                              ]),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput
                                                        .datetimepicker.color,
                                                    expression:
                                                      "formInput.datetimepicker.color"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  type: "text",
                                                  list: "buttonColors"
                                                },
                                                domProps: {
                                                  value:
                                                    _vm.formInput.datetimepicker
                                                      .color
                                                },
                                                on: {
                                                  input: function($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.formInput
                                                        .datetimepicker,
                                                      "color",
                                                      $event.target.value
                                                    )
                                                  }
                                                }
                                              }),
                                              _c(
                                                "datalist",
                                                {
                                                  attrs: { id: "buttonColors" }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "#00b900"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__buttonColorGreen"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "#0d6efd"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__buttonColorBlue"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "#dc3545"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__buttonColorRed"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "#ffc107"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__buttonColorYellow"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "#adb5bd"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__buttonColorGray"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "#000000"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__buttonColorBlack"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "small",
                                                { staticClass: "text-muted" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__setHexColorCode"
                                                      )
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group col-3" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(_vm.$t("__fontcolor"))
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.formInput
                                                          .datetimepicker.style,
                                                      expression:
                                                        "formInput.datetimepicker.style"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.formInput
                                                          .datetimepicker,
                                                        "style",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "primary"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__Fontwhite"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "secondary"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__Fontblack"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-row px-4 pt-4" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-group col-4" },
                                            [
                                              _vm._m(8),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.formInput
                                                          .datetimepicker.action
                                                          .mode,
                                                      expression:
                                                        "formInput.datetimepicker.action.mode"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  on: {
                                                    change: [
                                                      function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .datetimepicker
                                                            .action,
                                                          "mode",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                      function($event) {
                                                        return _vm.onDtPickerTypechanged(
                                                          true
                                                        )
                                                      }
                                                    ]
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { value: "date" }
                                                    },
                                                    [_vm._v("Date")]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { value: "time" }
                                                    },
                                                    [_vm._v("Time")]
                                                  ),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "datetime"
                                                      }
                                                    },
                                                    [_vm._v("Datetime")]
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-group col-4" },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "__timepickerVariable"
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.formInput
                                                          .datetimepicker
                                                          .varName,
                                                      expression:
                                                        "formInput.datetimepicker.varName"
                                                    }
                                                  ],
                                                  staticClass: "form-control",
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.formInput
                                                          .datetimepicker,
                                                        "varName",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "",
                                                        disabled: "",
                                                        selected: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__UserVariableSelectOptional"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.allVariables,
                                                    function(value, index) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: 100 + index,
                                                          domProps: {
                                                            value: value
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(value) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "px-4 mt-2 mb-3" },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("__label")))
                                          ]),
                                          _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.formInput
                                                          .datetimepicker.tag,
                                                      expression:
                                                        "formInput.datetimepicker.tag"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "col-3 form-control ml-2",
                                                  attrs: {
                                                    id: "datetimepicker"
                                                  },
                                                  on: {
                                                    focus: function($event) {
                                                      return _vm.onchanged(
                                                        $event
                                                      )
                                                    },
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.formInput
                                                          .datetimepicker,
                                                        "tag",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        disabled: "",
                                                        selected: ""
                                                      },
                                                      domProps: {
                                                        value: {
                                                          color: "",
                                                          name: ""
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__TagSelectOptional"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.nodeData.tags,
                                                    function(tag, index) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: 100 + index,
                                                          domProps: {
                                                            value: tag
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(tag.name) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              ),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.formInput
                                                          .datetimepicker
                                                          .tagValue,
                                                      expression:
                                                        "formInput.datetimepicker.tagValue"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "col-3 form-control ml-2",
                                                  attrs: {
                                                    "aria-placeholder": _vm.$t(
                                                      "__fontcolor"
                                                    )
                                                  },
                                                  on: {
                                                    change: function($event) {
                                                      var $$selectedVal = Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function(o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function(o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                      _vm.$set(
                                                        _vm.formInput
                                                          .datetimepicker,
                                                        "tagValue",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        value: "",
                                                        disabled: "",
                                                        selected: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "__TagValueSelectOptional"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.valuesOfTag(
                                                      _vm.formInput
                                                        .datetimepicker.tag
                                                        .color,
                                                      _vm.formInput
                                                        .datetimepicker.tag.name
                                                    ),
                                                    function(value, index) {
                                                      return _c(
                                                        "option",
                                                        {
                                                          key: 100 + index,
                                                          domProps: {
                                                            value: value
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                          " +
                                                              _vm._s(value) +
                                                              "\n                        "
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "px-4 mt-2 mb-3" },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("__variable")))
                                          ]),
                                          _vm.formInput.datetimepicker.userVars
                                            .length == 0
                                            ? _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-info m-1",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addDtPickerVar()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-plus text-info"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.formInput.datetimepicker
                                              .userVars,
                                            function(varItem, varIndex) {
                                              return _c(
                                                "div",
                                                { key: varIndex },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row mt-2"
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                varItem.variable,
                                                              expression:
                                                                "varItem.variable"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "col-3 form-control ml-2",
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                varItem,
                                                                "variable",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__UserVariableSelectOptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.allVariables,
                                                            function(
                                                              value,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key:
                                                                    100 + index,
                                                                  domProps: {
                                                                    value: value
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        value
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              varItem.value,
                                                            expression:
                                                              "varItem.value"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "col-3 form-control ml-2",
                                                        attrs: { type: "text" },
                                                        domProps: {
                                                          value: varItem.value
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              varItem,
                                                              "value",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm.formInput
                                                            .datetimepicker
                                                            .userVars.length > 0
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-danger m-1",
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:void(0)"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteDtPickerVar(
                                                                        varIndex
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-minus text-danger"
                                                                  })
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          varIndex ==
                                                          _vm.formInput
                                                            .datetimepicker
                                                            .userVars.length -
                                                            1
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-info m-1",
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:void(0)"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addDtPickerVar()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-plus text-info"
                                                                  })
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "px-4 mt-2 mb-3" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "__timepickerInitialTime"
                                                )
                                              )
                                            )
                                          ]),
                                          _c(
                                            "a",
                                            {
                                              staticClass: "text-danger m-1",
                                              attrs: {
                                                href: "javascript:void(0)"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.clearDtPickerDate(
                                                    "initial"
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-undo text-success"
                                              })
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-4"
                                                },
                                                [
                                                  _vm.dtPickerType ===
                                                  "checkbox"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.initial,
                                                            expression:
                                                              "formInput.datetimepicker.action.initial"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: "checkbox"
                                                        },
                                                        domProps: {
                                                          checked: Array.isArray(
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.initial
                                                          )
                                                            ? _vm._i(
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action
                                                                  .initial,
                                                                null
                                                              ) > -1
                                                            : _vm.formInput
                                                                .datetimepicker
                                                                .action.initial
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action
                                                                  .initial,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .action,
                                                                    "initial",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .action,
                                                                    "initial",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action,
                                                                "initial",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      })
                                                    : _vm.dtPickerType ===
                                                      "radio"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.initial,
                                                            expression:
                                                              "formInput.datetimepicker.action.initial"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: "radio"
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.initial,
                                                            null
                                                          )
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action,
                                                              "initial",
                                                              null
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.initial,
                                                            expression:
                                                              "formInput.datetimepicker.action.initial"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: _vm.dtPickerType
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.initial
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action,
                                                              "initial",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "px-4 mt-2 mb-3" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "form-row" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-4"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__timepickerMinTime"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-danger m-1",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.clearDtPickerDate(
                                                            "min"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-undo text-success"
                                                      })
                                                    ]
                                                  ),
                                                  _vm.dtPickerType ===
                                                  "checkbox"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.min,
                                                            expression:
                                                              "formInput.datetimepicker.action.min"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: "checkbox"
                                                        },
                                                        domProps: {
                                                          checked: Array.isArray(
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.min
                                                          )
                                                            ? _vm._i(
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action.min,
                                                                null
                                                              ) > -1
                                                            : _vm.formInput
                                                                .datetimepicker
                                                                .action.min
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action.min,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .action,
                                                                    "min",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .action,
                                                                    "min",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action,
                                                                "min",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      })
                                                    : _vm.dtPickerType ===
                                                      "radio"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.min,
                                                            expression:
                                                              "formInput.datetimepicker.action.min"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: "radio"
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.min,
                                                            null
                                                          )
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action,
                                                              "min",
                                                              null
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.min,
                                                            expression:
                                                              "formInput.datetimepicker.action.min"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: _vm.dtPickerType
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.min
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action,
                                                              "min",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-4"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__timepickerMaxTime"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-danger m-1",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.clearDtPickerDate(
                                                            "max"
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-undo text-success"
                                                      })
                                                    ]
                                                  ),
                                                  _vm.dtPickerType ===
                                                  "checkbox"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.max,
                                                            expression:
                                                              "formInput.datetimepicker.action.max"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: "checkbox"
                                                        },
                                                        domProps: {
                                                          checked: Array.isArray(
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.max
                                                          )
                                                            ? _vm._i(
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action.max,
                                                                null
                                                              ) > -1
                                                            : _vm.formInput
                                                                .datetimepicker
                                                                .action.max
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$a =
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action.max,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .action,
                                                                    "max",
                                                                    $$a.concat([
                                                                      $$v
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .action,
                                                                    "max",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                _vm.formInput
                                                                  .datetimepicker
                                                                  .action,
                                                                "max",
                                                                $$c
                                                              )
                                                            }
                                                          }
                                                        }
                                                      })
                                                    : _vm.dtPickerType ===
                                                      "radio"
                                                    ? _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.max,
                                                            expression:
                                                              "formInput.datetimepicker.action.max"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: "radio"
                                                        },
                                                        domProps: {
                                                          checked: _vm._q(
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.max,
                                                            null
                                                          )
                                                        },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            return _vm.$set(
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action,
                                                              "max",
                                                              null
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action.max,
                                                            expression:
                                                              "formInput.datetimepicker.action.max"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "form-control ml-2",
                                                        attrs: {
                                                          type: _vm.dtPickerType
                                                        },
                                                        domProps: {
                                                          value:
                                                            _vm.formInput
                                                              .datetimepicker
                                                              .action.max
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              _vm.formInput
                                                                .datetimepicker
                                                                .action,
                                                              "max",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      })
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-row px-4 mt-3" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "form-group col-12 mb-0"
                                            },
                                            [
                                              _c("label", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "__buttonTappedAction"
                                                    )
                                                  )
                                                )
                                              ]),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-auto mb-0"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row col-auto"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-6"
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__jumpToSpecificScenario"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row align-items-center"
                                                            },
                                                            [
                                                              _c("md-radio", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  type: "radio",
                                                                  value:
                                                                    "datesnode"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .nodetype,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .datetimepicker,
                                                                      "nodetype",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "formInput.datetimepicker.nodetype"
                                                                }
                                                              }),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .datetimepicker
                                                                          .nextScenario,
                                                                      expression:
                                                                        "formInput.datetimepicker.nextScenario"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-9",
                                                                  attrs: {
                                                                    id:
                                                                      "datesnode"
                                                                  },
                                                                  on: {
                                                                    focus: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onchanged(
                                                                        $event
                                                                      )
                                                                    },
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .datetimepicker,
                                                                        "nextScenario",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        selected:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__NextScenariooptional"
                                                                            )
                                                                          ) +
                                                                          "\n                                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.allScenario,
                                                                    function(
                                                                      scenario,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key:
                                                                            100 +
                                                                            index,
                                                                          domProps: {
                                                                            value:
                                                                              scenario
                                                                                .scenario
                                                                                .scenarioId
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                  " +
                                                                              _vm._s(
                                                                                scenario
                                                                                  .scenario
                                                                                  .scenarioName
                                                                              ) +
                                                                              "\n                                "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-6"
                                                        },
                                                        [
                                                          _c("label", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "__jumpToSpecificNode"
                                                                )
                                                              )
                                                            )
                                                          ]),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row align-items-center"
                                                            },
                                                            [
                                                              _c("md-radio", {
                                                                staticClass:
                                                                  "ml-3",
                                                                attrs: {
                                                                  type: "radio",
                                                                  value:
                                                                    "datennode"
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm
                                                                      .formInput
                                                                      .datetimepicker
                                                                      .nodetype,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm
                                                                        .formInput
                                                                        .datetimepicker,
                                                                      "nodetype",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "formInput.datetimepicker.nodetype"
                                                                }
                                                              }),
                                                              _c(
                                                                "select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "model",
                                                                      rawName:
                                                                        "v-model",
                                                                      value:
                                                                        _vm
                                                                          .formInput
                                                                          .datetimepicker
                                                                          .nextNode,
                                                                      expression:
                                                                        "formInput.datetimepicker.nextNode"
                                                                    }
                                                                  ],
                                                                  staticClass:
                                                                    "form-control col-9",
                                                                  attrs: {
                                                                    id:
                                                                      "datennode"
                                                                  },
                                                                  on: {
                                                                    focus: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onchanged(
                                                                        $event
                                                                      )
                                                                    },
                                                                    change: function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        _vm
                                                                          .formInput
                                                                          .datetimepicker,
                                                                        "nextNode",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "option",
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          "",
                                                                        disabled:
                                                                          "",
                                                                        selected:
                                                                          ""
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                                  " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "__NextNodeoptional"
                                                                            )
                                                                          ) +
                                                                          "\n                                "
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._l(
                                                                    _vm.nextNodeSelection,
                                                                    function(
                                                                      node,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key: index,
                                                                          domProps: {
                                                                            value:
                                                                              node.nodeId
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                  " +
                                                                              _vm._s(
                                                                                node.title.substr(
                                                                                  0,
                                                                                  25
                                                                                )
                                                                              ) +
                                                                              "\n                                  " +
                                                                              _vm._s(
                                                                                node.subtitle
                                                                              ) +
                                                                              "\n                                "
                                                                          )
                                                                        ]
                                                                      )
                                                                    }
                                                                  )
                                                                ],
                                                                2
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-userreply",
                                      role: "tabpanel",
                                      "aria-labelledby": "v-pills-userreply-tab"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "pt-5" },
                                      [
                                        _c("label", [
                                          _vm._v(
                                            " " + _vm._s(_vm.$t("__variable"))
                                          )
                                        ]),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-info m-1",
                                            attrs: {
                                              href: "javascript:void(0)"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.showExistVariable(
                                                  "UserReply"
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-edit text-info"
                                            })
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-row" },
                                          _vm._l(
                                            _vm.formInput.userAction.userReply
                                              .variables,
                                            function(variable, index) {
                                              return _c("div", { key: index }, [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-link btn-variable"
                                                  },
                                                  [
                                                    _vm._v(
                                                      "$" + _vm._s(variable)
                                                    )
                                                  ]
                                                )
                                              ])
                                            }
                                          ),
                                          0
                                        ),
                                        _vm._l(
                                          _vm.formInput.userAction.userReply
                                            .validate.inputList,
                                          function(items, index) {
                                            return _c(
                                              "div",
                                              {
                                                key: items.type,
                                                staticClass: "form-row"
                                              },
                                              [
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value: items.type,
                                                        expression: "items.type"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "form-control col-3 ml-2",
                                                    attrs: {
                                                      id: "userreplyscenario"
                                                    },
                                                    on: {
                                                      change: [
                                                        function($event) {
                                                          var $$selectedVal = Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function(o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function(o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                          _vm.$set(
                                                            items,
                                                            "type",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                        function($event) {
                                                          return _vm.onUserReplyValidateTypeChanged(
                                                            $event,
                                                            index
                                                          )
                                                        }
                                                      ]
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "null",
                                                          selected: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "Eng" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_Eng"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "Num" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_Num"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "Len" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_Len"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "RegExp"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_RegExp"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "Phone"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_Phone"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "Post" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_Post"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: { value: "City" }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__UserreplyVariableType_City"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                items.type == "Len"
                                                  ? _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.formInput
                                                                .userAction
                                                                .userReply
                                                                .selectCompare,
                                                            expression:
                                                              "formInput.userAction.userReply.selectCompare"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "col-1 form-control ml-2",
                                                        attrs: { type: "text" },
                                                        on: {
                                                          change: function(
                                                            $event
                                                          ) {
                                                            var $$selectedVal = Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function(o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function(o) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                            _vm.$set(
                                                              _vm.formInput
                                                                .userAction
                                                                .userReply,
                                                              "selectCompare",
                                                              $event.target
                                                                .multiple
                                                                ? $$selectedVal
                                                                : $$selectedVal[0]
                                                            )
                                                          }
                                                        }
                                                      },
                                                      _vm._l(
                                                        _vm.formInput.userAction
                                                          .userReply
                                                          .lensCompare,
                                                        function(item) {
                                                          return _c(
                                                            "option",
                                                            {
                                                              key: item.value,
                                                              domProps: {
                                                                value:
                                                                  item.label
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    item.value
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    )
                                                  : _vm._e(),
                                                items.type == "Len"
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: items.value,
                                                          expression:
                                                            "items.value"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "col-3 form-control ml-2",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder: _vm.$t(
                                                          "__UserreplyVariableType_Len"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: items.value
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            items,
                                                            "value",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                items.type == "RegExp"
                                                  ? _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: items.value,
                                                          expression:
                                                            "items.value"
                                                        }
                                                      ],
                                                      staticClass:
                                                        "col-3 form-control ml-2",
                                                      attrs: {
                                                        type: "text",
                                                        placeholder: _vm.$t(
                                                          "__UserreplyVariableType_RegExp"
                                                        )
                                                      },
                                                      domProps: {
                                                        value: items.value
                                                      },
                                                      on: {
                                                        input: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          ) {
                                                            return
                                                          }
                                                          _vm.$set(
                                                            items,
                                                            "value",
                                                            $event.target.value
                                                          )
                                                        }
                                                      }
                                                    })
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-1 mt-2" },
                                                  [
                                                    _vm.formInput.userAction
                                                      .userReply.validate
                                                      .inputList.length > 1
                                                      ? _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger m-1",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteButtonRegex(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-minus text-danger"
                                                            })
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "ml-1 mt-2" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-info m-1",
                                                        attrs: {
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.addButtonRegex(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-plus text-info"
                                                        })
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "form-check mt-2" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.userAction
                                                      .userReply.encryption,
                                                  expression:
                                                    "formInput.userAction.userReply.encryption"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.formInput.userAction
                                                    .userReply.encryption
                                                )
                                                  ? _vm._i(
                                                      _vm.formInput.userAction
                                                        .userReply.encryption,
                                                      null
                                                    ) > -1
                                                  : _vm.formInput.userAction
                                                      .userReply.encryption
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.formInput.userAction
                                                        .userReply.encryption,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply,
                                                          "encryption",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply,
                                                          "encryption",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.formInput.userAction
                                                        .userReply,
                                                      "encryption",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: { for: "ecsiteBox" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.$t("__encryption")
                                                    ) +
                                                    "\n                    "
                                                )
                                              ]
                                            )
                                          ]
                                        ),
                                        _c("label", { staticClass: "mt-2" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t("__systemReply"))
                                          )
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "mt-2 mb-3" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "__VariableFormatValidateSuccess"
                                                  )
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c("md-radio", {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    type: "radio",
                                                    value: "scenario"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .userReplyValidateNextType
                                                        .success,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.userReplyValidateNextType,
                                                        "success",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userReplyValidateNextType.success"
                                                  }
                                                }),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .success.scenarioId,
                                                        expression:
                                                          "formInput.userAction.userReply.validate.success.scenarioId"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "form-control col-4 ml-2",
                                                    attrs: {
                                                      id:
                                                        "userReplyValidateSuccessScenario"
                                                    },
                                                    on: {
                                                      focus: function($event) {
                                                        return _vm.onNextScenarioNodeChanged(
                                                          $event
                                                        )
                                                      },
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .success,
                                                          "scenarioId",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "",
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__NextScenariooptional"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.allScenario,
                                                      function(
                                                        scenario,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: 100 + index,
                                                            domProps: {
                                                              value:
                                                                scenario
                                                                  .scenario
                                                                  .scenarioId
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  scenario
                                                                    .scenario
                                                                    .scenarioName
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _c("md-radio", {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    type: "radio",
                                                    value: "node"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .userReplyValidateNextType
                                                        .success,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.userReplyValidateNextType,
                                                        "success",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userReplyValidateNextType.success"
                                                  }
                                                }),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .success.nextNodeId,
                                                        expression:
                                                          "formInput.userAction.userReply.validate.success.nextNodeId"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "form-control col-4 ml-2",
                                                    attrs: {
                                                      id:
                                                        "userReplyValidateSuccessNode"
                                                    },
                                                    on: {
                                                      focus: function($event) {
                                                        return _vm.onNextScenarioNodeChanged(
                                                          $event
                                                        )
                                                      },
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .success,
                                                          "nextNodeId",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "",
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__NextNodeoptional"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.nextAndSameNodeSelection,
                                                      function(node, index) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: index,
                                                            domProps: {
                                                              value: node.nodeId
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  node.title.substr(
                                                                    0,
                                                                    25
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  node.subtitle
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "mt-2 mb-3" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t(
                                                    "__VariableFormatValidateFailed"
                                                  )
                                                )
                                              )
                                            ]),
                                            _c(
                                              "div",
                                              { staticClass: "form-row" },
                                              [
                                                _c("md-radio", {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    type: "radio",
                                                    value: "scenario"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .userReplyValidateNextType
                                                        .failed,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.userReplyValidateNextType,
                                                        "failed",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userReplyValidateNextType.failed"
                                                  }
                                                }),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .failed.scenarioId,
                                                        expression:
                                                          "formInput.userAction.userReply.validate.failed.scenarioId"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "form-control col-4 ml-2",
                                                    attrs: {
                                                      id:
                                                        "userReplyValidateFailedScenario"
                                                    },
                                                    on: {
                                                      focus: function($event) {
                                                        return _vm.onNextScenarioNodeChanged(
                                                          $event
                                                        )
                                                      },
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .failed,
                                                          "scenarioId",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "",
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__NextScenariooptional"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.allScenario,
                                                      function(
                                                        scenario,
                                                        index
                                                      ) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: 100 + index,
                                                            domProps: {
                                                              value:
                                                                scenario
                                                                  .scenario
                                                                  .scenarioId
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  scenario
                                                                    .scenario
                                                                    .scenarioName
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                ),
                                                _c("md-radio", {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    type: "radio",
                                                    value: "node"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm
                                                        .userReplyValidateNextType
                                                        .failed,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.userReplyValidateNextType,
                                                        "failed",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "userReplyValidateNextType.failed"
                                                  }
                                                }),
                                                _c(
                                                  "select",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .failed.nextNodeId,
                                                        expression:
                                                          "formInput.userAction.userReply.validate.failed.nextNodeId"
                                                      }
                                                    ],
                                                    staticClass:
                                                      "form-control col-4 ml-2",
                                                    attrs: {
                                                      id:
                                                        "userReplyValidateFailedNode"
                                                    },
                                                    on: {
                                                      focus: function($event) {
                                                        return _vm.onNextScenarioNodeChanged(
                                                          $event
                                                        )
                                                      },
                                                      change: function($event) {
                                                        var $$selectedVal = Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function(o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function(o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .userAction
                                                            .userReply.validate
                                                            .failed,
                                                          "nextNodeId",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c(
                                                      "option",
                                                      {
                                                        attrs: {
                                                          value: "",
                                                          disabled: "",
                                                          selected: ""
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                "__NextNodeoptional"
                                                              )
                                                            ) +
                                                            "\n                        "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      _vm.nextAndSameNodeSelection,
                                                      function(node, index) {
                                                        return _c(
                                                          "option",
                                                          {
                                                            key: index,
                                                            domProps: {
                                                              value: node.nodeId
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                          " +
                                                                _vm._s(
                                                                  node.title.substr(
                                                                    0,
                                                                    25
                                                                  )
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  node.subtitle
                                                                ) +
                                                                "\n                        "
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  ],
                                                  2
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-removevariable",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-removevariable-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("label", [
                                        _vm._v(
                                          " " + _vm._s(_vm.$t("__variable"))
                                        )
                                      ]),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "text-info m-1",
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function($event) {
                                              return _vm.showExistVariable(
                                                "RemoveVariable"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-edit text-info"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "form-row" },
                                        _vm._l(
                                          _vm.formInput.userAction.emptyVariable
                                            .variables,
                                          function(variable, index) {
                                            return _c("div", { key: index }, [
                                              _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-link btn-variable"
                                                },
                                                [_vm._v("$" + _vm._s(variable))]
                                              )
                                            ])
                                          }
                                        ),
                                        0
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-externalapisetting",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-externalapisetting-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("div", { staticClass: "mt-2 mb-3" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(_vm.$t("__ExternalapiName"))
                                          )
                                        ]),
                                        _vm._m(9)
                                      ]),
                                      _c("div", { staticClass: "mt-2 mb-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-check" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.externalApi
                                                      .cros.enable,
                                                  expression:
                                                    "formInput.externalApi.cros.enable"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.formInput.externalApi.cros
                                                    .enable
                                                )
                                                  ? _vm._i(
                                                      _vm.formInput.externalApi
                                                        .cros.enable,
                                                      null
                                                    ) > -1
                                                  : _vm.formInput.externalApi
                                                      .cros.enable
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.formInput.externalApi
                                                        .cros.enable,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .externalApi.cros,
                                                          "enable",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .externalApi.cros,
                                                          "enable",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.formInput.externalApi
                                                        .cros,
                                                      "enable",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label",
                                                attrs: {
                                                  for: "externalapiEnable"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__ExternalapiEnable"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "mt-2 mb-3" }, [
                                        _c(
                                          "div",
                                          { staticClass: "form-check" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.externalApi
                                                      .cros.others
                                                      .orderIdReplace,
                                                  expression:
                                                    "formInput.externalApi.cros.others.orderIdReplace"
                                                }
                                              ],
                                              staticClass: "form-check-input",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.formInput.externalApi.cros
                                                    .others.orderIdReplace
                                                )
                                                  ? _vm._i(
                                                      _vm.formInput.externalApi
                                                        .cros.others
                                                        .orderIdReplace,
                                                      null
                                                    ) > -1
                                                  : _vm.formInput.externalApi
                                                      .cros.others
                                                      .orderIdReplace
                                              },
                                              on: {
                                                change: function($event) {
                                                  var $$a =
                                                      _vm.formInput.externalApi
                                                        .cros.others
                                                        .orderIdReplace,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .externalApi.cros
                                                            .others,
                                                          "orderIdReplace",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .externalApi.cros
                                                            .others,
                                                          "orderIdReplace",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.formInput.externalApi
                                                        .cros.others,
                                                      "orderIdReplace",
                                                      $$c
                                                    )
                                                  }
                                                }
                                              }
                                            }),
                                            _c(
                                              "label",
                                              {
                                                staticClass: "form-check-label"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "__OrderIdReplaceEnable"
                                                      )
                                                    ) +
                                                    "\n                      "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      ]),
                                      _c("div", { staticClass: "mt-2 mb-3" }, [
                                        _c("label", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("__ApiResponseSuccess")
                                            )
                                          )
                                        ]),
                                        _c("div", { staticClass: "form-row" }, [
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.externalApi
                                                      .cros.responseSetting
                                                      .success.scenarioId,
                                                  expression:
                                                    "formInput.externalApi.cros.responseSetting.success.scenarioId"
                                                }
                                              ],
                                              staticClass:
                                                "form-control col-4 ml-2",
                                              on: {
                                                change: [
                                                  function($event) {
                                                    var $$selectedVal = Array.prototype.filter
                                                      .call(
                                                        $event.target.options,
                                                        function(o) {
                                                          return o.selected
                                                        }
                                                      )
                                                      .map(function(o) {
                                                        var val =
                                                          "_value" in o
                                                            ? o._value
                                                            : o.value
                                                        return val
                                                      })
                                                    _vm.$set(
                                                      _vm.formInput.externalApi
                                                        .cros.responseSetting
                                                        .success,
                                                      "scenarioId",
                                                      $event.target.multiple
                                                        ? $$selectedVal
                                                        : $$selectedVal[0]
                                                    )
                                                  },
                                                  function($event) {
                                                    return _vm.onApiResSuccessScenarioChanged(
                                                      $event
                                                    )
                                                  }
                                                ]
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__NextScenariooptional"
                                                        )
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._l(_vm.allScenario, function(
                                                scenario,
                                                index
                                              ) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: 100 + index,
                                                    domProps: {
                                                      value:
                                                        scenario.scenario
                                                          .scenarioId
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                          " +
                                                        _vm._s(
                                                          scenario.scenario
                                                            .scenarioName
                                                        ) +
                                                        "\n                        "
                                                    )
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          ),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.formInput.externalApi
                                                      .cros.responseSetting
                                                      .success.nextNodeId,
                                                  expression:
                                                    "formInput.externalApi.cros.responseSetting.success.nextNodeId"
                                                }
                                              ],
                                              staticClass:
                                                "form-control col-4 ml-2",
                                              on: {
                                                change: function($event) {
                                                  var $$selectedVal = Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function(o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function(o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                  _vm.$set(
                                                    _vm.formInput.externalApi
                                                      .cros.responseSetting
                                                      .success,
                                                    "nextNodeId",
                                                    $event.target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "option",
                                                {
                                                  attrs: {
                                                    value: "",
                                                    disabled: "",
                                                    selected: ""
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                          " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__NextNodeoptional"
                                                        )
                                                      ) +
                                                      "\n                        "
                                                  )
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.userReplyValidateNodes
                                                  .success,
                                                function(node, index) {
                                                  return _c(
                                                    "option",
                                                    {
                                                      key: index,
                                                      domProps: {
                                                        value: node.nodeId
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            node.title.substr(
                                                              0,
                                                              25
                                                            )
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            node.subtitle
                                                          ) +
                                                          "\n                        "
                                                      )
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          )
                                        ])
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2 mb-3" },
                                        [
                                          _c("label", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("__ApiResponseFailed")
                                              )
                                            )
                                          ]),
                                          _vm._l(
                                            _vm.formInput.externalApi.cros
                                              .responseSetting.failed,
                                            function(item, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: index,
                                                  staticClass: "border p-2 mb-2"
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "float-right"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          staticClass:
                                                            "text-danger float-right",
                                                          attrs: {
                                                            href:
                                                              "javascript:void(0)"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.deleteApiResFailedSetting(
                                                                index
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "fas fa-trash text-danger"
                                                          })
                                                        ]
                                                      )
                                                    ]
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row mb-1"
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value: item.error,
                                                              expression:
                                                                "item.error"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-4 ml-2",
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "error",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__SelectErrorCode"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.crosApiErrs,
                                                            function(
                                                              err,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key:
                                                                    "cros-err-" +
                                                                    index,
                                                                  domProps: {
                                                                    value:
                                                                      err.code
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        err.code
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  ),
                                                  item.error !== "default" &&
                                                  item.error !== ""
                                                    ? _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-danger ml-1"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.getCrosErrorMsg(
                                                                item.error
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row mt-2"
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.scenarioId,
                                                              expression:
                                                                "item.scenarioId"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-4 ml-2",
                                                          on: {
                                                            change: [
                                                              function($event) {
                                                                var $$selectedVal = Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function(
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(function(
                                                                    o
                                                                  ) {
                                                                    var val =
                                                                      "_value" in
                                                                      o
                                                                        ? o._value
                                                                        : o.value
                                                                    return val
                                                                  })
                                                                _vm.$set(
                                                                  item,
                                                                  "scenarioId",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                              function($event) {
                                                                return _vm.onApiResFailedScenarioChanged(
                                                                  index
                                                                )
                                                              }
                                                            ]
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__NextScenariooptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm.allScenario,
                                                            function(
                                                              scenario,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key:
                                                                    100 + index,
                                                                  domProps: {
                                                                    value:
                                                                      scenario
                                                                        .scenario
                                                                        .scenarioId
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        scenario
                                                                          .scenario
                                                                          .scenarioName
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      ),
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.nextNodeId,
                                                              expression:
                                                                "item.nextNodeId"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "form-control col-4 ml-2",
                                                          on: {
                                                            change: function(
                                                              $event
                                                            ) {
                                                              var $$selectedVal = Array.prototype.filter
                                                                .call(
                                                                  $event.target
                                                                    .options,
                                                                  function(o) {
                                                                    return o.selected
                                                                  }
                                                                )
                                                                .map(function(
                                                                  o
                                                                ) {
                                                                  var val =
                                                                    "_value" in
                                                                    o
                                                                      ? o._value
                                                                      : o.value
                                                                  return val
                                                                })
                                                              _vm.$set(
                                                                item,
                                                                "nextNodeId",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "option",
                                                            {
                                                              attrs: {
                                                                value: "",
                                                                disabled: "",
                                                                selected: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__NextNodeoptional"
                                                                    )
                                                                  ) +
                                                                  "\n                          "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            _vm
                                                              .userReplyValidateNodes
                                                              .failed[index],
                                                            function(
                                                              node,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "option",
                                                                {
                                                                  key: index,
                                                                  domProps: {
                                                                    value:
                                                                      node.nodeId
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                            " +
                                                                      _vm._s(
                                                                        node.title.substr(
                                                                          0,
                                                                          25
                                                                        )
                                                                      ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        node.subtitle
                                                                      ) +
                                                                      "\n                          "
                                                                  )
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "row pt-4 add-btn-input cursor-pointer",
                                              on: {
                                                click: function($event) {
                                                  return _vm.addNewApiResFailedSetting()
                                                }
                                              }
                                            },
                                            [_vm._m(10)]
                                          )
                                        ],
                                        2
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-textmessage",
                                      role: "tabpanel",
                                      "aria-labelledby":
                                        "v-pills-textmessage-tab"
                                    }
                                  },
                                  [
                                    _c("div", { staticClass: "pt-5" }, [
                                      _c("div", [
                                        _c(
                                          "span",
                                          { staticClass: "text-danger" },
                                          [_vm._v("*")]
                                        ),
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.$t("__requiredItem")) +
                                            "\n                  "
                                        )
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "px-4 mt-2 mb-3" },
                                        [
                                          _c("label", [
                                            _c(
                                              "span",
                                              { staticClass: "text-danger" },
                                              [_vm._v("*")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("__Textcontent"))
                                            )
                                          ]),
                                          _c("textarea", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.formInput.textMsg.text,
                                                expression:
                                                  "formInput.textMsg.text"
                                              }
                                            ],
                                            staticClass: "form-control col-9",
                                            attrs: {
                                              rows: "6",
                                              id: "text-input"
                                            },
                                            domProps: {
                                              value: _vm.formInput.textMsg.text
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.$set(
                                                  _vm.formInput.textMsg,
                                                  "text",
                                                  $event.target.value
                                                )
                                              }
                                            }
                                          })
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "px-4 mt-2 mb-3" },
                                        [
                                          _c("label", [
                                            _vm._v(_vm._s(_vm.$t("__Emoji")))
                                          ]),
                                          _vm.formInput.textMsg.emojis.length ==
                                          0
                                            ? _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "text-info m-1",
                                                      attrs: {
                                                        href:
                                                          "javascript:void(0)"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.addTextMsgEmoji()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-plus text-info"
                                                      })
                                                    ]
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._l(
                                            _vm.formInput.textMsg.emojis,
                                            function(emoji, emojiIndex) {
                                              return _c(
                                                "div",
                                                { key: emojiIndex },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "form-row mt-2"
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "col-1 form-control ml-2"
                                                        },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                emojiIndex + 1
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      ),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              emoji.productId,
                                                            expression:
                                                              "emoji.productId"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "col-4 form-control ml-2",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: _vm.$t(
                                                            "__EmojiProdId"
                                                          )
                                                        },
                                                        domProps: {
                                                          value: emoji.productId
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              emoji,
                                                              "productId",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              emoji.emojiId,
                                                            expression:
                                                              "emoji.emojiId"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "col-3 form-control ml-2",
                                                        attrs: {
                                                          type: "text",
                                                          placeholder: _vm.$t(
                                                            "__EmojiId"
                                                          )
                                                        },
                                                        domProps: {
                                                          value: emoji.emojiId
                                                        },
                                                        on: {
                                                          input: function(
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            ) {
                                                              return
                                                            }
                                                            _vm.$set(
                                                              emoji,
                                                              "emojiId",
                                                              $event.target
                                                                .value
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "div",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm.formInput.textMsg
                                                            .emojis.length > 0
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-danger m-1",
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:void(0)"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.deleteTextMsgEmoji(
                                                                        emojiIndex
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-minus text-danger"
                                                                  })
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          (emojiIndex ==
                                                            _vm.formInput
                                                              .textMsg.emojis
                                                              .length -
                                                              1) &
                                                          (_vm.formInput.textMsg
                                                            .emojis.length <
                                                            20)
                                                            ? _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-info m-1",
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:void(0)"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.addTextMsgEmoji()
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-plus text-info"
                                                                  })
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          )
                                        ],
                                        2
                                      )
                                    ])
                                  ]
                                ),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig" ||
                                _vm.accountType == "webbot"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-pills-quickreply",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-pills-quickreply-tab"
                                        }
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "pt-5" },
                                          [
                                            _c("label", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("__QuickReplyAction")
                                                )
                                              )
                                            ]),
                                            _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "text-danger" },
                                                [_vm._v("*")]
                                              ),
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.$t("__requiredItem")
                                                  ) +
                                                  "\n                  "
                                              )
                                            ]),
                                            _vm._l(
                                              _vm.formInput.quickReply.items,
                                              function(item, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "border p-2 mb-2"
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "float-right"
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticClass:
                                                              "text-danger float-right",
                                                            attrs: {
                                                              href:
                                                                "javascript:void(0)"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.deleteQuickReplyAction(
                                                                  index
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-trash text-danger"
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c("br"),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "form-row px-4 pt-4"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-4"
                                                          },
                                                          [
                                                            _c("label", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                              _vm._v(
                                                                "\n                          " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__QuickReplyAction"
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item
                                                                        .action
                                                                        .type,
                                                                    expression:
                                                                      "item.action.type"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "quickReplyType"
                                                                },
                                                                on: {
                                                                  change: [
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal = Array.prototype.filter
                                                                        .call(
                                                                          $event
                                                                            .target
                                                                            .options,
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            return o.selected
                                                                          }
                                                                        )
                                                                        .map(
                                                                          function(
                                                                            o
                                                                          ) {
                                                                            var val =
                                                                              "_value" in
                                                                              o
                                                                                ? o._value
                                                                                : o.value
                                                                            return val
                                                                          }
                                                                        )
                                                                      _vm.$set(
                                                                        item.action,
                                                                        "type",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                    function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.onQuickReplyActionChanged(
                                                                        $event,
                                                                        index
                                                                      )
                                                                    }
                                                                  ]
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "postback"
                                                                    }
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "__QuickReplyPostback"
                                                                          )
                                                                        ) +
                                                                        "\n                          "
                                                                    )
                                                                  ]
                                                                ),
                                                                _vm.accountType ==
                                                                  "line" ||
                                                                _vm.accountType ==
                                                                  "webbot"
                                                                  ? _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "message"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__QuickReplyMessage"
                                                                              )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.accountType ==
                                                                  "line" ||
                                                                _vm.accountType ==
                                                                  "webbot"
                                                                  ? _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "uri"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__QuickReplyUri"
                                                                              )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                                _vm.accountType ==
                                                                "line"
                                                                  ? _c(
                                                                      "option",
                                                                      {
                                                                        attrs: {
                                                                          value:
                                                                            "datetimepicker"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                            " +
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__QuickReplyTimepicker"
                                                                              )
                                                                            ) +
                                                                            "\n                          "
                                                                        )
                                                                      ]
                                                                    )
                                                                  : _vm._e()
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group col-4"
                                                          },
                                                          [
                                                            _c("label", [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [_vm._v("*")]
                                                              ),
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__label"
                                                                    )
                                                                  )
                                                              )
                                                            ]),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    item.action
                                                                      .label,
                                                                  expression:
                                                                    "item.action.label"
                                                                }
                                                              ],
                                                              staticClass:
                                                                "form-control",
                                                              attrs: {
                                                                type: "text"
                                                              },
                                                              domProps: {
                                                                value:
                                                                  item.action
                                                                    .label
                                                              },
                                                              on: {
                                                                input: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    $event
                                                                      .target
                                                                      .composing
                                                                  ) {
                                                                    return
                                                                  }
                                                                  _vm.$set(
                                                                    item.action,
                                                                    "label",
                                                                    $event
                                                                      .target
                                                                      .value
                                                                  )
                                                                }
                                                              }
                                                            })
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    item.action.type ===
                                                    "postback"
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__label"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-row"
                                                                },
                                                                [
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            item
                                                                              .action
                                                                              .tag,
                                                                          expression:
                                                                            "item.action.tag"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "col-3 form-control ml-2",
                                                                      attrs: {
                                                                        id:
                                                                          "quickReplyTag"
                                                                      },
                                                                      on: {
                                                                        focus: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onQuickReplyActionChanged(
                                                                            $event,
                                                                            index
                                                                          )
                                                                        },
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            item.action,
                                                                            "tag",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              "",
                                                                            selected:
                                                                              ""
                                                                          },
                                                                          domProps: {
                                                                            value: {
                                                                              color:
                                                                                "",
                                                                              name:
                                                                                ""
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "__TagSelectOptional"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .nodeData
                                                                          .tags,
                                                                        function(
                                                                          tag,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                100 +
                                                                                index,
                                                                              domProps: {
                                                                                value: tag
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    tag.name
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            item
                                                                              .action
                                                                              .tagValue,
                                                                          expression:
                                                                            "item.action.tagValue"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "col-3 form-control ml-2",
                                                                      attrs: {
                                                                        "aria-placeholder": _vm.$t(
                                                                          "__fontcolor"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            item.action,
                                                                            "tagValue",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              "",
                                                                            disabled:
                                                                              "",
                                                                            selected:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "__TagValueSelectOptional"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.valuesOfTag(
                                                                          item
                                                                            .action
                                                                            .tag
                                                                            .color,
                                                                          item
                                                                            .action
                                                                            .tag
                                                                            .name
                                                                        ),
                                                                        function(
                                                                          value,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                100 +
                                                                                index,
                                                                              domProps: {
                                                                                value: value
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    value
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__variable"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              item.action
                                                                .userVars
                                                                .length == 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ml-1"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-info m-1",
                                                                          attrs: {
                                                                            href:
                                                                              "javascript:void(0)"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.addQuickReplyVar(
                                                                                index
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-plus text-info"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._l(
                                                                item.action
                                                                  .userVars,
                                                                function(
                                                                  varItem,
                                                                  varIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: varIndex
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-row mt-2"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "select",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    varItem.variable,
                                                                                  expression:
                                                                                    "varItem.variable"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "col-3 form-control ml-2",
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$selectedVal = Array.prototype.filter
                                                                                    .call(
                                                                                      $event
                                                                                        .target
                                                                                        .options,
                                                                                      function(
                                                                                        o
                                                                                      ) {
                                                                                        return o.selected
                                                                                      }
                                                                                    )
                                                                                    .map(
                                                                                      function(
                                                                                        o
                                                                                      ) {
                                                                                        var val =
                                                                                          "_value" in
                                                                                          o
                                                                                            ? o._value
                                                                                            : o.value
                                                                                        return val
                                                                                      }
                                                                                    )
                                                                                  _vm.$set(
                                                                                    varItem,
                                                                                    "variable",
                                                                                    $event
                                                                                      .target
                                                                                      .multiple
                                                                                      ? $$selectedVal
                                                                                      : $$selectedVal[0]
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "option",
                                                                                {
                                                                                  attrs: {
                                                                                    value:
                                                                                      "",
                                                                                    disabled:
                                                                                      "",
                                                                                    selected:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "__UserVariableSelectOptional"
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._l(
                                                                                _vm.allVariables,
                                                                                function(
                                                                                  value,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "option",
                                                                                    {
                                                                                      key:
                                                                                        100 +
                                                                                        index,
                                                                                      domProps: {
                                                                                        value: value
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            value
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              )
                                                                            ],
                                                                            2
                                                                          ),
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    varItem.value,
                                                                                  expression:
                                                                                    "varItem.value"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "col-3 form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "text"
                                                                              },
                                                                              domProps: {
                                                                                value:
                                                                                  varItem.value
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    varItem,
                                                                                    "value",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ml-1"
                                                                            },
                                                                            [
                                                                              item
                                                                                .action
                                                                                .userVars
                                                                                .length >
                                                                              0
                                                                                ? _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger m-1",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "javascript:void(0)"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deleteQuickReplyVar(
                                                                                            index,
                                                                                            varIndex
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fas fa-minus text-danger"
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ml-1"
                                                                            },
                                                                            [
                                                                              varIndex ==
                                                                              item
                                                                                .action
                                                                                .userVars
                                                                                .length -
                                                                                1
                                                                                ? _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-info m-1",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "javascript:void(0)"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addQuickReplyVar(
                                                                                            index
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fas fa-plus text-info"
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row px-4 mt-3"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-12 mb-0"
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "*"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "__buttonTappedAction"
                                                                          )
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-auto mb-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-row col-auto"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-6"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "__jumpToSpecificScenario"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-row align-items-center"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "md-radio",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-3",
                                                                                      attrs: {
                                                                                        type:
                                                                                          "radio",
                                                                                        value:
                                                                                          "quickReplyNextScenario"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .action
                                                                                            .nodeType,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nodeType",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.action.nodeType"
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "select",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            item
                                                                                              .action
                                                                                              .nextScenario,
                                                                                          expression:
                                                                                            "item.action.nextScenario"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "form-control col-9",
                                                                                      attrs: {
                                                                                        id:
                                                                                          "quickReplyNextScenario"
                                                                                      },
                                                                                      on: {
                                                                                        focus: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.onQuickReplyActionChanged(
                                                                                            $event,
                                                                                            index
                                                                                          )
                                                                                        },
                                                                                        change: function(
                                                                                          $event
                                                                                        ) {
                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                            .call(
                                                                                              $event
                                                                                                .target
                                                                                                .options,
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                return o.selected
                                                                                              }
                                                                                            )
                                                                                            .map(
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                var val =
                                                                                                  "_value" in
                                                                                                  o
                                                                                                    ? o._value
                                                                                                    : o.value
                                                                                                return val
                                                                                              }
                                                                                            )
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nextScenario",
                                                                                            $event
                                                                                              .target
                                                                                              .multiple
                                                                                              ? $$selectedVal
                                                                                              : $$selectedVal[0]
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs: {
                                                                                            value:
                                                                                              "",
                                                                                            disabled:
                                                                                              "",
                                                                                            selected:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                      " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "__NextScenariooptional"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                    "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.allScenario,
                                                                                        function(
                                                                                          scenario,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "option",
                                                                                            {
                                                                                              key:
                                                                                                100 +
                                                                                                index,
                                                                                              domProps: {
                                                                                                value:
                                                                                                  scenario
                                                                                                    .scenario
                                                                                                    .scenarioId
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    scenario
                                                                                                      .scenario
                                                                                                      .scenarioName
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-6"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "__jumpToSpecificNode"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-row align-items-center"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "md-radio",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-3",
                                                                                      attrs: {
                                                                                        type:
                                                                                          "radio",
                                                                                        value:
                                                                                          "quickReplyNextNode"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .action
                                                                                            .nodeType,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nodeType",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.action.nodeType"
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "select",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            item
                                                                                              .action
                                                                                              .nextNode,
                                                                                          expression:
                                                                                            "item.action.nextNode"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "form-control col-9",
                                                                                      attrs: {
                                                                                        id:
                                                                                          "quickReplyNextNode"
                                                                                      },
                                                                                      on: {
                                                                                        focus: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.onQuickReplyActionChanged(
                                                                                            $event,
                                                                                            index
                                                                                          )
                                                                                        },
                                                                                        change: function(
                                                                                          $event
                                                                                        ) {
                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                            .call(
                                                                                              $event
                                                                                                .target
                                                                                                .options,
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                return o.selected
                                                                                              }
                                                                                            )
                                                                                            .map(
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                var val =
                                                                                                  "_value" in
                                                                                                  o
                                                                                                    ? o._value
                                                                                                    : o.value
                                                                                                return val
                                                                                              }
                                                                                            )
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nextNode",
                                                                                            $event
                                                                                              .target
                                                                                              .multiple
                                                                                              ? $$selectedVal
                                                                                              : $$selectedVal[0]
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs: {
                                                                                            value:
                                                                                              "",
                                                                                            disabled:
                                                                                              "",
                                                                                            selected:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                      " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "__NextNodeoptional"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                    "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.nextNodeSelection,
                                                                                        function(
                                                                                          node,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "option",
                                                                                            {
                                                                                              key: index,
                                                                                              domProps: {
                                                                                                value:
                                                                                                  node.nodeId
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    node.title.substr(
                                                                                                      0,
                                                                                                      25
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    node.subtitle
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    item.action.type ===
                                                    "message"
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger"
                                                                  },
                                                                  [_vm._v("*")]
                                                                ),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__Textcontent"
                                                                      )
                                                                    )
                                                                )
                                                              ]),
                                                              _c("textarea", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item
                                                                        .action
                                                                        .text,
                                                                    expression:
                                                                      "item.action.text"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control col-9",
                                                                domProps: {
                                                                  value:
                                                                    item.action
                                                                      .text
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      item.action,
                                                                      "text",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    item.action.type === "uri"
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger"
                                                                  },
                                                                  [_vm._v("*")]
                                                                ),
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "__jumpToSpecificUrl"
                                                                      )
                                                                    )
                                                                )
                                                              ]),
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name:
                                                                      "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item
                                                                        .action
                                                                        .uri,
                                                                    expression:
                                                                      "item.action.uri"
                                                                  }
                                                                ],
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  type: "text",
                                                                  placeholder: _vm.$t(
                                                                    "__urlPlaceHolder"
                                                                  )
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    item.action
                                                                      .uri
                                                                },
                                                                on: {
                                                                  input: function(
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    ) {
                                                                      return
                                                                    }
                                                                    _vm.$set(
                                                                      item.action,
                                                                      "uri",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  }
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e(),
                                                    item.action.type ===
                                                    "datetimepicker"
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row px-4 pt-4"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-4"
                                                                },
                                                                [
                                                                  _vm._m(
                                                                    11,
                                                                    true
                                                                  ),
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            item
                                                                              .action
                                                                              .mode,
                                                                          expression:
                                                                            "item.action.mode"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "form-control",
                                                                      on: {
                                                                        change: [
                                                                          function(
                                                                            $event
                                                                          ) {
                                                                            var $$selectedVal = Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function(
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                            _vm.$set(
                                                                              item.action,
                                                                              "mode",
                                                                              $event
                                                                                .target
                                                                                .multiple
                                                                                ? $$selectedVal
                                                                                : $$selectedVal[0]
                                                                            )
                                                                          },
                                                                          function(
                                                                            $event
                                                                          ) {
                                                                            return _vm.onQuickReplyDtPickerTypechanged(
                                                                              index,
                                                                              true
                                                                            )
                                                                          }
                                                                        ]
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              "date"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Date"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              "time"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Time"
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              "datetime"
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "Datetime"
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-4"
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "__timepickerVariable"
                                                                        )
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            item
                                                                              .action
                                                                              .varName,
                                                                          expression:
                                                                            "item.action.varName"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "form-control",
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            item.action,
                                                                            "varName",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              "",
                                                                            selected:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "__UserVariableSelectOptional"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.allVariables,
                                                                        function(
                                                                          value,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                100 +
                                                                                index,
                                                                              domProps: {
                                                                                value: value
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    value
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__label"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-row"
                                                                },
                                                                [
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            item
                                                                              .action
                                                                              .tag,
                                                                          expression:
                                                                            "item.action.tag"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "col-3 form-control ml-2",
                                                                      attrs: {
                                                                        id:
                                                                          "quickReplyTag"
                                                                      },
                                                                      on: {
                                                                        focus: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.onQuickReplyActionChanged(
                                                                            $event,
                                                                            index
                                                                          )
                                                                        },
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            item.action,
                                                                            "tag",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            disabled:
                                                                              "",
                                                                            selected:
                                                                              ""
                                                                          },
                                                                          domProps: {
                                                                            value: {
                                                                              color:
                                                                                "",
                                                                              name:
                                                                                ""
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "__TagSelectOptional"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm
                                                                          .nodeData
                                                                          .tags,
                                                                        function(
                                                                          tag,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                100 +
                                                                                index,
                                                                              domProps: {
                                                                                value: tag
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    tag.name
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  ),
                                                                  _c(
                                                                    "select",
                                                                    {
                                                                      directives: [
                                                                        {
                                                                          name:
                                                                            "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            item
                                                                              .action
                                                                              .tagValue,
                                                                          expression:
                                                                            "item.action.tagValue"
                                                                        }
                                                                      ],
                                                                      staticClass:
                                                                        "col-3 form-control ml-2",
                                                                      attrs: {
                                                                        "aria-placeholder": _vm.$t(
                                                                          "__fontcolor"
                                                                        )
                                                                      },
                                                                      on: {
                                                                        change: function(
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal = Array.prototype.filter
                                                                            .call(
                                                                              $event
                                                                                .target
                                                                                .options,
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                return o.selected
                                                                              }
                                                                            )
                                                                            .map(
                                                                              function(
                                                                                o
                                                                              ) {
                                                                                var val =
                                                                                  "_value" in
                                                                                  o
                                                                                    ? o._value
                                                                                    : o.value
                                                                                return val
                                                                              }
                                                                            )
                                                                          _vm.$set(
                                                                            item.action,
                                                                            "tagValue",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "option",
                                                                        {
                                                                          attrs: {
                                                                            value:
                                                                              "",
                                                                            disabled:
                                                                              "",
                                                                            selected:
                                                                              ""
                                                                          }
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              " +
                                                                              _vm._s(
                                                                                _vm.$t(
                                                                                  "__TagValueSelectOptional"
                                                                                )
                                                                              ) +
                                                                              "\n                            "
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _vm._l(
                                                                        _vm.valuesOfTag(
                                                                          item
                                                                            .action
                                                                            .tag
                                                                            .color,
                                                                          item
                                                                            .action
                                                                            .tag
                                                                            .name
                                                                        ),
                                                                        function(
                                                                          value,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "option",
                                                                            {
                                                                              key:
                                                                                100 +
                                                                                index,
                                                                              domProps: {
                                                                                value: value
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                              " +
                                                                                  _vm._s(
                                                                                    value
                                                                                  ) +
                                                                                  "\n                            "
                                                                              )
                                                                            ]
                                                                          )
                                                                        }
                                                                      )
                                                                    ],
                                                                    2
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__variable"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              item.action
                                                                .userVars
                                                                .length == 0
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "ml-1"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-info m-1",
                                                                          attrs: {
                                                                            href:
                                                                              "javascript:void(0)"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.addQuickReplyVar(
                                                                                index
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-plus text-info"
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm._l(
                                                                item.action
                                                                  .userVars,
                                                                function(
                                                                  varItem,
                                                                  varIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: varIndex
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-row mt-2"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "select",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    varItem.variable,
                                                                                  expression:
                                                                                    "varItem.variable"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "col-3 form-control ml-2",
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$selectedVal = Array.prototype.filter
                                                                                    .call(
                                                                                      $event
                                                                                        .target
                                                                                        .options,
                                                                                      function(
                                                                                        o
                                                                                      ) {
                                                                                        return o.selected
                                                                                      }
                                                                                    )
                                                                                    .map(
                                                                                      function(
                                                                                        o
                                                                                      ) {
                                                                                        var val =
                                                                                          "_value" in
                                                                                          o
                                                                                            ? o._value
                                                                                            : o.value
                                                                                        return val
                                                                                      }
                                                                                    )
                                                                                  _vm.$set(
                                                                                    varItem,
                                                                                    "variable",
                                                                                    $event
                                                                                      .target
                                                                                      .multiple
                                                                                      ? $$selectedVal
                                                                                      : $$selectedVal[0]
                                                                                  )
                                                                                }
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "option",
                                                                                {
                                                                                  attrs: {
                                                                                    value:
                                                                                      "",
                                                                                    disabled:
                                                                                      "",
                                                                                    selected:
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                " +
                                                                                      _vm._s(
                                                                                        _vm.$t(
                                                                                          "__UserVariableSelectOptional"
                                                                                        )
                                                                                      ) +
                                                                                      "\n                              "
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._l(
                                                                                _vm.allVariables,
                                                                                function(
                                                                                  value,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "option",
                                                                                    {
                                                                                      key:
                                                                                        100 +
                                                                                        index,
                                                                                      domProps: {
                                                                                        value: value
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                " +
                                                                                          _vm._s(
                                                                                            value
                                                                                          ) +
                                                                                          "\n                              "
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                }
                                                                              )
                                                                            ],
                                                                            2
                                                                          ),
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    varItem.value,
                                                                                  expression:
                                                                                    "varItem.value"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "col-3 form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "text"
                                                                              },
                                                                              domProps: {
                                                                                value:
                                                                                  varItem.value
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    varItem,
                                                                                    "value",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ml-1"
                                                                            },
                                                                            [
                                                                              item
                                                                                .action
                                                                                .userVars
                                                                                .length >
                                                                              0
                                                                                ? _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-danger m-1",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "javascript:void(0)"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deleteQuickReplyVar(
                                                                                            index,
                                                                                            varIndex
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fas fa-minus text-danger"
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "ml-1"
                                                                            },
                                                                            [
                                                                              varIndex ==
                                                                              item
                                                                                .action
                                                                                .userVars
                                                                                .length -
                                                                                1
                                                                                ? _c(
                                                                                    "a",
                                                                                    {
                                                                                      staticClass:
                                                                                        "text-info m-1",
                                                                                      attrs: {
                                                                                        href:
                                                                                          "javascript:void(0)"
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.addQuickReplyVar(
                                                                                            index
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "i",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fas fa-plus text-info"
                                                                                        }
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                : _vm._e()
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "__timepickerInitialTime"
                                                                    )
                                                                  )
                                                                )
                                                              ]),
                                                              _c(
                                                                "a",
                                                                {
                                                                  staticClass:
                                                                    "text-danger m-1",
                                                                  attrs: {
                                                                    href:
                                                                      "javascript:void(0)"
                                                                  },
                                                                  on: {
                                                                    click: function(
                                                                      $event
                                                                    ) {
                                                                      return _vm.clearQuickReplyDtPickerDate(
                                                                        index,
                                                                        "initial"
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                [
                                                                  _c("i", {
                                                                    staticClass:
                                                                      "fas fa-undo text-success"
                                                                  })
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-row"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4"
                                                                    },
                                                                    [
                                                                      item
                                                                        .action
                                                                        .dtPickerType ===
                                                                      "checkbox"
                                                                        ? _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .initial,
                                                                                  expression:
                                                                                    "item.action.initial"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "checkbox"
                                                                              },
                                                                              domProps: {
                                                                                checked: Array.isArray(
                                                                                  item
                                                                                    .action
                                                                                    .initial
                                                                                )
                                                                                  ? _vm._i(
                                                                                      item
                                                                                        .action
                                                                                        .initial,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : item
                                                                                      .action
                                                                                      .initial
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      item
                                                                                        .action
                                                                                        .initial,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c = $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v = null,
                                                                                      $$i = _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          item.action,
                                                                                          "initial",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          item.action,
                                                                                          "initial",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      item.action,
                                                                                      "initial",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : item
                                                                            .action
                                                                            .dtPickerType ===
                                                                          "radio"
                                                                        ? _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .initial,
                                                                                  expression:
                                                                                    "item.action.initial"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "radio"
                                                                              },
                                                                              domProps: {
                                                                                checked: _vm._q(
                                                                                  item
                                                                                    .action
                                                                                    .initial,
                                                                                  null
                                                                                )
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    item.action,
                                                                                    "initial",
                                                                                    null
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .initial,
                                                                                  expression:
                                                                                    "item.action.initial"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  item
                                                                                    .action
                                                                                    .dtPickerType
                                                                              },
                                                                              domProps: {
                                                                                value:
                                                                                  item
                                                                                    .action
                                                                                    .initial
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    item.action,
                                                                                    "initial",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "px-4 mt-2 mb-3"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-row"
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__timepickerMinTime"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-danger m-1",
                                                                          attrs: {
                                                                            href:
                                                                              "javascript:void(0)"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.clearQuickReplyDtPickerDate(
                                                                                index,
                                                                                "min"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-undo text-success"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      item
                                                                        .action
                                                                        .dtPickerType ===
                                                                      "checkbox"
                                                                        ? _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .min,
                                                                                  expression:
                                                                                    "item.action.min"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "checkbox"
                                                                              },
                                                                              domProps: {
                                                                                checked: Array.isArray(
                                                                                  item
                                                                                    .action
                                                                                    .min
                                                                                )
                                                                                  ? _vm._i(
                                                                                      item
                                                                                        .action
                                                                                        .min,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : item
                                                                                      .action
                                                                                      .min
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      item
                                                                                        .action
                                                                                        .min,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c = $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v = null,
                                                                                      $$i = _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          item.action,
                                                                                          "min",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          item.action,
                                                                                          "min",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      item.action,
                                                                                      "min",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : item
                                                                            .action
                                                                            .dtPickerType ===
                                                                          "radio"
                                                                        ? _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .min,
                                                                                  expression:
                                                                                    "item.action.min"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "radio"
                                                                              },
                                                                              domProps: {
                                                                                checked: _vm._q(
                                                                                  item
                                                                                    .action
                                                                                    .min,
                                                                                  null
                                                                                )
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    item.action,
                                                                                    "min",
                                                                                    null
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .min,
                                                                                  expression:
                                                                                    "item.action.min"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  item
                                                                                    .action
                                                                                    .dtPickerType
                                                                              },
                                                                              domProps: {
                                                                                value:
                                                                                  item
                                                                                    .action
                                                                                    .min
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    item.action,
                                                                                    "min",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-4"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "label",
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.$t(
                                                                                "__timepickerMaxTime"
                                                                              )
                                                                            )
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          staticClass:
                                                                            "text-danger m-1",
                                                                          attrs: {
                                                                            href:
                                                                              "javascript:void(0)"
                                                                          },
                                                                          on: {
                                                                            click: function(
                                                                              $event
                                                                            ) {
                                                                              return _vm.clearQuickReplyDtPickerDate(
                                                                                index,
                                                                                "max"
                                                                              )
                                                                            }
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "fas fa-undo text-success"
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      item
                                                                        .action
                                                                        .dtPickerType ===
                                                                      "checkbox"
                                                                        ? _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .max,
                                                                                  expression:
                                                                                    "item.action.max"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "checkbox"
                                                                              },
                                                                              domProps: {
                                                                                checked: Array.isArray(
                                                                                  item
                                                                                    .action
                                                                                    .max
                                                                                )
                                                                                  ? _vm._i(
                                                                                      item
                                                                                        .action
                                                                                        .max,
                                                                                      null
                                                                                    ) >
                                                                                    -1
                                                                                  : item
                                                                                      .action
                                                                                      .max
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      item
                                                                                        .action
                                                                                        .max,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c = $$el.checked
                                                                                      ? true
                                                                                      : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v = null,
                                                                                      $$i = _vm._i(
                                                                                        $$a,
                                                                                        $$v
                                                                                      )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          item.action,
                                                                                          "max",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          item.action,
                                                                                          "max",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      item.action,
                                                                                      "max",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : item
                                                                            .action
                                                                            .dtPickerType ===
                                                                          "radio"
                                                                        ? _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .max,
                                                                                  expression:
                                                                                    "item.action.max"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  "radio"
                                                                              },
                                                                              domProps: {
                                                                                checked: _vm._q(
                                                                                  item
                                                                                    .action
                                                                                    .max,
                                                                                  null
                                                                                )
                                                                              },
                                                                              on: {
                                                                                change: function(
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    item.action,
                                                                                    "max",
                                                                                    null
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                        : _c(
                                                                            "input",
                                                                            {
                                                                              directives: [
                                                                                {
                                                                                  name:
                                                                                    "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item
                                                                                      .action
                                                                                      .max,
                                                                                  expression:
                                                                                    "item.action.max"
                                                                                }
                                                                              ],
                                                                              staticClass:
                                                                                "form-control ml-2",
                                                                              attrs: {
                                                                                type:
                                                                                  item
                                                                                    .action
                                                                                    .dtPickerType
                                                                              },
                                                                              domProps: {
                                                                                value:
                                                                                  item
                                                                                    .action
                                                                                    .max
                                                                              },
                                                                              on: {
                                                                                input: function(
                                                                                  $event
                                                                                ) {
                                                                                  if (
                                                                                    $event
                                                                                      .target
                                                                                      .composing
                                                                                  ) {
                                                                                    return
                                                                                  }
                                                                                  _vm.$set(
                                                                                    item.action,
                                                                                    "max",
                                                                                    $event
                                                                                      .target
                                                                                      .value
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-row px-4 mt-3"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "form-group col-12 mb-0"
                                                                },
                                                                [
                                                                  _c("label", [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "*"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _vm._v(
                                                                      "\n                            " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "__buttonTappedAction"
                                                                          )
                                                                        )
                                                                    )
                                                                  ]),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "form-group col-auto mb-0"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-row col-auto"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-6"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "__jumpToSpecificScenario"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-row align-items-center"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "md-radio",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-3",
                                                                                      attrs: {
                                                                                        type:
                                                                                          "radio",
                                                                                        value:
                                                                                          "quickReplyNextScenario"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .action
                                                                                            .nodeType,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nodeType",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.action.nodeType"
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "select",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            item
                                                                                              .action
                                                                                              .nextScenario,
                                                                                          expression:
                                                                                            "item.action.nextScenario"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "form-control col-9",
                                                                                      attrs: {
                                                                                        id:
                                                                                          "quickReplyNextScenario"
                                                                                      },
                                                                                      on: {
                                                                                        focus: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.onQuickReplyActionChanged(
                                                                                            $event,
                                                                                            index
                                                                                          )
                                                                                        },
                                                                                        change: function(
                                                                                          $event
                                                                                        ) {
                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                            .call(
                                                                                              $event
                                                                                                .target
                                                                                                .options,
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                return o.selected
                                                                                              }
                                                                                            )
                                                                                            .map(
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                var val =
                                                                                                  "_value" in
                                                                                                  o
                                                                                                    ? o._value
                                                                                                    : o.value
                                                                                                return val
                                                                                              }
                                                                                            )
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nextScenario",
                                                                                            $event
                                                                                              .target
                                                                                              .multiple
                                                                                              ? $$selectedVal
                                                                                              : $$selectedVal[0]
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs: {
                                                                                            value:
                                                                                              "",
                                                                                            disabled:
                                                                                              "",
                                                                                            selected:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                      " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "__NextScenariooptional"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                    "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.allScenario,
                                                                                        function(
                                                                                          scenario,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "option",
                                                                                            {
                                                                                              key:
                                                                                                100 +
                                                                                                index,
                                                                                              domProps: {
                                                                                                value:
                                                                                                  scenario
                                                                                                    .scenario
                                                                                                    .scenarioId
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    scenario
                                                                                                      .scenario
                                                                                                      .scenarioName
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "form-group col-6"
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "__jumpToSpecificNode"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "form-row align-items-center"
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "md-radio",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-3",
                                                                                      attrs: {
                                                                                        type:
                                                                                          "radio",
                                                                                        value:
                                                                                          "quickReplyNextNode"
                                                                                      },
                                                                                      model: {
                                                                                        value:
                                                                                          item
                                                                                            .action
                                                                                            .nodeType,
                                                                                        callback: function(
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nodeType",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                        expression:
                                                                                          "item.action.nodeType"
                                                                                      }
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "select",
                                                                                    {
                                                                                      directives: [
                                                                                        {
                                                                                          name:
                                                                                            "model",
                                                                                          rawName:
                                                                                            "v-model",
                                                                                          value:
                                                                                            item
                                                                                              .action
                                                                                              .nextNode,
                                                                                          expression:
                                                                                            "item.action.nextNode"
                                                                                        }
                                                                                      ],
                                                                                      staticClass:
                                                                                        "form-control col-9",
                                                                                      attrs: {
                                                                                        id:
                                                                                          "quickReplyNextNode"
                                                                                      },
                                                                                      on: {
                                                                                        focus: function(
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.onQuickReplyActionChanged(
                                                                                            $event,
                                                                                            index
                                                                                          )
                                                                                        },
                                                                                        change: function(
                                                                                          $event
                                                                                        ) {
                                                                                          var $$selectedVal = Array.prototype.filter
                                                                                            .call(
                                                                                              $event
                                                                                                .target
                                                                                                .options,
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                return o.selected
                                                                                              }
                                                                                            )
                                                                                            .map(
                                                                                              function(
                                                                                                o
                                                                                              ) {
                                                                                                var val =
                                                                                                  "_value" in
                                                                                                  o
                                                                                                    ? o._value
                                                                                                    : o.value
                                                                                                return val
                                                                                              }
                                                                                            )
                                                                                          _vm.$set(
                                                                                            item.action,
                                                                                            "nextNode",
                                                                                            $event
                                                                                              .target
                                                                                              .multiple
                                                                                              ? $$selectedVal
                                                                                              : $$selectedVal[0]
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs: {
                                                                                            value:
                                                                                              "",
                                                                                            disabled:
                                                                                              "",
                                                                                            selected:
                                                                                              ""
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                      " +
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "__NextNodeoptional"
                                                                                                )
                                                                                              ) +
                                                                                              "\n                                    "
                                                                                          )
                                                                                        ]
                                                                                      ),
                                                                                      _vm._l(
                                                                                        _vm.nextNodeSelection,
                                                                                        function(
                                                                                          node,
                                                                                          index
                                                                                        ) {
                                                                                          return _c(
                                                                                            "option",
                                                                                            {
                                                                                              key: index,
                                                                                              domProps: {
                                                                                                value:
                                                                                                  node.nodeId
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    node.title.substr(
                                                                                                      0,
                                                                                                      25
                                                                                                    )
                                                                                                  ) +
                                                                                                  "\n                                      " +
                                                                                                  _vm._s(
                                                                                                    node.subtitle
                                                                                                  ) +
                                                                                                  "\n                                    "
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        }
                                                                                      )
                                                                                    ],
                                                                                    2
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ]
                                                )
                                              }
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row pt-4 add-btn-input cursor-pointer",
                                                on: {
                                                  click: function($event) {
                                                    return _vm.addQuickReplyAction()
                                                  }
                                                }
                                              },
                                              [_vm._m(12)]
                                            )
                                          ],
                                          2
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "tab-pane fade",
                                    attrs: {
                                      id: "v-pills-form",
                                      role: "tabpanel",
                                      "aria-labelledby": "v-pills-form-tab"
                                    }
                                  },
                                  [
                                    _c("var-form-setting", {
                                      attrs: {
                                        allVarForms: _vm.allVarForms,
                                        varForms: _vm.formInput.varForms
                                      },
                                      on: {
                                        showCreateFormModal:
                                          _vm.showCreateFormModal,
                                        update: _vm.updateFormValue,
                                        deleteForm: _vm.deleteForm
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _vm.accountType == "line" ||
                                _vm.accountType == "fb" ||
                                _vm.accountType == "ig"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "tab-pane fade",
                                        attrs: {
                                          id: "v-datetime-trigger",
                                          role: "tabpanel",
                                          "aria-labelledby":
                                            "v-datetime-trigger-tab"
                                        }
                                      },
                                      [
                                        _c("div", { staticClass: "pt-5" }, [
                                          _c(
                                            "div",
                                            { staticClass: "form-check mt-2" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.formInput
                                                        .datetimeTrigger.enable,
                                                    expression:
                                                      "formInput.datetimeTrigger.enable"
                                                  }
                                                ],
                                                staticClass: "form-check-input",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.formInput
                                                      .datetimeTrigger.enable
                                                  )
                                                    ? _vm._i(
                                                        _vm.formInput
                                                          .datetimeTrigger
                                                          .enable,
                                                        null
                                                      ) > -1
                                                    : _vm.formInput
                                                        .datetimeTrigger.enable
                                                },
                                                on: {
                                                  change: function($event) {
                                                    var $$a =
                                                        _vm.formInput
                                                          .datetimeTrigger
                                                          .enable,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.formInput
                                                              .datetimeTrigger,
                                                            "enable",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.formInput
                                                              .datetimeTrigger,
                                                            "enable",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.formInput
                                                          .datetimeTrigger,
                                                        "enable",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "form-check-label"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__DateTimeTriggerEnable"
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "form-row px-4" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-4"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__DateTimeTriggerStart"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formInput
                                                            .datetimeTrigger
                                                            .startTime,
                                                        expression:
                                                          "formInput.datetimeTrigger.startTime"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "datetime-local"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.formInput
                                                          .datetimeTrigger
                                                          .startTime
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .datetimeTrigger,
                                                          "startTime",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "form-group col-4"
                                                },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "__DateTimeTriggerEnd"
                                                        )
                                                      )
                                                    )
                                                  ]),
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.formInput
                                                            .datetimeTrigger
                                                            .endTime,
                                                        expression:
                                                          "formInput.datetimeTrigger.endTime"
                                                      }
                                                    ],
                                                    staticClass: "form-control",
                                                    attrs: {
                                                      type: "datetime-local"
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.formInput
                                                          .datetimeTrigger
                                                          .endTime
                                                    },
                                                    on: {
                                                      input: function($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        ) {
                                                          return
                                                        }
                                                        _vm.$set(
                                                          _vm.formInput
                                                            .datetimeTrigger,
                                                          "endTime",
                                                          $event.target.value
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c("div", { staticClass: "modal-footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary",
                        staticStyle: { width: "100%" },
                        attrs: {
                          id: "addNode",
                          type: "button",
                          "data-toggle": "modal",
                          "data-translate": "_confirm.node"
                        },
                        on: {
                          click: function($event) {
                            return _vm.submitNode()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.$t("__determine")) +
                            "\n          "
                        )
                      ]
                    )
                  ])
                ]
              )
            ]
          )
        ]
      ),
      _c("create-variable-modal", { on: { newVariable: _vm.newVariable } }),
      _c("exist-variable-modal", {
        attrs: { allVariables: _vm.variables },
        on: { addVariable: _vm.addVariable, removeVariable: _vm.removeVariable }
      }),
      _c("var-form-modal", {
        attrs: { allVariables: _vm.variables, formObject: _vm.formObjectModel },
        on: { saveForm: _vm.saveForm }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "pt-5", staticStyle: { "min-width": "653.25px" } },
      [_c("div", { staticClass: "form-group" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "ml-2 p-1",
        attrs: {
          href: "https://developers.line.biz/media/messaging-api/emoji-list.pdf"
        }
      },
      [_c("i", { staticClass: "far fa-laugh" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" MODE")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-row" }, [
      _c("select", { staticClass: "col-3 form-control ml-2" }, [
        _c("option", { attrs: { value: "cros", selected: "" } }, [
          _vm._v("CROS")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
      _vm._v(" MODE")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: "mx-auto my-3", attrs: { href: "javascript:void(0)" } },
      [_c("i", { staticClass: "fas fa-plus" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }