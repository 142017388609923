var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "button-editor" }, [
    _c("h5", [
      _vm._v(_vm._s(_vm.$t("__button")) + " " + _vm._s(_vm.buttonNumber))
    ]),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__buttonLabel")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.button.label,
            expression: "button.label"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "text", maxlength: "20", required: "" },
        domProps: { value: _vm.button.label },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.button, "label", $event.target.value)
          }
        }
      })
    ]),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__actionType")))]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.button.actionType,
              expression: "button.actionType"
            }
          ],
          staticClass: "form-control",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.button,
                "actionType",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            }
          }
        },
        [
          _c("option", { attrs: { value: "uri" } }, [_vm._v("URI")]),
          _c("option", { attrs: { value: "text" } }, [_vm._v("Text")])
        ]
      )
    ]),
    _vm.button.actionType === "uri"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("__enterUri")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.button.value,
                expression: "button.value"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "url" },
            domProps: { value: _vm.button.value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.button, "value", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _vm.button.actionType === "text"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("__enterText")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.button.value,
                expression: "button.value"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "text", maxlength: "80" },
            domProps: { value: _vm.button.value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.button, "value", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v(_vm._s(_vm.$t("__buttonColor")))]),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.button.color,
            expression: "button.color"
          }
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.button.color },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.button, "color", $event.target.value)
          }
        }
      })
    ]),
    _vm.accountType === "webbot"
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("__fontColor")))]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.button.fontColor,
                expression: "button.fontColor"
              }
            ],
            staticClass: "form-control",
            attrs: { type: "color" },
            domProps: { value: _vm.button.fontColor },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.button, "fontColor", $event.target.value)
              }
            }
          })
        ])
      : _vm._e(),
    _c(
      "button",
      {
        staticClass: "btn btn-danger",
        on: {
          click: function($event) {
            return _vm.$emit("deleteButton")
          }
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.$t("__deleteButton")) + "\n  ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }